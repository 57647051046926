import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";

const Oops = (props) => {
  const navigate = useNavigate();

  return (
    <div style={{ textAlign: "center", padding: "40px" }}>
      <h1>Oops... alguma coisa deu errado.</h1>
      <p>O erro ocorrido foi automaticamente reportado para o Suporte do Movi como crítico.</p>
      <p>Se esse erro persistir entre em contato com o suporte.</p>
      <br />
      {props.ticket && <p style={{ color: "gray" }}>Ticket do caso: {props.ticket}</p>}
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          navigate("/app/home", { replace: true });
        }}
      >
        Voltar para o início
      </Button>
    </div>
  );
};

export default Oops;
