import { CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from 'react';
import useApiClient, { apiRoutes } from '../../../hooks/apiClient';
import store from 'store';

const VehicleBrandsSelect = (props) => {

    const optionsInit = [];
    optionsInit.tipo = 'tipo_1';
    const [options, setOptions] = useState(optionsInit);
    const [selected, setSelected] = useState(null);
    const { request, data, errors, loading } = useApiClient();

    const { brandId, type } = props.formik.values;

    useEffect(() => {
        const cachedOptions = store.get('brands');
        if (cachedOptions && cachedOptions[`tipo_${type}`]) {
            setOptions(cachedOptions[`tipo_${type}`]);
        }
        else {
            request(apiRoutes.main.fipe.marcas, {
                idTipoVeiculo: type,
            });
        }
        setSelected(null);
    }, [request, type]);

    useEffect(() => {
        if (!loading && data && data.success) {
            setOptions(data.value);
        }
    }, [data, loading]);

    useEffect(() => { // Cache

        var cachedItems = store.get('brands') || {};
        cachedItems[`tipo_${type}`] = options;
        store.set('brands', cachedItems);
        
        const selected = options.find((item) => item.idMarca === brandId);
        setSelected(selected || null);
    }, [options, type, brandId]);

    if (loading)
        return <CircularProgress />

    if (errors)
        return <Alert title="Oops... não foi possível carregar as marcas" />

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.descricao || 'Carregando...'}
            value={selected}
            noOptionsText="Nenhuma marca encontrada"
            onChange={(e, brand) => {
                setSelected(brand);
                props.onChange(e, brand);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label="Fabricante"
                    placeholder="Marca do veículo"
                    disabled={props.formik.isSubmitting || props.disabled}
                    error={props.formik.touched[props.field] && Boolean(props.formik.errors[props.field])}
                    helperText={props.formik.touched[props.field] ? props.formik.errors[props.field] : ''}
                />
            )}
        />
    );
};

export default VehicleBrandsSelect;