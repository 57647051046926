import AppConfig from "../config/app.config";

/**
 * Routes for API services consumed by the application
 */
const apiRoutes = {
  main: {
    app: {
      __route: "/api",

      // Queries
      init: { method: "GET", action: "/Versions" },
      pendingOperations: { method: "GET", action: "/AppPendingOperations" },
    },
    auth: {
      __route: "/api",

      // Commands
      signInForgotPassword: { method: "POST", action: "/SignInForgotPassword" },
      signInResetPassword: { method: "POST", action: "/SignInResetPassword" },
      signUpEmail: { method: "POST", action: "/SignUpEmail" },
      signUpEmailVerification: { method: "POST", action: "/SignUpEmailVerification" },
      signUpEmployeeUserInvite: { method: "POST", action: "/SignUpEmployeeUserInvite" },
      signNewsletter: { method: "POST", action: "/SignNewsletter" },
      signOutBasic: { method: "POST", action: "/SignOut" },

      // Queries
      signInBasic: { method: "GET", action: "/Signin" },
    },
    aux: {
      __route: "/api",

      // Queries
      auxDataBankGetAll: { method: "GET", action: "/AuxDataBankGetAll" },
    },
    bankAccount: {
      __route: "/api",

      // Commands
      bankAccountSave: { method: "POST", action: "/BankAccountSave" },
      bankAccountRemove: { method: "POST", action: "/BankAccountRemove" },

      // Queries
      bankAccountById: { method: "GET", action: "/BankAccountById" },
      bankAccountGetAll: { method: "GET", action: "/BankAccountGetAll" },
    },
    cache: {
      __route: "/api",

      // Queries
      serviceCategories: { method: "GET", action: "/ServiceCategories" },
      productCategories: { method: "GET", action: "/ProductCategories" },
    },
    companyOrder: {
      __route: "/api",

      // Commands
      save: { method: "POST", action: "/CompanyOrderSave" },
      addItem: { method: "POST", action: "/CompanyOrderAddItem" },
      removeItem: { method: "POST", action: "/CompanyOrderRemoveItem" },
      cancel: { method: "POST", action: "/CompanyOrderCancel" },
      finishRegistration: { method: "POST", action: "/CompanyOrderFinishRegistration" },

      // Queries
      byId: { method: "GET", action: "/CompanyOrderById" },
      search: { method: "GET", action: "/CompanyOrderSearch" },
    },
    debt: {
      __route: "/api",

      // Commands
      debtAdd: { method: "POST", action: "/DebtAdd" },
      debtCancel: { method: "POST", action: "/DebtCancel" },
      debtInstallmentPay: { method: "POST", action: "/DebtInstallmentPay" },
      debtInstallmentUpdate: { method: "POST", action: "/DebtInstallmentUpdate" },
      debtRemove: { method: "POST", action: "/DebtRemove" },

      // Queries
      debtById: { method: "GET", action: "/DebtById" },
      debtByCompanyOrder: { method: "GET", action: "/DebtByCompanyOrder" },
      debtSearch: { method: "GET", action: "/DebtSearch" },
    },
    garageOrder: {
      __route: "/api",

      // Commands
      addProduct: { method: "POST", action: "/GarageOrderAddProduct" },
      removeProduct: { method: "POST", action: "/GarageOrderRemoveProduct" },
      addService: { method: "POST", action: "/GarageOrderAddService" },
      removeService: { method: "POST", action: "/GarageOrderRemoveService" },

      // Queries
      pdf: { method: "GET", action: "/GarageOrderPdf" },
    },
    person: {
      __route: "/api",

      // Commands
      employeeSave: { method: "POST", action: "/EmployeeSave" },
      customerSave: { method: "POST", action: "/CustomerSave" },
      supplierSave: { method: "POST", action: "/SupplierSave" },

      // Queries
      customerById: { method: "GET", action: "/CustomerById" },
      employeeById: { method: "GET", action: "/EmployeeById" },
      employeeGarageStaff: { method: "GET", action: "/EmployeeGarageStaff" },
      supplierById: { method: "GET", action: "/SupplierById" },
      customerSearch: { method: "GET", action: "/CustomerSearch" },
      employeeSearch: { method: "GET", action: "/EmployeeSearch" },
      supplierSearch: { method: "GET", action: "/SupplierSearch" },
    },
    product: {
      __route: "/api",

      // Commands
      save: { method: "POST", action: "/ProductSave" },
      manufacturerItemSave: { method: "POST", action: "/ProductManufacturerItemSave" },
      manufacturerItemRemove: { method: "POST", action: "/ProductManufacturerItemRemove" },
      // descriptorCreate: { method: "POST", action: "/ProductDescriptorCreate" },
      // descriptorUpdate: { method: "POST", action: "/ProductDescriptorUpdate" },

      // Queries
      productGroups: { method: "GET", action: "/ProductGroups" },
      byBarcode: { method: "GET", action: "/ProductByBarcode" },
      byCode: { method: "GET", action: "/ProductByCode" },
      byGroup: { method: "GET", action: "/ProductByGroup" },
      // descriptorGroups: { method: "GET", action: "/ProductDescriptorGroups" },
      // descriptorByBarcode: { method: "GET", action: "/ProductDescriptorByBarcode" },
      // descriptorByManufacturerCode: { method: "GET", action: "/ProductDescriptorByManufacturerCode" },
      // descriptorByGroup: { method: "GET", action: "/ProductDescriptorByGroup" },
    },
    service: {
      __route: "/api",

      // Commands
      save: { method: "POST", action: "/ServiceSave" },
      remove: { method: "POST", action: "/ServiceRemove" },

      // Queries
      byId: { method: "GET", action: "/ServiceById" },
      search: { method: "GET", action: "/ServiceSearch" },
    },
    serviceOrder: {
      __route: "/api",

      // Commands
      create: { method: "POST", action: "/ServiceOrderCreate" },
      update: { method: "POST", action: "/ServiceOrderUpdate" },
      updateItem: { method: "POST", action: "/ServiceOrderUpdateItem" },
      paymentAdd: { method: "POST", action: "/ServiceOrderPaymentAdd" },
      paymentRemove: { method: "POST", action: "/ServiceOrderPaymentRemove" },
      cancel: { method: "POST", action: "/ServiceOrderCancel" },
      close: { method: "POST", action: "/ServiceOrderClose" },

      // Queries
      dashboard: { method: "GET", action: "/ServiceOrderDashboard" },
      byId: { method: "GET", action: "/ServiceOrderById" },
      history: { method: "GET", action: "/ServiceOrderHistory" },
      pdf: { method: "GET", action: "/ServiceOrderPdf" },
    },
    signalR: {
      __route: "/api",
      updateResource: { method: "POST", action: "/SignalRUpdateResource" },
    },
    stock: {
      __route: "/api",

      // Commands
      save: { method: "POST", action: "/StockSave" },

      // Queries
      byId: { method: "GET", action: "/StockById" },
      byBarcode: { method: "GET", action: "/StockByBarcode" },
      search: { method: "GET", action: "/StockSearch" },
    },
    storeOrder: {
      __route: "/api",

      // Commands
      cancel: { method: "POST", action: "/StoreOrderCancel" },
      close: { method: "POST", action: "/StoreOrderClose" },
      create: { method: "POST", action: "/StoreOrderCreate" },
      paymentAdd: { method: "POST", action: "/StoreOrderPaymentAdd" },
      paymentRemove: { method: "POST", action: "/StoreOrderPaymentRemove" },
      productAdd: { method: "POST", action: "/StoreOrderProductAdd" },
      productRemove: { method: "POST", action: "/StoreOrderProductRemove" },

      // Queries
      byId: { method: "GET", action: "/StoreOrderById" },
      byPeriod: { method: "GET", action: "/StoreOrderByPeriod" },
    },
    vehicle: {
      __route: "/api",

      // Commands
      save: { method: "POST", action: "/VehicleSave" },

      // Queries
      byId: { method: "GET", action: "/VehicleById" },
      byPlate: { method: "GET", action: "/VehicleByPlate" },
    },
    fipe: {
      __route: "/api",
      marcas: { method: "GET", action: "/FipeGetMarcas" },
      modelos: { method: "GET", action: "/FipeGetModelos" },
    },
  },
};

export const prepareRoutes = (routes) => {
  console.log("Preparing routes...");

  for (let host in routes) {
    for (let controller in routes[host]) {
      for (let action in routes[host][controller]) {
        if (action === "__route") continue;

        let route = routes[host][controller][action];
        route.url = `${AppConfig.hosts[host]}${routes[host][controller].__route}${routes[host][controller][action].action}`;
        route.code = AppConfig.codes[`${host}-${controller}-${action}`];
      }
    }
  }

  return routes;
};

export default apiRoutes;
