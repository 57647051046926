import { Card, CardContent, Grid } from "@material-ui/core";
import BuildIcon from "@material-ui/icons/Build";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import DetailView from "../../../components/UI/DetailView/DetailView";
import MAutocomplete from "../../../components/UI/MAutocomplete/MAutocomplete";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import store from "store";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const initialValues = {
  id: "",
  category: "",
  subcategory: "",
  description: "",
  shortDescription: "",
  application: "",
  info: "",
  estimatedTime: 1,
  price: "0.00",
};

const validationSchema = Yup.object({
  description: Yup.string(),
  shortDescription: Yup.string().required("Informe a descrição resumida"),
  category: Yup.string().required("Selecione uma categoria"),
  subcategory: Yup.string().required("Selecione uma subcategoria"),
  price: Yup.number()
    .min(0.01, "Informe um valor maior ou igual a R$0,01")
    .required("Informe o preço"),
});

const ServiceDetail = (props) => {
  const { request, data, errors, loading, requestId } = useApiClient();
  const navigate = useNavigate();
  const [cachedCategories, setCachedCategories] = useState(
    store.get("cached-service-categories")
  );
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.service.save, values, { requestId: "submit" });
    },
  });

  const { setSubmitting } = formik;
  const { category: selectedCategory } = formik.values;

  useEffect(() => {
    if (!cachedCategories)
      request(apiRoutes.main.cache.serviceCategories, null, {
        requestId: "categories",
      });
    else {
      setCategories(cachedCategories.categories);
    }
  }, [cachedCategories, request]);

  useEffect(() => {
    if (data || errors) setSubmitting(false);

    if (requestId === "categories" && data && data.success) {
      store.set("cached-service-categories", data.value);
      setCachedCategories(data.value);
      setCategories(data.value.categories);
    }

    if (requestId === "submit" && data && data.success) {
      toast.success("Salvo com sucesso");
      navigate(-1);
    }
  }, [data, errors, requestId, navigate, setSubmitting]);

  useEffect(() => {
    if (!selectedCategory) setSubcategories([]);
    else if (cachedCategories)
      setSubcategories(
        cachedCategories.subcategories.filter(
          (subcategory) => subcategory.category === selectedCategory
        )
      );
  }, [selectedCategory, cachedCategories]);

  return (
    <DetailView
      title="Serviços / Cadastro"
      icon={<BuildIcon />}
      defaultAction={null}
      formik={formik}
      getRoute={apiRoutes.main.service.byId}
      loading={loading}
      {...props}
    >
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MAutocomplete
                  formik={formik}
                  field="category"
                  label="Categoria"
                  options={categories}
                  optionLabelField="category"
                  optionKeyField="category"
                  placeholder="Selecione a Categoria do serviço"
                  onChange={(e, item) => {}}
                />
              </Grid>
              <Grid item xs={12}>
                <MAutocomplete
                  formik={formik}
                  field="subcategory"
                  label="Subcategoria"
                  options={subcategories}
                  optionLabelField="subcategory"
                  optionKeyField="subcategory"
                  placeholder="Selecione a Subcategoria do serviço"
                />
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  field={"shortDescription"}
                  label={"Descrição breve"}
                  placeholder={"Essa é a descrição que aparecerá nos pedidos"}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MTextField
                  field="estimatedTime"
                  label="Tempo de Execução (Horas)"
                  formik={formik}
                  type="number"
                  min="0.01"
                  step="any"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MTextField
                  field={"price"}
                  label={"Valor (R$)"}
                  formik={formik}
                  type="number"
                  money
                  min="0.01"
                  step="0.01"
                />
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  field={"description"}
                  label={"Descrição detalhada"}
                  formik={formik}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  field="info"
                  label="Informação adicional"
                  placeholder="Disponível para os usuário dos sistema"
                  formik={formik}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </DetailView>
  );
};

export default ServiceDetail;
