import { format, parseJSON } from "date-fns";

export const fDate = (jsonDate, stringFormat = "dd/MM/yyyy HH:mm") => {
  if (!jsonDate) return "";
  return format(parseJSON(jsonDate), stringFormat);
};

export const fPaymentType = (value) => {
  if (!value) return "";
  switch (value) {
    case "fcb":
      return "Boleto Bancário";
    case "credit-card":
      return "Cartão de Crédito";
    case "debit-card":
      return "Cartão de Débito";
    case "money":
      return "Dinheiro";
    case "pix":
      return "Pix";
    case "cheque":
      return "Cheque";
    default:
      return "(desconhecido)";
  }
};
