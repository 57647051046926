import common from "./config.common";
import devConfig from "./config.dev";
import stagingConfig from "./config.staging";
import prodConfig from "./config.prod";

function loadConfig() {
  let envConfig;

  switch (process.env.REACT_APP_ENV) {
    case "development": {
      envConfig = devConfig;
      break;
    }
    case "staging": {
      envConfig = stagingConfig;
      break;
    }
    case "production": {
      envConfig = prodConfig;
      break;
    }
    default:
      throw new Error("Configuração inválida. ");
  }

  return { ...envConfig, ...common };
}

const appConfig = { ...loadConfig() };

export default appConfig;
