import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.css";

const NavigationItem = (props) => {
  return (
    <li className={classes.NavigationItem}>
      <NavLink to={props.link} className={(navData) => navData.isActive ? classes.active : ''}>
        {props.children}
      </NavLink>
    </li>
  );
};

export default NavigationItem;
