import { Menu, MenuItem } from "@material-ui/core";
import { openCenteredWindow } from "../../App";
import { getUrl } from "../../hooks/apiClient";
import apiRoutes from "../../hooks/apiClient.routes";

const ServiceOrderPrintMenu = ({ serviceOrder, el, handlePrintMenuClose }) => {
  if (!serviceOrder?.id) return null;

  return (
    <Menu
      id="print-menu"
      anchorEl={el}
      keepMounted
      open={!!el}
      onClose={handlePrintMenuClose}
      style={{ marginTop: 40, marginLeft: 0 }}
    >
      <MenuItem
        onClick={() => {
          handlePrintMenuClose();
          openCenteredWindow(
            getUrl(apiRoutes.main.garageOrder.pdf, {
              id: serviceOrder.garageOrderId,
            }),
            "Movi Motors - Pedido de Oficina",
            800,
            600
          );
        }}
      >
        Pedido
      </MenuItem>
      <MenuItem
        disabled={!serviceOrder.id}
        onClick={() => {
          handlePrintMenuClose();
          openCenteredWindow(
            getUrl(apiRoutes.main.serviceOrder.pdf, {
              id: serviceOrder.id,
            }),
            "Movi Motors - Ordem de Serviço",
            800,
            600
          );
        }}
      >
        Ordem de Serviço
      </MenuItem>
    </Menu>
  );
};

export default ServiceOrderPrintMenu;
