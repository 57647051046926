import { Typography } from "@material-ui/core";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const cols = [
  {
    name: "Quant.",
    selector: "quantity",
    width: "10%",
    format: (row) => `${row.quantity} ${row.productUnit}`,
  },
  {
    name: "Descrição",
    selector: "description",
    width: "40%",
  },
  {
    name: "Valor Unit.(R$)",
    selector: (row) => row.price.toFixed(2),
    right: true,
    width: "25%",
  },
  {
    name: "Valor Total(R$)",
    selector: (row) => (row.price * row.quantity).toFixed(2),
    right: true,
    width: "25%",
  },
];

const TabProducts = () => {
  const { serviceOrders, selectedIndex } = useSelector((state) => state.dashboardGarage);

  const dispatch = useDispatch();

  const selectedServiceOrder = ((serviceOrders || {}).active || [])[selectedIndex];
  if (!selectedServiceOrder) return null;

  return (
    <>
      <DataTable
        columns={cols}
        data={selectedServiceOrder.garageOrder?.products || []}
        noDataComponent={
          <b>
            <br />
            Nenhum produto foi adicionado
          </b>
        }
        noHeader
        pointerOnHover
        striped
        dense
        responsive
        onRowClicked={(row) => {
          console.log(row, !!row);
          dispatch(dashboardGarageActions.setProductRemoveModalShow(row));
        }}
      />
      {!!selectedServiceOrder.garageOrder?.productsTotal && (
        <Typography variant="h6" style={{ textAlign: "right" }}>
          Total R$ {selectedServiceOrder.garageOrder?.productsTotal?.toFixed(2)}
        </Typography>
      )}
    </>
  );
};

export default TabProducts;
