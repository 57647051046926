import {
  Badge,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import BuildIcon from "@material-ui/icons/Build";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import PrintIcon from "@material-ui/icons/Print";
import RefreshIcon from "@material-ui/icons/Refresh";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useCallback, useEffect, useState } from "react";
import ReactList from "react-list";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { openCenteredWindow } from "../../App";
import LaunchImg from "../../assets/images/ilustrations/launch.svg";
import StatusIcon from "../../components/UI/StatusIcon/StatusIcon";
import useApiClient, { apiRoutes, getUrl } from "../../hooks/apiClient";
import { dashboardGarageActions } from "../../redux/dashboardGarage/dashboardGarageSlice";
import ProductPickerModal from "../Products/ProductPickerModal/ProductPickerModal";
import DashboardGarageTabs from "./DashboardGarageTabs/DashboardGarageTabs";
import ProductRemoveModal from "./DashboardGarageTabs/TabProducts/ProductRemoveModal/ProductRemoveModal";
import ServicePickerModal from "./DashboardGarageTabs/TabServices/ServiceModal/ServicePickerModal";
import ServiceRemoveModal from "./DashboardGarageTabs/TabServices/ServiceModal/ServiceRemoveModal";
import PaymentAddModal from "./DashboardGarageTabs/TabPayments/PaymentAddModal/PaymentAddModal";
import PaymentRemoveModal from "./DashboardGarageTabs/TabPayments/PaymentRemoveModal/PaymentRemoveModal";
import CloseServiceOrderModal from "./DashboardGarageToolbar/CloseServiceOrderModal/CloseServiceOrderModal";
import ServiceOrderEditModal from "./DashboardGarageToolbar/ServiceOrderEditModal/ServiceOrderEditModal";

const DashboardGarage = () => {
  const dispatch = useDispatch();
  const { request, data, errors, loading } = useApiClient();
  const [statusFilter, setStatusFilter] = useState(() => ["open", "executing", "done"]);
  const [printMenuAnchorEl, setPrintMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const desktopMedia = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const {
    serviceOrders,
    selectedIndex,
    servicePickerShow,
    serviceRemoveShow,
    productPickerShow,
    productRemoveModalShow,
    paymentAddModalShow,
    paymentRemoveModalShow,
    closeServiceOrderModalShow,
    serviceOrderEditModalShow,
  } = useSelector((state) => state.dashboardGarage);

  const handlePrintMenuOpen = (event) => {
    setPrintMenuAnchorEl(event.currentTarget);
  };

  const handlePrintMenuClose = () => {
    setPrintMenuAnchorEl(null);
  };

  const requestServiceOrders = useCallback(() => {
    request(apiRoutes.main.serviceOrder.dashboard, {});
  }, [request]);

  useEffect(() => {
    if (!serviceOrders) requestServiceOrders(serviceOrders);
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(dashboardGarageActions.setServiceOrders(data.value));
    }
  }, [data, errors, loading, dispatch]);

  const selectedServiceOrder = ((serviceOrders || {}).active || [])[selectedIndex] || {};

  const renderServiceOrderItem = (index, key) => {
    if (index === -1 && ((serviceOrders || {}).active || []).length === 0) return null;

    if (index === -1 && serviceOrders.active.length > 0) {
      dispatch(dashboardGarageActions.setSelectedIndex(0));
      return null;
    }

    const selectedServiceOrder = serviceOrders.active[index];

    if (!selectedServiceOrder) return null;

    const selected = index === selectedIndex;

    return (
      <div
        key={key}
        onClick={() => {
          dispatch(dashboardGarageActions.setSelectedIndex(index));
        }}
        style={{
          padding: 5,
          backgroundColor: selected ? "#b3e5fc" : "white",
          margin: 5,
          borderStyle: "solid",
          borderWidth: 0.5,
          borderColor: selected ? "#7da0b0" : "white",
          fontSize: "small",
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Grid container spacing={0} alignItems="center" alignContent="center">
          <Grid item xs={1} style={{ padding: 5 }}>
            <StatusIcon serviceOrder={selectedServiceOrder} />
          </Grid>
          <Grid item xs={11} style={{ padding: 10 }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <b>
                  {(selectedServiceOrder.vehicle || {}).brand} {(selectedServiceOrder.vehicle || {}).model} |{" "}
                  {(selectedServiceOrder.vehicle || {}).plates}
                </b>
              </Grid>
              <Grid item xs={12}>
                {(selectedServiceOrder.customer || {}).name}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      {productPickerShow && <ProductPickerModal selectedServiceOrder={selectedServiceOrder} />}
      {productRemoveModalShow && <ProductRemoveModal />}
      {servicePickerShow && <ServicePickerModal selectedServiceOrder={selectedServiceOrder} />}
      {serviceRemoveShow && <ServiceRemoveModal />}
      {paymentAddModalShow && <PaymentAddModal selectedServiceOrder={selectedServiceOrder} />}
      {paymentRemoveModalShow && <PaymentRemoveModal selectedServiceOrder={selectedServiceOrder} />}
      {closeServiceOrderModalShow && <CloseServiceOrderModal serviceOrder={selectedServiceOrder} />}
      {serviceOrderEditModalShow && <ServiceOrderEditModal serviceOrder={selectedServiceOrder} />}
      <Grid container spacing={2}>
        <Menu
          id="print-menu"
          anchorEl={printMenuAnchorEl}
          keepMounted
          open={!!printMenuAnchorEl}
          onClose={handlePrintMenuClose}
          style={{ marginTop: 40, marginLeft: 0 }}
        >
          <MenuItem
            disabled={!!!selectedServiceOrder.garageOrder}
            onClick={() => {
              handlePrintMenuClose();
              openCenteredWindow(
                getUrl(apiRoutes.main.garageOrder.pdf, {
                  id: selectedServiceOrder.garageOrderId,
                }),
                "Movi Motors - Pedido de Oficina",
                800,
                600
              );
            }}
          >
            Pedido
          </MenuItem>
          <MenuItem
            disabled={!selectedServiceOrder.id}
            onClick={() => {
              handlePrintMenuClose();
              openCenteredWindow(
                getUrl(apiRoutes.main.serviceOrder.pdf, {
                  id: selectedServiceOrder.id,
                }),
                "Movi Motors - Ordem de Serviço",
                800,
                600
              );
            }}
          >
            Ordem de Serviço
          </MenuItem>
        </Menu>
        <Grid item xs={12} sm={4} md={3}>
          <Card>
            {!desktopMedia && renderServiceOrderItem(selectedIndex, selectedIndex)}
            {desktopMedia && (
              <div
                style={{
                  backgroundColor: "#f4f4f4",
                  margin: 0,
                  marginTop: 7,
                  padding: 0,
                  overflow: "auto",
                  minHeight: 600,
                }}
              >
                <ReactList
                  itemRenderer={renderServiceOrderItem}
                  length={((serviceOrders || {}).active || []).length}
                  type="uniform"
                />
              </div>
            )}
            {desktopMedia && (
              <CardContent>
                <Grid item xs={12}>
                  <ToggleButtonGroup
                    size="small"
                    value={statusFilter}
                    onChange={(e, newStatusFilter) => {
                      let indexOfRefresh = newStatusFilter.indexOf("refresh");
                      if (indexOfRefresh >= 0) {
                        newStatusFilter.splice(newStatusFilter.indexOf("refresh"), 1);
                        requestServiceOrders();
                      }
                      setStatusFilter(newStatusFilter);
                    }}
                  >
                    <ToggleButton value="open">
                      <Tooltip title="Abertas ou pendentes">
                        <AssignmentLateIcon style={{ color: "#b22a00" }} />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="executing">
                      <Tooltip title="Em execução">
                        <AssignmentIndIcon style={{ color: "#b28900" }} />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="done">
                      <Tooltip title="Concluídas">
                        <AssignmentTurnedInIcon style={{ color: "#357a38" }} />
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="refresh">
                      <Tooltip title="Atualizar">
                        <RefreshIcon />
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </CardContent>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} sm={8} md={9}>
          <Card>
            <CardContent style={{ minHeight: 647 }}>
              {!selectedServiceOrder.id && loading && (
                <Grid container>
                  <Grid item xs={12} md={12} style={{ paddingTop: 30, textAlign: "center" }}>
                    <div>
                      <br />
                      <br />
                      <CircularProgress /> <br />
                      Carregando...
                    </div>
                  </Grid>
                </Grid>
              )}
              {!selectedServiceOrder.id && !loading && (
                <Grid container>
                  <Grid item xs={12} md={12} style={{ paddingTop: 30, textAlign: "center" }}>
                    <img src={LaunchImg} height={120} />
                    <Typography variant="h6">Nenhuma Ordem de Serviço para exibir</Typography>
                    <p>Que tal criar um nova promoção?</p>
                    <p>
                      <Button variant="contained" color="default" onClick={() => navigate("/app/promocoes")}>
                        Promoções
                      </Button>
                    </p>
                  </Grid>
                </Grid>
              )}
              {selectedServiceOrder.id && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      borderBottomColor: "lightgray",
                      borderBottomStyle: "solid",
                      borderBottomWidth: 1,
                      paddingLeft: 0,
                      marginBottom: 0,
                    }}
                  >
                    <IconButton onClick={() => dispatch(dashboardGarageActions.setProductPickerShow(true))}>
                      <Badge badgeContent="+">
                        <Tooltip title="Adicionar Produto">
                          <ShoppingCartIcon />
                        </Tooltip>
                      </Badge>
                    </IconButton>
                    <IconButton onClick={() => dispatch(dashboardGarageActions.setServicePickerShow(true))}>
                      <Badge badgeContent="+">
                        <Tooltip title="Adicionar Serviço">
                          <BuildIcon />
                        </Tooltip>
                      </Badge>
                    </IconButton>
                    <IconButton onClick={() => dispatch(dashboardGarageActions.setPaymentAddModalShow(true))}>
                      <Badge badgeContent="+">
                        <Tooltip title="Adicionar Pagamento">
                          <MonetizationOnIcon />
                        </Tooltip>
                      </Badge>
                    </IconButton>
                    <IconButton onClick={handlePrintMenuOpen}>
                      <Tooltip title="Imprimir">
                        <PrintIcon />
                      </Tooltip>
                    </IconButton>
                    {/* <IconButton>
                      <Tooltip title="Enviar mensagem">
                        <EmailIcon />
                      </Tooltip>
                    </IconButton> */}
                    <IconButton
                      disabled={!selectedServiceOrder.id}
                      onClick={() => dispatch(dashboardGarageActions.setCloseServiceOrderModalShow(true))}
                    >
                      <Tooltip title="Encerrar OS">
                        <DoneAllIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  <DashboardGarageTabs selectedServiceOrder={selectedServiceOrder} />
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardGarage;
