import { Button, Card, CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import * as Yup from "yup";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import AddressCard from "../../../components/UI/Person/AddressCard/AddressCard";
import IdentificationCard from "../../../components/UI/Person/IdentificationCard/IdentificationCard";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { serviceOrderActions } from "../../../redux/serviceOrder/serviceOrderSlice";
import { validCnpj, validCpf } from "../../../utils/validation";
import VehicleAssociated from "../../Vehicle/VehicleAssociated/VehicleAssociated";
import classes from "../../__Containers.css";

const initialValues = {
  id: "",
  type: "1",
  name: "",
  publicName: "",
  taxId: "",
  gender: "",
  birth: null,
  phone1: "",
  phone2: "",
  phone3: "",
  email: "",
  info: "",
  addressLine1: "",
  number: "",
  addressLine2: "",
  neighborhood: "",
  city: "",
  state: "",
  zip: "",
  associatedVehicles: [],
};

const validationSchema = Yup.object({
  name: Yup.string("Informe o nome do cliente").required("O nome do cliente é obrigatório"),
  taxId: Yup.string().when("type", {
    is: "1",
    then: Yup.string()
      .default("")
      .test(validCpf({ required: false })),
    otherwise: Yup.string()
      .default("")
      .test(validCnpj({ required: false })),
  }),
  zip: Yup.string().matches(/^[0-9]{8}/g, "Formato do CEP é inválido"),
});

/**
 * Customer Detail component
 */
const CustomerDetail = () => {
  const dispatch = useDispatch();
  const { request, data, errors, loading } = useApiClient();
  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      request(apiRoutes.main.person.customerSave, {
        ...values,
        associatedVehicles: values.associatedVehicles.map((v) => v.id),
      });
    },
  });

  const { setSubmitting, setErrors, setValues, setStatus } = formik;
  const { request: customerRequest, data: customerData, errors: customerErrors } = useApiClient();
  const newServiceOrderContext = location.state?.newServiceOrderContext;

  useEffect(() => {
    if (location.state && location.state.id) {
      setSubmitting(true);
      customerRequest(apiRoutes.main.person.customerById, {
        id: location.state.id,
      });
      delete location.state.id;
    }
  }, [customerRequest, setSubmitting, location.state]);

  useEffect(() => {
    if (customerData) {
      setValues({
        ...customerData.value.person,
        type: `${customerData.value.person.type}`,
        info: customerData.value.customerProfile.info,
        associatedVehicles: customerData.value.customerProfile.vehicles.filter((v) => !!!v.removed),
      });
      setSubmitting(false);
    }

    if (customerErrors) {
      setSubmitting(false);
    }
  }, [customerData, customerErrors, setSubmitting, setValues]);

  useEffect(() => {
    if (data || errors) setSubmitting(false);

    if (data) {
      toast.success("Salvo com sucesso");
      console.log(newServiceOrderContext, data);
      if (newServiceOrderContext) {
        dispatch(
          serviceOrderActions.setNewServiceOrderPayload({
            customerId: data.value.id,
          })
        );
        navigate("/app/os/abertura");
      } else {
        navigate(-1);
      }
    }

    setStatus(errors ? errors.status : "");
    setErrors(errors ? errors.schema || {} : {});
  }, [data, errors, navigate, newServiceOrderContext, setStatus, setErrors, setSubmitting]);

  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Clientes / Cadastro" icon={<PeopleIcon />} defaultAction={null} />
      <form onSubmit={formik.handleSubmit}>
        <Card className={classes.FormCard}>
          {(formik.isSubmitting || loading) && <LinearProgress />}
          <Grid container spacing={3}>
            {formik.status && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <IdentificationCard formik={formik} />
            <AddressCard formik={formik} />
            <Grid item xs={12} md={6}>
              <VehicleAssociated
                loading={loading}
                associatedVehicles={formik.values.associatedVehicles}
                handleChange={(vehicles) => formik.setFieldValue("associatedVehicles", vehicles)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={6} md={2}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Salvar
                </Button>
              )}
            </Grid>
            <Grid item xs={6} md={2}>
              <Button variant="contained" color="default" fullWidth onClick={() => navigate(-1)}>
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default CustomerDetail;
