import { Button, Card, CardContent, Grid, LinearProgress, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import { companyOrderActions } from "../../../redux/companyOrder/companyOrderSlice";
import classes from "../../__Containers.css";
import CompanyOrderItemsTable from "./CompanyOrderItemsTable";
import NewProductModal from "./NewProductModal/NewProductModal";

const initialValues = {
  companyOrderId: "",
  productId: "",
  manufacturerItemId: "",
  quantity: "",
  lot: "",
  fabrication: "",
  expiresAt: "",
  info: "",
  cost: "",
  price: "",
};

const validationSchema = Yup.object({
  productId: Yup.string().required("Selecione o produto"),
  quantity: Yup.number().moreThan(0, "Precisa ser maior que Zero").default(0),
  cost: Yup.number().moreThan(0, "Precisa ser maior que Zero").default(0),
  price: Yup.number().moreThan(0, "Precisa ser maior que Zero").default(0),
});

const CompanyOrderItemsCard = (props) => {
  const { companyOrderId, items, newItemHandler, removedItemHandler } = props;
  const [barcode, setBarcode] = useState("");
  const { newProductModalShow } = useSelector((state) => state.companyOrder);
  const [searchResult, setSearchResult] = useState(null);
  const { request, data, errors, loading, requestId, requestExtra } = useApiClient();
  const {
    request: deleteItemRequest,
    data: deleteItemData,
    errors: deleteItemErrors,
    loading: deleteItemLoading,
  } = useApiClient();
  const dispatch = useDispatch();

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      actions.setStatus("");
      request(
        apiRoutes.main.companyOrder.addItem,
        { companyOrderId: companyOrderId, ...values },
        {
          requestId: "add-item",
        }
      );
    },
  });

  const searchHandler = useCallback(
    (barcode) => {
      if (!barcode && barcode.length !== 13) {
        return;
      }
      request(apiRoutes.main.product.byBarcode, { barcode }, { requestId: "search", requestExtra: barcode });
    },
    [request]
  );

  const { values, setValues, setTouched, setSubmitting, setStatus, setErrors, resetForm } = formik;

  const newItemAdded = useCallback(
    (newItem) => {
      console.log("item added");
      newItemHandler({ ...newItem });
    },
    [values]
  );

  useEffect(() => {
    console.log("after search, add-item", requestId);

    if (requestId === "search") {
      if (data && data.success) {
        setSearchResult({ ...data.value, barcode: requestExtra });
        setBarcode("");
      }
    }

    if (requestId === "add-item") {
      if (data && data.success) {
        //item added
        newItemAdded(data.value);
        resetForm();
      }
    }
    setStatus({ ...errors }.status || "");
    setErrors({ ...errors }.schema || {});
    if (!loading) setSubmitting(false);
  }, [data, errors, loading, requestId, requestExtra, setSubmitting, setStatus, setErrors, resetForm]);

  useEffect(() => {
    console.log("after result", searchResult);

    if (!searchResult) return;

    if (searchResult.resolution === "none") {
      // setStatus("Item não encontrado. ");
      setSearchResult(null);
      dispatch(companyOrderActions.setNewProductModalShow(searchResult.barcode));
      return;
    }

    // Happiest path
    if (searchResult.resolved?.id) {
      setValues({
        ...initialValues,
        companyOrderId,
        productId: searchResult.resolved.id,
        manufacturerItemId: searchResult.resolved.manufacturerItems[0].id,
        shortDescription: `${searchResult.resolved.shortDescription} ${searchResult.resolved.manufacturerItems[0].manufacturer} ${searchResult.resolved.manufacturerItems[0].manufacturerCode}`,
        price: searchResult.price,
      });
      setTouched(false);
    }
  }, [searchResult, companyOrderId, setValues, setStatus, setTouched]);

  useEffect(() => {
    console.log("item-removed", deleteItemData, deleteItemErrors);

    if (deleteItemData) {
      toast.success("Item removido");
      removedItemHandler(deleteItemData.value.itemId);
    }
    setStatus((deleteItemErrors || {}).status || "");
  }, [deleteItemData, deleteItemErrors, setStatus]);

  return (
    <>
      {newProductModalShow && <NewProductModal onItemCreated={searchHandler} />}
      <Grid item xs={12} md={6}>
        <form onSubmit={formik.handleSubmit}>
          {(loading || deleteItemLoading) && <LinearProgress />}
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                {formik.status && (
                  <Grid item xs={12}>
                    <Alert severity="error">{formik.status}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <span className={classes.SectionTitle}>Produtos</span>
                </Grid>
                {!formik.values.manufacturerItemId && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Código de Barras"
                        placeholder="Digite ou escaneie o Código de Barras"
                        fullWidth
                        disabled={loading}
                        onChange={(e) => {
                          setBarcode(e.target.value);
                        }}
                        onKeyUp={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            searchHandler(barcode);
                          }
                        }}
                        value={barcode}
                      />
                      {formik.errors.barcode && <Alert severity="error">{formik.errors.barcode}</Alert>}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button variant="text" color="primary" disabled={loading} onClick={() => searchHandler(barcode)}>
                        Pesquisar Item
                      </Button>
                    </Grid>
                  </>
                )}
                {formik.values.manufacturerItemId && (
                  <>
                    <Grid item xs={12}>
                      {console.log(formik)}
                      <GridItemText label="Produto" text={formik.values.shortDescription} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MTextField field="quantity" label="Quantidade" type="number" step="0.01" formik={formik} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MTextField field="cost" label="Custo (R$)" type="number" step="0.01" formik={formik} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MTextField field="price" label="Preço de Venda (R$)" type="number" step="0.01" formik={formik} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <MTextField field="lot" label="No. Lote" formik={formik} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        id="expiresAt"
                        name="expiresAt"
                        label="Validade"
                        format="dd/MM/yyyy"
                        fullWidth
                        value={formik.values.expiresAt ? formik.values.expiresAt : null}
                        disabled={formik.isSubmitting}
                        invalidDateMessage="Data inválida"
                        disablePast
                        onChange={(date) => {
                          formik.setFieldValue("expiresAt", date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "alterar vencimento",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <KeyboardDatePicker
                        id="fabrication"
                        name="fabrication"
                        label="Fabricação"
                        format="dd/MM/yyyy"
                        fullWidth
                        value={formik.values.fabrication ? formik.values.fabrication : null}
                        disabled={formik.isSubmitting}
                        invalidDateMessage="Data inválida"
                        disableFuture
                        onChange={(date) => {
                          formik.setFieldValue("fabrication", date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "alterar fabricação",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Button
                        variant="text"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          formik.submitForm();
                        }}
                      >
                        Adicionar
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        disabled={loading}
                        onClick={() => {
                          formik.resetForm();
                          setBarcode("");
                          setSearchResult(null);
                        }}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={12}>
                  <CompanyOrderItemsTable
                    items={items}
                    onRowClicked={(item) => {
                      if (deleteItemLoading) return;
                      deleteItemRequest(apiRoutes.main.companyOrder.removeItem, {
                        companyOrderId,
                        itemId: item.id,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                  <GridItemText
                    md={12}
                    label="Total (R$)"
                    money={_.sumBy(items, (item) => item.quantity * item.cost)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </>
  );
};

export default CompanyOrderItemsCard;
