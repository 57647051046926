import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import React from "react";
import SearchView from "../../../components/UI/SearchView/SearchView";
import apiRoutes from "../../../hooks/apiClient.routes";
import HotButton from "../../../components/UI/HotButton/HotButton";
import { useNavigate } from "react-router";

const cols = [
  {
    name: "Nome",
    selector: "name",
  },
  {
    name: "CPF",
    selector: "taxId",
  },
  {
    name: "E-mail",
    selector: "email",
  },
  {
    name: "Telefone",
    selector: "phone1",
  },
];

const TeamSearch = (props) => {
  const navigate = useNavigate();
  return (
    <SearchView
      title="Equipe"
      icon={<AssignmentIndIcon />}
      placeholder="Nome ou CPF"
      cacheKey="team-search"
      cols={cols}
      searchRoute={apiRoutes.main.person.employeeSearch}
      defaultActionButton={
        <HotButton
          hotkeys="ctrl+n"
          onClick={() => {
            navigate("/app/equipe/form");
          }}
        >
          Novo Membro
        </HotButton>
      }
      onRowClicked={(row) => {
        navigate("/app/equipe/form", {
          state: { id: row.id },
        });
      }}
    />
  );
};

export default TeamSearch;
