/**
 * Settings for staging environment
 * yarn start
 */
const stagingConfig = {
  env: "staging",
  hosts: {
    main: "https://movi-serverless-main-staging.azurewebsites.net",
  },
  codes: {
    // auth:Commands
    ["main-auth-signInForgotPassword"]: "8/pkm8fHTk66oEFgPwfBH4lNdyGTvYk39SNwMHfwtpmkXR8YQveRcQ==",
    ["main-auth-signInResetPassword"]: "uwKZVYyEA5p53ZeDFP3F8Vr6Okr2SMmOF87rR/BAPwlE/XIx2Cy0gQ==",
    ["main-auth-signUpEmail"]: "AiywdisZ/H7tXS89SnpVisGRZTLUwnMwFt/WA2PzLfV3YbPEnZPu7A==",
    ["main-auth-signUpEmailVerification"]: "VcTapRMQBansQMAyZD6slFyRuaUEaF79/04nfXTwLfRADAQSjWX1cA==",
    ["main-auth-signUpEmployeeUserInvite"]: "KI/pME4PUTLSGTQvVDzIaRy/wqagIYHmsA3OxItRR6NkHaSo25CZnQ==",
    ["main-auth-signNewsletter"]: "iBhBYZNZnOsss0J0RY8Jay0637IijfkbEM4cbhzvdOLd5kTRvQIO5A==",
    ["main-auth-signInBasic"]: "watN6SRBAa4BIuYb/jJwnQIAEDKIktaqxDfYh/ltZa1A4XVbG4mZgw==",
    ["main-auth-signOutBasic"]: "",

    // app:queries
    ["main-app-pendingOperations"]: "VLKHHigzwlzjN9WB6WJLjIILO3pb4sGK0oPSwimOnRNU8hscWIPPpw==",

    // aux:Queries
    ["main-aux-auxDataBankGetAll"]: "yfNABMVhfVbzETdI2dCh2LypSPVcRWjO2lWAWFrz5JliWqmZC4OSCw==",

    // bankAccount:commands
    ["main-bankAccount-bankAccountSave"]: "67anaQ4RqreLTX48NBsHasZu1cqaYjPK17ADlEzyceEl3w5oB/GzaQ==",
    ["main-bankAccount-bankAccountRemove"]: "nmloiJFIwgjGSe5aDZDOVLWJ7Ddg1BDTcpeqBzrAG8DBTWRUJfxoaQ==",

    // bankAccount:queries
    ["main-bankAccount-bankAccountById"]: "OagHI4x9q/j5ub0DDOnLVu/0ZCZQGDPHU7bCxcB/VODaZwAZoDlmmg==",
    ["main-bankAccount-bankAccountGetAll"]: "iCzALkCgjMU3AaLseIkxgW4MWvvGUBwXmVY/LKnG3QRS7RtfdcpfLQ==",

    ["main-cache-serviceCategories"]: "CDgxTJ/56aZEPW4Y2HAHBLiEJDgALIWUSD68x6BgZlAXbLLRDWUFlQ==",

    // companyOrder:commands
    ["main-companyOrder-save"]: "7EMdPLMPULjq0m8Xw67bvtbZqpBW06iO3yeDLgmx0dNdFzCMLJSbZQ==",
    ["main-companyOrder-addItem"]: "yQEkxi6ZuFnM2FDRy/vQYJrC4JPy2Rz3m2Ujfm/3/dTcUokz80zDhw==",
    ["main-companyOrder-removeItem"]: "2aAIdLZCC6M7xPRiaKyYlNG7qcFiootu866qXzzZle1rR4XsUp3RrA==",
    ["main-companyOrder-cancel"]: "Hnw6vBodnI0B9SycaTOWN9Tea7cVHhTFAv8oIjH257AM1S62yGUVzQ==",
    ["main-companyOrder-finishRegistration"]: "EGQOSdDo6M1d8EtYcIak5c9qAHGS5jKaUwMWH2CKvbqEq/THIzbQEg==",

    // companyOrder:queries
    ["main-companyOrder-byId"]: "rgiSdEUrd0BZQ6dkcdLhEqdIdGYwGU8IOlvBOOUc7u2OFcOQFEqclQ==",
    ["main-companyOrder-search"]: "a6V9JSc5UC1K4mlFyqc241oXUefCa6uQLiF8MH681Uqa4shTEqtqCw==",

    // debt:commands
    ["main-debt-debtAdd"]: "u0vTNZd0WZCMUZeBDEGoswxMhjavhn5CwVvlNw3/VlEPDGMhTE3/uA==",
    ["main-debt-debtCancel"]: "XgUKDMqKivGx/CEOq3ORp4b7RNPV6xTkyCq9a8FjqakaNZ5yadf3eg==",
    ["main-debt-debtInstallmentPay"]: "WZqBa2UY774CbuGayAnjYLyxzAGF7IAaJa6x/TSfmRdFPxkSwwmYGw==",
    ["main-debt-debtInstallmentUpdate"]: "3ke64jzWkk9JCUaBaACEheOsFaK4QRbCKWWJe9OGopFi5DAntKQDKA==",
    ["main-debt-debtRemove"]: "AoMwJGm3nyAz/UrktapOhpm5MZPHwvHhmGkpSOnI4iHLZAEwEgAKWg==",

    // debt:queries
    ["main-debt-debtById"]: "gVf8R2Kg5B7DhtPOTvPV3tnaspkSkj77dy02V2eexIx8u1ix/NKmjA==",
    ["main-debt-debtByCompanyOrder"]: "iqQv2R/PuxoT8ZZXfhhpa0IcaGIopDiHCxPtU7YQMPz8vjC/DOcKZw==",
    ["main-debt-debtSearch"]: "efbpOMqARcNRdOkK48ZpJ7XWKKLiui1sVoQIVUAbxzdzvtzYkN4TIQ==",

    // garageOrder:commands
    ["main-garageOrder-addProduct"]: "paD3ue/dnB02W7F24pzTkIux5b7aonRF3JXsGJDr/tb8cvuzPjKIXw==",
    ["main-garageOrder-removeProduct"]: "MsCH9el2ydp4R0vQM7SzeYk7at3dED22MSNZjEzdhSNueY7FPSXjlg==",
    ["main-garageOrder-addService"]: "RVdORHaA5xeusHL5AFEAQmQqdo72G7djBxn9xfco1SxXUNI2x8Fn7g==",
    ["main-garageOrder-removeService"]: "a/tbMtfNqsyLFtzGeKoYPIfM6stPG0yPxEVvjVUQy1ukxmarCq9YIw==",

    // person:commands
    ["main-person-employeeSave"]: "V2LKL8i3bLP589HxwrHMl9j4W/fGK0BZZciA3Btt1HxiD7t/RGXqnw==",
    ["main-person-customerSave"]: "WEzQB/vsxoF79OlioNKtvzUgC0GMnhKu/GOQU8qCEio3yuKBA9ox4w==",
    ["main-person-supplierSave"]: "DLQ6n8L8r7mpUSg0FtmgHNQLaM7qaL472rfhehAh6si5L0QGNr1sSg==",

    // person:queries
    ["main-person-customerById"]: "c9gRWT2wTJsaaNM7vUMGxhpG005uFS2joEHV7E8fjm0SGiCf8fyXEQ==",
    ["main-person-employeeById"]: "OKrbCob1IfoUCK0jhE5E8X2uJIpsjikJGf2IV5yMRfVjnwkJcGuaBg==",
    ["main-person-supplierById"]: "7iDhlWsTLLhAHua9Ne2O6wNHBySFieJRRfxuXpuK74qEV8IV9tTHww==",
    ["main-person-customerSearch"]: "MzE2KtINRjk7XPPhHOpmOOtNqE1TzkTjhTEMbbAEhXv63aMZQziVEg==",
    ["main-person-employeeSearch"]: "X1n20RuCJo3y1QBRNbines0W4TxMboNgh19S8yAbTuoz3pptMFvmag==",
    ["main-person-supplierSearch"]: "PqCaUVt6jgFaABO3q1SlCDZ6Pa/J7rjgQZaHfrmYQkS4CGnXrQHgDQ==",

    // product:commands
    ["main-product-save"]: "1w4twihrN7CLCI2JmscLhsxXZQAJoxZpj3s1fYHOvyYiZF8kNjY2hg==",
    ["main-product-descriptorCreate"]: "ePwnJ4faAPhr7/Ctkdx75iQBGNqGf5tjaQ50YBaXLFYBUlig6fAmww==",
    ["main-product-descriptorUpdate"]: "UsR33LkpPsqywt99QeGhQ1F/wjs3Lb33kk1xypF8Cw4mB6XF0DW7kA==",
    ["main-product-descriptorManufacturerItemSave"]: "",
    ["main-product-descriptorManufacturerItemRemove"]: "EYJg1fvUwXVHRouKtkUgDa1XB8uKPvgnvbYP3CQXYDJN0enk/xCptg==",

    // product:queries
    ["main-product-descriptorGroups"]: "rZ/VcIEKaTGLm2yKIT7lEQYXMzVUWQogOAR9O9Sjsi2Q/s8GrAWdTA==",
    ["main-product-descriptorByBarcode"]: "9SHSFhyF2GhxjhzzKAhtRIXP/NfxoSGxPFlpqDKqtjJx7iyJ9OrhSg==",
    ["main-product-descriptorByManufacturerCode"]: "PFOlTFXbCk6n6GoEXh72W4VMWmvSYzLtnLHeOfxCczR1ASq3Hhdzpg==",
    ["main-product-descriptorByGroup"]: "wFqCNj8gTxcukTA9FjoTNgjW6dHrJa3NG0uG/WLt6W45V4DYAfaTCQ==",

    // service:commands
    ["main-service-save"]: "8mJgbi2P//GXdCs3z4UuGYQIHm9uiym5aZMvJd2v3SeiCIkr15LMfQ==",
    ["main-service-remove"]: "KqdwM/RUZt5KNhniVb9aN3MaEBFFtIviR3BzWfUiBarAbdGTEVwWjg==",

    // service:queries
    ["main-service-byId"]: "Bf1z0rZS0P3obU4utLtTFA/42dl/2x61P1lSAh6/YhV9RbcFsdKoBw==",
    ["main-service-search"]: "bNFBmcqr90axeRval36ORsjJBlJOQgf9vYm12rnJufkN3lUHwAYoag==",

    // serviceOrder:commands
    ["main-serviceOrder-create"]: "5JLxfb8gy7SjmXPXoG/1DMZMg9V/q8iReWtG5dvu66X49ASad1ynrw==",
    ["main-serviceOrder-update"]: "Fck4wBUsbZwKqMGTeLwONXzxN7gR2wNLCovqadZklvZtWc6yJxuKuA==",
    ["main-serviceOrder-updateItem"]: "n9sMa1pzv40pSdu4o9SMvccfFm0CBq6tCmJE2QKbXSmp/Uk0IFFnxg==",
    ["main-serviceOrder-paymentAdd"]: "YyestJAfcdKBLxaUmVMcMaE3q8V7/D37mKc6Y3Qbci7lEl7lf/p0zQ==",
    ["main-serviceOrder-paymentRemove"]: "isWCIA00ZpDbvPndnTclfu0HiynxY47AxPM4DwQnBHawNi7yKzaLMw==",
    ["main-serviceOrder-cancel"]: "3vCd7sj7jsSmmRPrvU/TqaGcQKy4OZkTxE8vQ3bnrwQ6Rpmll0i/xQ==",
    ["main-serviceOrder-close"]: "aOdnGqlZjpWV8uatMmtK45MGQ0JUm3359T3YdGraZakR4lKEruyZtw==",

    // serviceOrder:queries
    ["main-serviceOrder-dashboard"]: "tKaBWnnJyO69isI35CeIW7dkah3ERoQy6ubMxy5tJbKUgGxeFlkLaA==",
    ["main-serviceOrder-pdf"]: "LVfaIFw75NceFmhHRO4wlGslBmvU0EiBKxZCjDuDyNJBvrYKhhYyxg==",

    // stock:queries
    ["main-stock-byId"]: "32zKsi/fPKwqw1UH1cESVdUiekjAKrMSkYGRUlZOhG6cOYLckQ4dFw==",
    ["main-stock-byBarcode"]: "yWigceFNMl6DIJUdXwHUAVmiiqTFKq4MBhaxzuDN9T5Ltk3LPodOPQ==",
    ["main-stock-search"]: "uSJC63gGjEOSVtUgJ8xe1IZCou1RV/7NQisBIpPVNLMasaJzqs5Sgg==",

    // storeOrder:commands
    ["main-storeOrder-cancel"]: "L/hUMobIB6RHlXdxoEId52dNez1yLLONuEwPJyRpWTeuZw0BkWyoYg==",
    ["main-storeOrder-close"]: "7YCw5n2CN/SK8R3KsniP44jGNwOFAHZPpMnrf3VJmVDiI9h/9Gr6tw==",
    ["main-storeOrder-create"]: "h5DQ41AL23JY2e8iMlaEeZajGdvIPfslTGIrYeWNRVgJWKaSAhXFVQ==",
    ["main-storeOrder-paymentAdd"]: "mN79fj7qnawd8EWlB/qxdelMJiMspiyTABbnYReK5Ai30Yp5a2rtuQ==",
    ["main-storeOrder-paymentRemove"]: "m4pHDnGhEcUrLrHFeG/I5ZvFgiM1bqpa9haK7IiErTHuf3vRH63sag==",
    ["main-storeOrder-productAdd"]: "AT6KQOh27Qem8IzOjgWtOM6J/lAVmW52ylJ80gY/LsI42Eh4rSaRVQ==",
    ["main-storeOrder-productRemove"]: "H8o1sAfrmWGF6KkSqzgBcFtEKZgM/UJ0nuErAgfZF3NM8E3Oc6J0yA==",

    // storeOrder:queries
    ["main-storeOrder-byId"]: "/ccl6lzaczOTblt6cTY3rfu1hMxOHL3FUY5QMZVGKXeichkDanU/8g==",
    ["main-storeOrder-byPeriod"]: "s2OV/dtY0Ds6G7bg72h0sCnTuFWEww63SdpRAsAkEGVX0QV2TGe6rQ==",

    // vehicle:commands
    ["main-vehicle-save"]: "IhGBKDg3P0tgxpSEfMGjp/WtoUgc3uXkXyGJ7izAx/JwcgK2yji6pg==",

    // vehicle:queries
    ["main-vehicle-byId"]: "FfGmzEH7s6Gol/crdrkMYrauI1c1erX02QvvYOJRHOH6xJMexzwa5Q==",
    ["main-vehicle-byPlate"]: "iOVmzQyTSmIQUabwSxjDivoKaVjIMkHECxWqh11DaDUtX8tkq9Wo9g==",

    // fipe:queries
    ["main-fipe-marcas"]: "iOVmzQyTSmIQUabwSxjDivoKaVjIMkHECxWqh11DaDUtX8tkq9Wo9g==",
    ["main-fipe-modelos"]: "ouJuU0IFouBtvbSgb7/6n6LsjRZmKvrYfiCeQRwV2rYIWS4mfwaaAg==",
  },
};

export default stagingConfig;
