import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import GridItemText from "../../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../../components/UI/LoadingLabel/LoadingLabel";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import { serviceOrderClosed } from "../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const CloseServiceOrderModal = (props) => {
  const { serviceOrder } = props;
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();

  useEffect(() => {
    if (data) {
      toast.success("Ordem de Serviço finalizada");
      dispatch(serviceOrderClosed(requestExtra));
    }
    if (errors) {
      toast.error(errors.status);
    }
  }, [data, errors, requestExtra]);

  const onClose = useCallback(() => {
    if (!loading) {
      dispatch(dashboardGarageActions.setCloseServiceOrderModalShow(false));
    }
  }, [loading, dispatch]);

  if (!serviceOrder) return null;

  const checkIcon = <CheckCircleIcon style={{ color: "green" }} />;
  const errorIcon = <ErrorIcon style={{ color: "red" }} />;

  // Checks
  let allDone = _.every(serviceOrder.items, (item) => !!item.done);
  let allPayed = Math.abs((serviceOrder.garageOrder?.totalDue ?? 0) - serviceOrder.paymentsTotal) <= 0.05;

  //TODO Products and Services OK? based on AI check if the items are aligned with the OS items

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="fechar ordem de serviço">
      <DialogTitle id="max-width-dialog-title">Encerrar Ordem de Serviço?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(errors || {}).status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{(errors || {}).status}</Alert>
            </Grid>
          )}
          <GridItemText md={6} label="Cliente" text={serviceOrder.customer.name} />
          <GridItemText
            md={6}
            label="Veículo"
            text={`${serviceOrder.vehicle.brand} ${serviceOrder.vehicle.model} | ${serviceOrder.vehicle.plates}`}
          />
          <Grid item md={12}>
            <span>{allDone ? checkIcon : errorIcon} Todos os itens da OS feitos</span>
          </Grid>
          <Grid item md={12}>
            {allPayed ? checkIcon : errorIcon} Pagamento realizado
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            request(
              apiRoutes.main.serviceOrder.close,
              {
                serviceOrderId: serviceOrder.id,
              },
              { requestExtra: serviceOrder }
            );
          }}
          color="secondary"
        >
          <LoadingLabel loading={loading} text="Encerrar OS" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseServiceOrderModal;
