import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import colors from "../../../data/colors";

const VehicleColorsSelect = (props) => {
  const [selected, setSelected] = useState(null);
  const { color } = props.formik.values;

  useEffect(() => {
    setSelected(colors.find((item) => item.text === color) || null);
  }, [setSelected, color]);

  return (
    <Autocomplete
      options={colors}
      getOptionLabel={(option) => option.text || "Carregando..."}
      value={selected}
      noOptionsText="Nenhuma cor encontrada"
      onChange={(e, color) => {
        setSelected(color);
        props.onChange(e, color);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Cor"
          placeholder="Cor do veículo"
          disabled={props.formik.isSubmitting || props.disabled}
          error={props.formik.touched[props.field] && Boolean(props.formik.errors[props.field])}
          helperText={props.formik.touched[props.field] ? props.formik.errors[props.field] : ""}
        />
      )}
    />
  );
};

export default VehicleColorsSelect;
