import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import GridItemText from "../../../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../../../components/UI/LoadingLabel/LoadingLabel";
import useApiClient from "../../../../../hooks/apiClient";
import apiRoutes from "../../../../../hooks/apiClient.routes";
import { serviceRemoved } from "../../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../../redux/dashboardGarage/dashboardGarageSlice";

const ServiceRemoveModal = () => {
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const { serviceOrders, selectedIndex, servicePicked } = useSelector((state) => state.dashboardGarage);

  useEffect(() => {
    if (data) {
      toast.success("Serviço removido");
      dispatch(serviceRemoved(requestExtra, servicePicked.id));
    }
  }, [data, errors, servicePicked, requestExtra]);

  const onClose = useCallback(() => {
    if (!loading) {
      dispatch(dashboardGarageActions.setServiceRemoveShow(false));
    }
  }, [loading, dispatch]);

  if (!servicePicked) return null;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="remover serviço">
      <DialogTitle id="max-width-dialog-title">Remover Serviço?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(errors || {}).status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{(errors || {}).status}</Alert>
            </Grid>
          )}
          <GridItemText md={6} label="Categoria" text={servicePicked.category} />
          <GridItemText md={6} label="Subcategoria" text={servicePicked.subcategory} />
          <GridItemText md={12} label="Descrição" text={servicePicked.shortDescription} />
          <GridItemText md={6} label="Quantidade" text={servicePicked.quantity} />
          <GridItemText md={6} label="Valor Unit.(R$)" text={servicePicked.price} />
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <b>TOTAL R${(servicePicked.price * servicePicked.quantity || 0).toFixed(2)}</b>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            const garageOrderId = serviceOrders.active[selectedIndex].garageOrderId;
            request(
              apiRoutes.main.garageOrder.removeService,
              {
                garageOrderId: garageOrderId,
                itemId: servicePicked.id,
              },
              { requestExtra: garageOrderId }
            );
          }}
          color="secondary"
        >
          <LoadingLabel loading={loading} text="Remover" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceRemoveModal;
