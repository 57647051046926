import apiRoutes from "../hooks/apiClient.routes";
import { dashboardGarageActions } from "../redux/dashboardGarage/dashboardGarageSlice";

export const serviceOrderChangedHandler = async ({ dispatch, params }) => {
  const serviceOrderByIdRoute = apiRoutes.main.serviceOrder.byId;
  var url = new URL(serviceOrderByIdRoute.url);
  var data = {
    code: serviceOrderByIdRoute.code,
    id: params.serviceOrderId,
  };
  for (let key in data) {
    url.searchParams.append(key, data[key]);
  }

  await fetch(url, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      response
        .json()
        .then((result) => {
          if (result.success) {
            dispatch(dashboardGarageActions.signalRServiceOrderChanged(result.value));
          }
        })
        .catch((e) => console.error(e));
    })
    .catch((e) => console.error(e));
};
