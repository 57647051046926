import BallotIcon from "@material-ui/icons/Ballot";
import React, { useEffect, useState } from "react";
import HotButton from "../../../components/UI/HotButton/HotButton";
import SearchView from "../../../components/UI/SearchView/SearchView";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import ProductItemList from "./ProductItemList";
import ProductSearchPanel from "./ProductSearchPanel";
import { Badge, CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router";

const cols = [
  {
    name: "Descrição",
    selector: "shortDescription",
    format: (row) => `${row.shortDescription} ${row.manufacturer ?? ''} ${row.manufacturerCode ?? ''}`,
  },
  {
    name: "Estoque",
    selector: "quantity",
    right: true,
  },
  {
    name: "Preço (R$)",
    selector: "price",
    right: true,
  },
];

const ProductSearch = (props) => {
  const { title, asComponent, onRowClicked } = props;
  const navigate = useNavigate();
  const { request, data, errors, loading } = useApiClient();
  const [pendingCompanyOrder, setPendingCompanyOrder] = useState(null);

  var hotButton = loading ? (
    <CircularProgress />
  ) : (
    <HotButton
      hotkeys="ctrl+n"
      onClick={() => {
        navigate("/app/produtos/pedidos/entrada", {
          state: {
            pendingCompanyOrder,
          },
        });
      }}
    >
      Entrada de Pedido
    </HotButton>
  );

  if (pendingCompanyOrder && hotButton) {
    hotButton = (
      <Badge color="secondary" badgeContent="!" title="Existe um lançamento pendente">
        {hotButton}
      </Badge>
    );
  }

  useEffect(() => {
    request(apiRoutes.main.app.pendingOperations, null);
  }, [request]);

  useEffect(() => {
    if (data && data.success) {
      setPendingCompanyOrder(data.value.pendingCompanyOrder);
    }
  }, [data, errors]);

  return (
    <SearchView
      title={asComponent ? null : (title ?? "Produtos")}
      icon={asComponent ? null : <BallotIcon />}
      placeholder="Código de barras do produto ou código"
      cacheKey="product-search"
      cols={cols}
      searchRoute={apiRoutes.main.stock.search}
      expandableRows={false}
      expandableRowsComponent={<ProductItemList />}
      defaultActionButton={asComponent ? null : hotButton}
      onRowClicked={(row) => {
        if (typeof onRowClicked === "function") {
          onRowClicked(row);
        } else {
          navigate("/app/produtos/form", { state: { id: row.id } });
        }
      }}
    />
  );
};

export default ProductSearch;
