import React from "react";
import { fDate } from "../../../utils/format";

const ProductItemList = (props) => {
  const { data } = props;

  if (!data.items || !data.items.length) return null;

  return (
    <table>
      <thead>
        <tr>
          <th>Entrada</th>
          <th>Última Saída</th>
          <th>Estoque</th>
          <th>Preço(R$)</th>
        </tr>
      </thead>
      <tbody>
        {data.items.map((item) => {
          return (
            <tr key={item.id}>
              <td>{fDate(item.creation)}</td>
              <td>{fDate(item.lastSale)}</td>
              <td>
                {item.quantity} {item.productUnit || "Un"}
              </td>
              <td>{item.price.toFixed(2)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ProductItemList;
