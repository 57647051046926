import { Typography } from "@material-ui/core";
import { format } from "date-fns";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const cols = [
  {
    name: "Descrição",
    selector: "description",
    width: "25%",
  },
  {
    name: "Detalhes",
    width: "55%",
    selector: (row) => `Recebido por ${row.userName} em ${format(new Date(row.processed), "dd/MM/yyyy HH:mm")}`,
  },
  {
    name: "Valor(R$)",
    width: "20%",
    selector: (row) => row.amount.toFixed(2),
    right: true,
  },
];

const TabPayments = () => {
  const { serviceOrders, selectedIndex } = useSelector((state) => state.dashboardGarage);

  const dispatch = useDispatch();

  const selectedServiceOrder = ((serviceOrders || {}).active || [])[selectedIndex];
  if (!selectedServiceOrder) return null;

  const leftOver = selectedServiceOrder.totalDue - selectedServiceOrder.paymentsTotal;

  return (
    <>
      <DataTable
        columns={cols}
        data={selectedServiceOrder.payments}
        noDataComponent={
          <b>
            <br />
            Nenhum pagamento foi adicionado
          </b>
        }
        noHeader
        pointerOnHover
        striped
        dense
        responsive
        onRowClicked={(row) => {
          dispatch(dashboardGarageActions.setPaymentRemoveModalShow(row));
        }}
      />
      {!!selectedServiceOrder.paymentsTotal && (
        <Typography variant="h6" style={{ textAlign: "right" }}>
          Total Pago R$ {selectedServiceOrder.paymentsTotal.toFixed(2)}
        </Typography>
      )}
    </>
  );
};

export default TabPayments;
