/**
 * Settings for production environment
 * yarn build:prod
 */
const prodConfig = {
  env: "prod",
  signalRHost: "https://movi-serverless-main.azurewebsites.net",
  hosts: {
    main: "https://movi-serverless-main.azurewebsites.net",
  },
  codes: {
    // App:Queries
    ["main-app-init"]: "aoX0P4MJqopQL7mWvx9Wx93lp2ffRgsiNSoWhCdDB3vhjweyIXqeKQ==",
    ["main-app-pendingOperations"]: "EWPag3shgt9Vd73SZL1sPpKqfoYSIPZ5deJ0oORkWmlrBPB8cJJSPA==",

    // Auth:Commands
    ["main-auth-signInForgotPassword"]: "pI/V9G4P3PPFl9TKWL2xaUtulnuLj6PZeB0LkS107ptv8ezcRub11A==",
    ["main-auth-signInResetPassword"]: "A4KhmXvEzvg7rJfXKzZWigO5qnTWZj02coaKHiGzB5k/nVGlma9pPQ==",
    ["main-auth-signUpEmail"]: "eFZ9dKHyaNDyqp9XXEwG0J8HLn12N5DDaEQ5Yb4wlaZkx92ag91Gdw==",
    ["main-auth-signUpEmailVerification"]: "ig4n039LXsIPBAlI9LdJH8Wlto49SRvV4tuf5sxYfJPZNg7WefUuqg==",
    ["main-auth-signUpEmployeeUserInvite"]: "iAVX3OhgGh4BLK2tcKMENEVu6jxnJUF0kHDR2G3BrNw/hB8/8T44vw==",
    ["main-auth-signNewsletter"]: "rQ840yKp35HBrMaw6vkP19IAn5VGaVv2DtHvU5D9haec44ZzNi6cFQ==",
    ["main-auth-signInBasic"]: "Gt7dN9BfXFarazDO6bQAF/WWWcGpqgn8p9z1D2HnkbC8vBbXVLa7hQ==",
    ["main-auth-signOutBasic"]: "nrO5ygAuPJfDbAVSxoyaiEAeyf/9X4qlCJAQUG5APebbljKxIuNzLw==",

    // Auth:Queries
    ["main-aux-auxDataBankGetAll"]: "pbQQkEBbwY70TWseia37laiawdAJWYqcf4TPOG39fnxDCGxgylgXqg==",

    // Commands
    ["main-bankAccount-bankAccountSave"]: "QGgbUoRmKlO2Ky5DyeJNNaquPziS7Ju19KYowyYTKfTB6vZ0dWezCQ==",
    ["main-bankAccount-bankAccountRemove"]: "IC6F16WYf80Igo56TWqhnWEyKCabArpO5s/sIIQxHarwe1FQEmeQGQ==",

    // Queries
    ["main-bankAccount-bankAccountById"]: "Ag7N1JGi7gWZiGaMlVGzayh9FcCKlmd6F52caSjSpGd2OrTMRRs/1w==",
    ["main-bankAccount-bankAccountGetAll"]: "zNrQ2j6yPgBeu42nmqC7vKlhJOi6ehn2NB8gmRt6qgIv6mKSUmpmcA==",

    ["main-cache-serviceCategories"]: "TNUHYyB3CV/PPhFbT7vq7JeBWbO3Lr1XmrS4yZsA9THcH3pSJBUPhA==",
    ["main-cache-productCategories"]: "xaiNkJPjVwjUmy2ODonrvJWZ2uAuiOWCminxWPXXEVjPOYrQvF897g==",
    
    // Commands
    ["main-companyOrder-save"]: "zo8JmaGs2LDzJlSXESc4aL0VwWOuDw7Ax9MBH5UCFgZaRcr0mNiTFQ==",
    ["main-companyOrder-addItem"]: "lrlEmJNba1uxTY1ae0R/Oc5En1M3Hd/yETUm5j0UetIZ7UJyROraIA==",
    ["main-companyOrder-removeItem"]: "7jQax1BGAIaZRZuR9p0ATBj320jvQgu5Dw7iRDxwK27OycHI6PJ3lQ==",
    ["main-companyOrder-cancel"]: "CWfm76jHCCMvK2MiwCM/RFtv5b1XJxxt9IIHH0Parkxopc4eYP3QHg==",
    ["main-companyOrder-finishRegistration"]: "h2rDpQadmTtgbHFeOolpnmDOy1OC6oVTOKftjt20bNdgS/gSszwGaA==",

    // Queries
    ["main-companyOrder-byId"]: "AD3rC5CrPh6/WjdadvxQXdRkcitQM13vC2ZYHoaLxijsps6bwEaKuA==",
    ["main-companyOrder-search"]: "iYTs/VvQSaBv9CoQEDDKlO6g2kuK23sFvqbuMg55HWJm2oKSPSghLA==",

    // Commands
    ["main-debt-debtAdd"]: "asDWsvXrGRPgeLIxrT9jxQ8rvYavPFC2ceUNev1h9Cw3qbh/oFuNfg==",
    ["main-debt-debtCancel"]: "hGUk1j9i3y2/DmobM6J1kU6s5R9WefrAY/Z5yGSPkPIWkyeees303w==",
    ["main-debt-debtInstallmentPay"]: "wYafzeUFIPdf6McjPCJ5Hnmppych2TEizqBO85VodT9CiS4rKuIsbg==",
    ["main-debt-debtInstallmentUpdate"]: "HPrw/0Bgszjdw/0DywPJcfgRooBYhNqaCVMK74a1DfzrkjbQvQkFDg==",
    ["main-debt-debtRemove"]: "1SAtz4Q9J0NdMMdz5VW7SOh8gAlw40deR8CW9l7jslSyNd9A1Bhluw==",

    // Queries
    ["main-debt-debtById"]: "D2bRvctAUdQ36BEJLRRHgU1cM6xIuMNciAiXcTgeNF8ra/tPnDfoBg==",
    ["main-debt-debtByCompanyOrder"]: "kCrpMOe3n1PcTqL4IAsaRHvBrkLBNRH5/b8DOtc1MBHaBAqbDc5qEQ==",
    ["main-debt-debtSearch"]: "WGD982mioMNPdC3PE4HhajPa5kxujgLir9qgQuABNhnzBrLQrwLAEw==",

    // Commands
    ["main-garageOrder-addProduct"]: "RTK74xV9qTPCYuOROhnCMAIOSaVQ7kxQdW3earaBLpbo54sfirrhMQ==",
    ["main-garageOrder-removeProduct"]: "lcju5HOuwUPnd5H9IanMECLGleWgN84/plMQfaC8rKV2Sz1lj7IFLQ==",
    ["main-garageOrder-addService"]: "Jevj/QAmMcI2JCbhPHRqFeTvOL9TZSwBbiajjk0pWJLOw7d5mkY3pA==",
    ["main-garageOrder-removeService"]: "IqmApUQrvOGnkoCUTsWBX78NNsBPgx8JoaUurpaYHPWaXqJ6aaFzqg==",

    // Queries
    ["main-garageOrder-pdf"]: "YsFNqHIbKNLnsBy8NfsnsSgQ06RYhCehBVFictuGT5hhH/gpGecWCQ==",

    // Commands
    ["main-person-employeeSave"]: "l18Ko2BckXlPyLZwR5d/LbPzfIa21SnZDzQQMcLgIVdpaBgclWi6KA==",
    ["main-person-customerSave"]: "CCawaHT8Th9287ynu94UsBaYnG9/4U8UELG1YUFZozJtb8PFfjHVuQ==",
    ["main-person-supplierSave"]: "yWEQzVEm/NcoL3Rp13kVxlbhHqnvN4JSWj6YNgppJTadlIPygJ/I6Q==",

    // Queries
    ["main-person-customerById"]: "yFUqin4PxlrIx2xhkbWgza3picZWoYXQtltk064SYXokuSIs1ossNA==",
    ["main-person-employeeById"]: "PSIrv8iUoSpTccFWQWhPhpQIsiZValFXjnF8/SyNzK5MLpHw1BpQWw==",
    ["main-person-supplierById"]: "4EWysMyrja5hz0Cj1HeQYaLCl4vjWWeba1shhGSXgbLGpnAtgHPgwA==",
    ["main-person-customerSearch"]: "rdkwB1voW4oCxdaaucfgzRV63O7qJZavvZciUZc7OrKYZyCr0kCqYQ==",
    ["main-person-employeeSearch"]: "xRaYjubwlEqm6jC4k/0NeZJfPeKQMyzefnsFfaOibdXzspoH2x9mtA==",
    ["main-person-supplierSearch"]: "KBG0r908kf3jn7Al2cAFxWsufqe00e55YCs1Mu9AIt8CAdNzdwUxXg==",
    ["main-person-employeeGarageStaff"]: "u68LCFdAraa6IZvJa6HU3gHUjBZ6Qr36BbUOEjGSYOjvqck/Zv8NJg==",


    // Commands
    ["main-product-save"]: "3sYa26QPhZANDSbAK0kv8/hNW7pWsOjEErhJyCGIsM9RI8J8A7kvQw==",
    ["main-product-manufacturerItemSave"]: "V6j9QuO0ic735WUtzNafWNl7wQsaanC80Zi2WKg5SpjsEg7kWYpajQ==",
    ["main-product-manufacturerItemRemove"]: "M7DovQnsnKyZaA9uAmJZID/VazR6R1znuZaAXYa7LMV6DV3w2CaSRw==",

    // Queries
    ["main-product-productGroups"]: "/kWWegB7MR6ZE33Iy4xt/Rc782dI6Ul2QVRKs6UuxffvxY5D7tsuJw==",
    ["main-product-byBarcode"]: "o9vmO31Y5dz9pdq8n/mWdOOShR79zjSfLITlnf5R99J8HYLPERtS0w==",
    ["main-product-byCode"]: "VwUKQK243hT4syk3AeC33avHCTwTyZsqxnt7eg5rmA043DPaXVASvw==",
    ["main-product-byGroup"]: "Fibx2MoIwXevKnJpLpkBaIIdyYC5K6paVvaci79rFgrf4faCaPZYrg==",

    // Commands
    ["main-service-save"]: "OmbmAJlAkvHGPVKGmbIY1w4v9HQHkj0ZWoKwBKjtFD/GLB7Nj1Fwaw==",
    ["main-service-remove"]: "8wdFB6dUTX0I5LRQrNyfw1KALtsN1SYQSaZJ4D3eSurnJ8gmbq0Cmw==",

    // Queries
    ["main-service-byId"]: "lkfaWizUgpLNlZKbqndbHazMBOJE4EqtLgvrIaODv8rRcRxt4Ak2MQ==",
    ["main-service-search"]: "KUxnH90aaCXXzIRChbHEa3fMI8unNyGgeco0xE46AlEujey2T5wu0A==",

    // Commands
    ["main-serviceOrder-create"]: "189Djjtt/0pBb2axLolEL3PvFHXVDg140b9kDh5IXI5qcdfNChJI0g==",
    ["main-serviceOrder-update"]: "qCk0FIc7C4aet0vNabcDGaEAKiaMeY1BAuE1r6FtuvJIEDNHKfFVRA==",
    ["main-serviceOrder-updateItem"]: "NaCC5GhVVoHKAqQy5n3m5CQy4znCXmO5O1s4i6zGHniaqUEKSMxnow==",
    ["main-serviceOrder-paymentAdd"]: "ejmnQRIioSYab3O8SNkeoFzXGnV2N5nA8dNEFwOwjcvn47oOiFungA==",
    ["main-serviceOrder-paymentRemove"]: "hO9dy6dJGlZGuD/WbaSXHzB69tpJOlyrNLgUZYNKtQYr1KTOpSSqkQ==",
    ["main-serviceOrder-cancel"]: "ea1VLX6yraI6a9gCYPRdpfXkATaUE37/4Z78bupe9xFz6XdVcVyraQ==",
    ["main-serviceOrder-close"]: "mcr/xh2Uu56g/uq4eRCbzeyNOfQ2tVzDKoFHPoD1xSghDbinol2usg==",

    // Queries
    ["main-serviceOrder-dashboard"]: "If3Ht75VhvBahAasHviHL4a1kpYR3gUKssh3aF6z2WoofrSq9GgB0Q==",
    ["main-serviceOrder-byId"]: "JcjguSEbdb8Y2gvel3AdvOk81o6eUGhXzfgYbxbzI7wCveuFPivHDg==",
    ["main-serviceOrder-pdf"]: "FWrbHCdfVjKjQca9WT3gQnjpgsvS6NK4mCSlUu2eyzOImiwOOq2Irg==",
    ["main-serviceOrder-history"]: "e1QRJN0io9w0stoxaKpc/DTegpSOk2JV6rriitFiYCbwAaLm2RqN8A==",

    // Commands
    ["main-stock-save"]: "9LyLdv0i19zeE65nWzOzwvJkd0kSe77UU/PkYNtMgF90mbavWPnktQ==",

    // Queries
    ["main-stock-byId"]: "M3sMjbsuX0IaPnaHBoAWeCHz95NUa2MUpa6eGV0HrqGqqa0iI8f0tA==",
    ["main-stock-byBarcode"]: "aG2oeaQ1tGaaeWGKYikMrkVTa8tUA8JOvaql0VDYNTa6MEQIAODdMg==",
    ["main-stock-search"]: "RW4I7Qyi51Dm9/8N0dZzF57CGEvaYaBasEDQHS08UE47OK3p2sZaxg==",

    // store:Order:commands
    ["main-storeOrder-cancel"]: "/6Hkf/poZgcWgrpgg7yvCYYB8c/CWvpznPM0CTb09rGr/pkRknYofQ==",
    ["main-storeOrder-close"]: "TPtac2OdckZNjXUM7pCHSp5CAqYrF50awNSOVVrhZAlqvFna1dzcNw==",
    ["main-storeOrder-create"]: "EyXsbQToFdcjS7T1l7uyZy5Vs52NutS0QdR7NdsvMtIwkHBu72IexA==",
    ["main-storeOrder-paymentAdd"]: "A03JlG3aaZR6jpNLOJ0sPMPoENttNg8fMRWytlbDzRoDiDq1o7BMkg==",
    ["main-storeOrder-paymentRemove"]: "xNqeaaskmOg/Sv79/ws1Ci3l7bLG4FdCIOUX7kcdiVg1YfPDxILqVQ==",
    ["main-storeOrder-productAdd"]: "2whqtl3i1PdqfWN/fwPyaUFKl4jvQ3pP7uxUJVxMIwA5Eb9uMsMO8A==",
    ["main-storeOrder-productRemove"]: "4Sc7s37TUsko5G5ORO8oaVIa1GKd7gCNdO/yGFJFdpebI4K6EjQ4uw==",

    // storeOrder:queries
    ["main-storeOrder-byId"]: "qJlOl9aJowLnMA/Snth1mtRRYasFFM23oRpnaZHWikv4XCpZgnB6iQ==",
    ["main-storeOrder-byPeriod"]: "36EIkJQat2LHGHj6GaSUgOxMbkVce8baQbz2HpaucLbtAbVuVfPLrw==",

    // vehicle:commands
    ["main-vehicle-save"]: "/KxwwRa7QLI2Z28xKdtHjyjaSa7gCqd9PzJR5X1yrZqTSRm5SsEu8w==",

    // vehicle:queries
    ["main-vehicle-byId"]: "medmSzyy6mfCJzqILy4YROUAUUlAL5loP83wxy9NvuQyKnfKLOyt7w==",
    ["main-vehicle-byPlate"]: "DonBBZQeETgSa0arEYp1xzGNSG9G2BmajyqBVuVa0zPWWbnklkqsKA==",

    // fipe:queries
    ["main-fipe-marcas"]: "4R/32Ry3m4e6P9OVI64QajwZNi5Zle56nDd1b6yVxsXw2i8hqWqveg==",
    ["main-fipe-modelos"]: "0qS87PfsQak/ZNYv5gDf7NNb3yIIxJQGi8gP6AzHHf9HU9jtGaa5Ig==",
  },
};

export default prodConfig;
