import { Button, Card, CardContent, Grid, LinearProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import * as Yup from "yup";
import MSelectFieldPaymentType from "../../../components/UI/MSelectField/MSelectFieldPaymentType";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import { fPaymentType } from "../../../utils/format";
import classes from "../../__Containers.css";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import _ from "lodash";
import CompanyOrderPaymentInstallments from "./CompanyOrderPaymentInstallments";

const cols = [
  {
    name: "Descrição",
    selector: "id",
    format: (row) => fPaymentType(row.paymentType),
  },
  {
    name: "Parcelas",
    right: true,
    selector: "installmentsCount",
  },
  {
    name: "Total (R$)",
    selector: "amount",
    right: true,
    format: (row) => row.amount.toFixed(2),
  },
];

const initialValues = {
  form: "", //debt or installment

  //debt
  companyOrderId: "",
  supplierId: "",
  supplierName: "",
  description: "",
  bankAccountPayload: "",
  paymentType: "fcb",
  installments: "1",
  amount: "",

  //installment
  debtId: "",
  debtInstallmentId: "",
  bankAccountPayload: "",
  docNumber: "",
  fcbBarcode: "",
  fcbLinhaDigitavel: "",
  due: null,
  selectedIndex: -1,
};

const validationSchema = Yup.object({});

const CompanyOrderPaymentsCard = (props) => {
  const [debts, setDebts] = useState([]);
  const [editingDebt, setEditingDebt] = useState(null);
  const { companyOrderId, supplier, total = 0 } = props;
  const { request, data, errors, loading, requestId } = useApiClient();
  const {
    request: deleteItemRequest,
    data: deleteItemData,
    errors: deleteItemErrors,
    loading: deleteItemLoading,
  } = useApiClient();

  useEffect(() => {
    if (companyOrderId) request(apiRoutes.main.debt.debtByCompanyOrder, { companyOrderId }, { requestId: "get-debts" });
  }, [companyOrderId]);

  useEffect(() => {
    if (requestId !== "get-debts") return;
    if (data) setDebts(data.value);
  }, [data, errors, requestId]);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      actions.setStatus("");

      if (values.form === "debt") {
        request(
          apiRoutes.main.debt.debtAdd,
          {
            ...values,
            companyOrderId: companyOrderId,
            supplierId: supplier.id,
            supplierName: supplier.name,
            description: "",
            bankAccountPayload: "",
            paymentType: values.paymentType,
            installments: values.installments,
            amount: values.amount,
          },
          {
            requestId: "add-debt",
          }
        );
      }

      if (values.form === "installment") {
        request(
          apiRoutes.main.debt.debtInstallmentUpdate,
          {
            debtId: editingDebt.id,
            debtInstallmentId: values.debtInstallmentId,
            bankAccountPayload: values.bankAccountPayload,
            docNumber: values.docNumber,
            fcbBarcode: values.fcbBarcode,
            fcbLinhaDigitavel: values.fcbLinhaDigitavel,
            due: values.due,
            amount: values.amount,
          },
          {
            requestId: "installment-update",
          }
        );
      }
    },
  });

  const { values, setFieldValue, setSubmitting, setStatus, setErrors } = formik;

  useEffect(() => {
    if (requestId === "add-debt" && data) {
      setEditingDebt(data.value);
    }

    if (requestId === "installment-update" && data) {
      setFieldValue("selectedIndex", -1);
    }

    setSubmitting(false);
    setErrors((errors || {}).schema || {});
    setStatus((errors || {}).status || "");
  }, [data, errors, requestId, setSubmitting, setErrors, setStatus]);

  useEffect(() => {
    if (deleteItemData) {
      toast.success("Pagamento removido");
      setEditingDebt(null);
      setDebts(debts.filter((item) => item.id != deleteItemData.value.id));
    }
    setStatus((deleteItemErrors || {}).status || "");
  }, [deleteItemData, deleteItemErrors, setStatus]);

  if (!props.companyOrderId) return null;

  return (
    <Grid item xs={12} md={6}>
      <form onSubmit={formik.handleSubmit}>
        {(loading || deleteItemLoading) && <LinearProgress />}
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {formik.status && (
                <Grid item xs={12}>
                  <Alert severity="error">{formik.status}</Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <span className={classes.SectionTitle}>Faturamento</span>
              </Grid>
              {!editingDebt && (
                <>
                  <Grid item xs={12} md={4}>
                    <MSelectFieldPaymentType field="paymentType" formik={formik} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MTextField field="installments" label="Qtd. Parcelas" type="number" step="1" formik={formik} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MTextField field="amount" label="Valor Total (R$)" type="number" step="0.01" formik={formik} />
                  </Grid>
                </>
              )}
              {editingDebt && (
                <>
                  <GridItemText label="Forma" text={fPaymentType(formik.values.paymentType)} />
                  <GridItemText
                    label="Valor Total(R$)"
                    text={_.sumBy(editingDebt.installments, (item) => +item.amount).toFixed(2)}
                  />
                  <Grid item xs={12} md={12}>
                    <CompanyOrderPaymentInstallments formik={formik} debt={editingDebt} />
                  </Grid>
                </>
              )}
              <Grid item xs={12} md={12}>
                <Button
                  variant="text"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    formik.setFieldValue("form", "debt");
                    if (!editingDebt) {
                      formik.submitForm();
                    } else {
                      setDebts([
                        ...debts,
                        {
                          paymentType: formik.values.paymentType,
                          installmentsCount: formik.values.installments,
                          amount: _.sumBy(editingDebt.installments, "amount"),
                          ...editingDebt,
                        },
                      ]);
                      setEditingDebt(null);
                      formik.resetForm();
                    }
                  }}
                >
                  Adicionar
                </Button>
                <Button
                  variant="text"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    if (editingDebt) {
                      deleteItemRequest(apiRoutes.main.debt.debtRemove, {
                        debtId: editingDebt.id,
                      });
                    }
                    formik.resetForm();
                  }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <DataTable
                  columns={cols}
                  data={debts}
                  noDataComponent="Nenhum pagamento para exibir"
                  noHeader
                  pointerOnHover
                  highlightOnHover
                  striped
                  responsive
                  onRowClicked={(row) => {
                    deleteItemRequest(apiRoutes.main.debt.debtRemove, {
                      debtId: row.id,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ textAlign: "right" }}>
                <h4>Total Pedido R${total.toFixed(2)}</h4>
                <h4>Total Pago R${_.sumBy(debts, "amount").toFixed(2)}</h4>
                <h4>SALDO R${(total - _.sumBy(debts, "amount")).toFixed(2)}</h4>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </Grid>
  );
};

export default CompanyOrderPaymentsCard;
