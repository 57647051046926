import { Dialog } from "@material-ui/core";
import React from "react";
import SupplierSearch from "../SupplierSearch/SupplierSearch";

const SupplierSelectModal = (props) => {
  const { show, handleClose, handleSelected } = props;
  if (!show) return null;

  return (
    <Dialog open maxWidth="lg" fullWidth onClose={handleClose} aria-labelledby="form-dialog-title" scroll="body">
      <SupplierSearch
        title="Selecione o Fornecedor"
        handleSupplierSelected={(supplier) => {
          handleClose();
          if (supplier) handleSelected(supplier);
        }}
      />
    </Dialog>
  );
};

export default SupplierSelectModal;
