import { Grid } from "@material-ui/core";
import { useState } from "react";
import { AntTab, AntTabs } from "../../../components/UI/AntTabs/AntTabs";
import TabGeneral from "./TabGeneral/TabGeneral";
import TabProducts from "./TabProducts/TabProducts";
import TabPayments from "./TabPayments/TabPayments";
import TabServiceOrder from "./TabServiceOrder/TabServiceOrder";
import TabServices from "./TabServices/TabServices";
import TabThirdParties from "./TabThirdParties/TabThirdParties";

const DashboardGarageTabs = (props) => {
  const { selectedServiceOrder } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  let selectedTab = null;
  switch (selectedTabIndex) {
    case 0:
      selectedTab = <TabServiceOrder serviceOrder={selectedServiceOrder} />;
      break;
    case 1:
      selectedTab = <TabProducts id={selectedServiceOrder.id} />;
      break;
    case 2:
      selectedTab = <TabServices selectedServiceOrder={selectedServiceOrder} />;
      break;
    case 3:
      selectedTab = <TabThirdParties id={selectedServiceOrder.id} />;
      break;
      case 4:
      selectedTab = <TabPayments id={selectedServiceOrder.id} />;
      break;
    case 5:
      selectedTab = <TabGeneral selectedServiceOrder={selectedServiceOrder} />;
      break;
  }

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <AntTabs
          value={selectedTabIndex}
          onChange={(e, newValue) => setSelectedTabIndex(newValue)}
          aria-label="oficina tabs"
          selectionFollowsFocus
        >
          <AntTab label="OS" />
          <AntTab label="PRODUTOS" />
          <AntTab label="SERVIÇOS" />
          <AntTab label="TERCEIROS" />
          <AntTab label="PAGAMENTOS" />
          <AntTab label="GERAL" />
        </AntTabs>
      </Grid>
      <Grid item xs={12}>
        {selectedTab}
      </Grid>
    </Grid>
  );
};

export default DashboardGarageTabs;
