import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingLabel from "../../../../components/UI/LoadingLabel/LoadingLabel";
import { companyOrderActions } from "../../../../redux/companyOrder/companyOrderSlice";
import NewManufacturerItem from "./NewManufacturerItem";
import NewProductForm from "./NewProductForm";
import NewProductSearch from "./NewProductSearch";
import _ from "lodash";

/**
 * STEPS:
    1. Confirm GTIN;
    2. Search/find or Add new Product;
    3. Add new manufacturer Item;
    [-]. Call searchHandler with the new added GTIN;
 */
const NewProductModal = ({ onItemCreated }) => {
  const [step, setStep] = useState([1]);
  const [productSelected, setProductSelected] = useState(null);
  const dispatch = useDispatch();
  const { barcode } = useSelector((state) => state.companyOrder);

  const onClose = useCallback(() => {
    dispatch(companyOrderActions.setNewProductModalShow(null));
  }, []);

  const onProductSelectedHandler = useCallback((product) => {
    setStep((curr) => {
      curr.push(!product.id ? 3 : 4);
      return [...curr];
    });
    setProductSelected(product);
  });

  return (
    <Dialog fullWidth={true} open onClose={onClose} aria-labelledby="adicionar produto ao catálogo">
      <DialogTitle id="max-width-dialog-title">Novo Item de Estoque</DialogTitle>
      <DialogContent>
        {_.last(step) === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Esse código de barras está correto?<h1>{barcode}</h1>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" color="primary" fullWidth onClick={() => setStep([2])}>
                Sim
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button variant="contained" fullWidth onClick={onClose}>
                Não
              </Button>
            </Grid>
          </Grid>
        )}
        {_.last(step) === 2 && <NewProductSearch onProductSelected={onProductSelectedHandler} />}
        {_.last(step) === 3 && <NewProductForm productSelected={productSelected} onProductSelected={onProductSelectedHandler} />}
        {_.last(step) === 4 && <NewManufacturerItem productSelected={productSelected} barcode={barcode} onItemCreated={onItemCreated} />}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={_.last(step) === 2}
          onClick={() => {
            setStep((curr) => {
              curr.pop();
              return [...curr];
            });
          }}
          color="primary"
        >
          Voltar
        </Button>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        {/* <Button color="primary">
          <LoadingLabel text="Salvar" />
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default NewProductModal;
