import { Card } from "@material-ui/core";
import classes from "../../../containers/__Containers.css";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

const PromotionsHome = (props) => {

  //TODO Work with targeting templates and custom. E.g.: "1000 Customers last active"; "100 More active (greater buyers);"

  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Promoções" icon={<NewReleasesIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <span style={{ fontSize: '24px' }}>Em desenvolvimento 👷🏼🚧</span>
      </Card>
    </div>
  );
};

export default PromotionsHome;