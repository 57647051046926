import { Alert } from "@material-ui/lab";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import MAutocomplete from "./MAutocomplete";

const MAutocompleteProductCategory = (props) => {
  const { formik, field, disabled, onChange } = props;
  const [categories, setCategories] = useState([]);
  const { request, data, errors, loading } = useApiClient();

  useEffect(() => {
    request(apiRoutes.main.product.productGroups);
  }, []);

  useEffect(() => {
    if (data) {
      setCategories(data.value);
      onChange(null, data.value.find(x => x.category === formik.values.category));
    }
  }, [data]);

  if (errors) return <Alert severity="error">Ocorreu um erro carregando os grupos</Alert>;

  return (
    <MAutocomplete
      formik={formik}
      field={field}
      options={categories}
      disabled={disabled || loading}
      onChange={onChange}
      optionKeyField="category"
      optionLabelField="category"
      label="Categoria"
      placeholder="Selecione uma categoria"
    />
  );
};

export default MAutocompleteProductCategory;
