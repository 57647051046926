import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import GridItemText from "../../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../../components/UI/LoadingLabel/LoadingLabel";
import MSelectFieldGarageStaff from "../../../../components/UI/MSelectField/MSelectFieldGarageStaff";
import MTextField from "../../../../components/UI/MTextField/MTextField";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import { serviceOrderChanged, serviceOrderItemChanged } from "../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const EditServiceOrdermodal = (props) => {
  const { serviceOrder } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra, requestId } = useApiClient();

  const formik = useFormik({
    initialValues: {
      id: serviceOrder.id,
      employeeId: serviceOrder.employeeResponsible?.employeeId ?? "",
      km: serviceOrder.km,
      receptionInfo: serviceOrder.receptionInfo,
      description: "",
    },
    onSubmit: (values) => {
      request(apiRoutes.main.serviceOrder.update, values, { requestExtra: values, requestId: "os" });
    },
  });

  useEffect(() => {
    if (data && requestId === "item") {
      toast.success("Alteração salva");
      setSelectedItem(null);
      dispatch(serviceOrderItemChanged(requestExtra));
    }

    if (data && requestId === "os") {
      toast.success("Alteração salva");
      dispatch(serviceOrderChanged(data.value));
    }

    if (errors) {
      toast.error(errors.status);
    }
  }, [data, errors, requestExtra, requestId]);

  const onClose = useCallback(() => {
    if (!loading) {
      dispatch(dashboardGarageActions.setServiceOrderEditModalShow(false));
    }
  }, [loading, dispatch]);

  const saveItemHandler = useCallback(
    (item) => {
      request(apiRoutes.main.serviceOrder.updateItem, item, { requestExtra: item, requestId: "item" });
    },
    [request]
  );

  if (!serviceOrder) return null;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="fechar ordem de serviço">
      <DialogTitle id="max-width-dialog-title">Editar Ordem de Serviço</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(errors || {}).status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{(errors || {}).status}</Alert>
            </Grid>
          )}
          <GridItemText md={6} label="Cliente" text={serviceOrder.customer?.name} />
          <GridItemText
            md={6}
            label="Veículo"
            text={`${serviceOrder.vehicle?.brand} ${serviceOrder.vehicle?.model} - ${serviceOrder.vehicle?.modelYear} | ${serviceOrder.vehicle?.plates}`}
          />
          <Grid item xs={12} md={6}>
            <MTextField
              field="km"
              label={"KM Atual do veículo"}
              formik={formik}
              inputProps={{
                maxLength: 8,
                type: "number",
                step: 1,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MSelectFieldGarageStaff formik={formik} label="Responsável" field="employeeId" />
          </Grid>
          <Grid item xs={12} md={12}>
            <MTextField
              field="receptionInfo"
              label={"Observações da Recepção"}
              formik={formik}
              rows={3}
              multiline
              inputProps={{
                maxLength: 1024,
              }}
            />
          </Grid>

          {!selectedItem && (
            <Grid item xs={12}>
              <b>Itens da OS</b>
              {loading ? (
                <CircularProgress />
              ) : (
                <List component="nav" aria-label="itens da os">
                  {serviceOrder.items?.map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        button
                        onClick={() => {
                          formik.setFieldValue("description", item.description);
                          setSelectedItem({
                            id: serviceOrder.id,
                            itemId: item.id,
                            isDone: !!item.done,
                          });
                        }}
                        disabled={loading}
                      >
                        <ListItemIcon>
                          {loading ? (
                            <CircularProgress size={20} />
                          ) : item.done ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText primary={<div>{item.description}</div>} />
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Grid>
          )}
          {selectedItem && (
            <Grid item xs={12}>
              <MTextField
                field="description"
                label={"Descrição de item"}
                formik={formik}
                multiline
                rows={3}
                inputProps={{ maxLength: 250 }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    saveItemHandler({ ...selectedItem, description: formik.values.description });
                  }
                }}
              />
              <Button
                disabled={loading || !formik.values.description}
                onClick={() => saveItemHandler({ ...selectedItem, description: formik.values.description })}
              >
                Salvar
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button onClick={() => formik.submitForm()} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditServiceOrdermodal;
