import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";

import {
  TextField,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Collapse,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/images/logo.png";
import classes from "../Login/Login.css";
import appConfig from "../../config/app.config";
import { useNavigate } from "react-router";

const validationSchema = yup.object({
  email: yup
    .string("Informe o e-mail de seu usuário")
    .email("Informe um e-mail válido")
    .required("O e-mail é obrigatório"),
});

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setStatus(null);

      axios
        .post(
          "https://movi-serverless-main.azurewebsites.net/api/SignInForgotPassword?code=pI/V9G4P3PPFl9TKWL2xaUtulnuLj6PZeB0LkS107ptv8ezcRub11A==",
          values
        )
        .then((response) => {
          actions.setSubmitting(false);

          if (response.data.success) {
            actions.setStatus({
              severity: "success",
              message: "Um link para a redefinição da senha foi enviado. ",
            });
          } else {
            actions.setStatus({
              severity: "error",
              message:
                "Desculpe, ocorreu um erro. Tente novamente mais tarde. ",
            });
          }
        })
        .catch((e) => {
          actions.setSubmitting(false);
          actions.setStatus(
            "Oops... Ocorreu um erro durante sua requisição. Tente novamente mais tarde. "
          );
          console.error(e);
        });
    },
  });

  return (
    <div className={classes.Login}>
      <Paper elevation={5} style={{ padding: "30px" }}>
        <div>
          <img
            src={LogoImg}
            alt="Logo Movi"
            style={{ cursor: "pointer", width: "20%", marginBottom: "20px" }}
            onClick={() => navigate("/")}
          />
          <br />
          <div style={{ fontWeight: "lighter", marginBottom: "40px" }}>
            <span>Recupere a senha de sua conta</span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Collapse in={formik.status !== undefined}>
              <Grid item xs={12}>
                <Alert
                  severity={formik.status ? formik.status.severity : "warning"}
                >
                  {formik.status ? formik.status.message : ""}
                </Alert>
              </Grid>
            </Collapse>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                label="E-mail do seu usuário"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Enviar e-mail de redefinição
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Link to="/login">Voltar</Link>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.Info}>
                Novo por aqui?
                <br />
                <Link>Crie uma conta grátis.</Link>
              </div>
              <span style={{ color: "gray", fontSize: "10px" }}>
                Todos os Direitos Reservados © {new Date().getFullYear()} | Versão{" "}
                {appConfig.clientVersion}
              </span>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default ForgotPassword;
