import React from "react";
import classes from "./SideDrawer.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import MenuItems from "../MenuItems/MenuItems";

const SideDrawer = (props) => {
  let attachedClasses = [classes.SideDrawer, classes.Close];
  if (props.open) {
    attachedClasses = [classes.SideDrawer, classes.Open];
  }
  return (
    <>
      <Backdrop show={props.open} clicked={props.closed} />
      <div
        style={{ marginTop: "30px", color: "black" }}
        onClick={() => {
          props.closed();
        }}
        className={attachedClasses.join(" ")}
      >
        <MenuItems />
      </div>
    </>
  );
};

export default SideDrawer;
