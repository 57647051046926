import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";
import { useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import GridItemText from "../../../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../../../components/UI/LoadingLabel/LoadingLabel";
import useApiClient from "../../../../../hooks/apiClient";
import apiRoutes from "../../../../../hooks/apiClient.routes";
import { serviceOrderPaymentRemoved } from "../../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../../redux/dashboardGarage/dashboardGarageSlice";

const PaymentRemoveModal = (props) => {
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const { selectedServiceOrder } = props;
  const { paymentPicked } = useSelector((state) => state.dashboardGarage);

  useEffect(() => {
    if (data) {
      toast.success("Pagamento removido");
      dispatch(serviceOrderPaymentRemoved({ ...requestExtra }));
    }
  }, [data, errors, requestExtra]);

  const onClose = useCallback(() => {
    if (!loading) {
      dispatch(dashboardGarageActions.setPaymentRemoveModalShow(false));
    }
  }, [loading, dispatch]);

  if (!paymentPicked) return null;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="remover pagamento">
      <DialogTitle id="max-width-dialog-title">Remover Pagamento?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(errors || {}).status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{(errors || {}).status}</Alert>
            </Grid>
          )}
          <GridItemText md={12} label="Descrição" text={paymentPicked.description} />
          <GridItemText
            md={12}
            label="Detalhes"
            text={`Recebido por ${paymentPicked.userName} em ${format(
              new Date(paymentPicked.processed),
              "dd/MM/yyyy HH:mm"
            )}`}
          />
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <b>VALOR R${paymentPicked.amount.toFixed(2)}</b>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            const payload = {
              serviceOrderId: selectedServiceOrder.id,
              paymentId: paymentPicked.paymentId,
            };
            request(apiRoutes.main.serviceOrder.paymentRemove, payload, { requestExtra: payload });
          }}
          color="secondary"
        >
          <LoadingLabel loading={loading} text="Remover" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentRemoveModal;
