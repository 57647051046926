import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect, useCallback } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import GridItemText from "../../../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../../../components/UI/LoadingLabel/LoadingLabel";
import useApiClient from "../../../../../hooks/apiClient";
import apiRoutes from "../../../../../hooks/apiClient.routes";
import { garageOrderProductRemoved } from "../../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../../redux/dashboardGarage/dashboardGarageSlice";

const ProductRemoveModal = () => {
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const { serviceOrders, selectedIndex, productPicked } = useSelector((state) => state.dashboardGarage);

  useEffect(() => {
    if (data) {
      toast.success("Produto removido");
      dispatch(garageOrderProductRemoved(requestExtra));
    }
  }, [data, errors, productPicked, requestExtra]);

  const onClose = useCallback(() => {
    if (!loading) {
      dispatch(dashboardGarageActions.setProductRemoveModalShow(null));
    }
  }, [loading, dispatch]);

  if (!productPicked) return null;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="remover produto">
      <DialogTitle id="max-width-dialog-title">Remover Produto?</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {(errors || {}).status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{(errors || {}).status}</Alert>
            </Grid>
          )}
          <GridItemText md={12} label="Descrição" text={productPicked.description} />
          <GridItemText md={6} label="Quantidade" text={`${productPicked.quantity} ${productPicked.productUnit}`} />
          <GridItemText md={6} label="Valor Unit.(R$)" text={productPicked.price} />
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <b>TOTAL R${(productPicked.price * productPicked.quantity || 0).toFixed(2)}</b>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            const payload = {
              serviceOrderId: serviceOrders.active[selectedIndex].id,
              garageOrderId: serviceOrders.active[selectedIndex].garageOrderId,
              itemId: productPicked.id,
            };
            request(apiRoutes.main.garageOrder.removeProduct, payload, { requestExtra: payload });
          }}
          color="secondary"
        >
          <LoadingLabel loading={loading} text="Remover" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductRemoveModal;
