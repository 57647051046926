import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import LoadingLabel from "../../../components/UI/LoadingLabel/LoadingLabel";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { garageOrderProductAdded } from "../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../redux/dashboardGarage/dashboardGarageSlice";
import ProductSearch from "../ProductSearch/ProductSearch";

const initialValues = {
  serviceOrderId: "",
  stockId: "",
  quantity: "1",
};

const validationSchema = Yup.object({
  serviceOrderId: Yup.string().required("-"),
  stockId: Yup.string().required("-"),
  quantity: Yup.number().min(0.01, "Quantidade inválida").required("Informe a quantidade desse produto"),
});

const ProductPickerModal = (props) => {
  const { selectedServiceOrder } = props;
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const { productPicked } = useSelector((state) => state.dashboardGarage);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.garageOrder.addProduct, values, { requestExtra: values.serviceOrderId });
    },
  });

  const { setValues, setStatus, setFieldValue, setSubmitting } = formik;

  useEffect(() => {
    setValues((curr) => {
      return {
        ...curr,
        serviceOrderId: selectedServiceOrder.id,
      };
    });
  }, [setValues, selectedServiceOrder.id]);

  useEffect(() => {
    if (data) {
      toast.success("Produto adicionado");
      console.log(data);
      dispatch(garageOrderProductAdded({ ...data.value, serviceOrderId: requestExtra }));
    }

    setStatus((errors || {}).status);
    setSubmitting(false);
  }, [data, errors, requestExtra, setSubmitting, setStatus]);

  const readOnly = formik.isSubmitting;

  const onClose = useCallback(() => {
    if (!formik.isSubmitting) {
      setStatus(null);
      dispatch(dashboardGarageActions.setProductPickerShow(false));
    }
  }, [formik.isSubmitting, setStatus]);

  const total = formik.values.price * formik.values.quantity;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="adicionar serviço">
      <DialogTitle id="max-width-dialog-title">Adicionar Produto</DialogTitle>
      <DialogContent>
        {!productPicked ? (
          <ProductSearch
            asComponent
            onRowClicked={(stock) => {
              console.log(stock);
              // var stockItem = stock.items[0];
              setFieldValue("stockId", stock.id);
              setFieldValue("price", stock.price);
              dispatch(dashboardGarageActions.setProductPicked(stock));
            }}
          />
        ) : (
          <Grid container spacing={2}>
            {formik.status && !loading && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <GridItemText md={6} label="Categoria" text={productPicked.category} />
            <GridItemText md={6} label="Subcategoria" text={productPicked.subcategory} />
            <GridItemText md={12} label="Descrição" text={productPicked.shortDescription} />
            <Grid item xs={6}>
              <MTextField
                field={"quantity"}
                label={"Quantidade"}
                formik={formik}
                disabled={readOnly}
                type="number"
                money
                min="1"
                step="0.01"
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <GridItemText md={12} label="Preço(R$)" text={productPicked.price.toFixed(2)} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <b>TOTAL R${(total || 0).toFixed(2)}</b>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={formik.isSubmitting}>
          Fechar
        </Button>
        <Button disabled={!productPicked || loading} onClick={formik.handleSubmit} color="primary">
          <LoadingLabel loading={formik.isSubmitting} text="Salvar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductPickerModal;
