import BusinessIcon from "@material-ui/icons/Business";
import { useFormik } from "formik";
import React, { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import DetailView from "../../../components/UI/DetailView/DetailView";
import AddressCard from "../../../components/UI/Person/AddressCard/AddressCard";
import IdentificationCard from "../../../components/UI/Person/IdentificationCard/IdentificationCard";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import store from "store";
import { useNavigate } from "react-router";

const initialValues = {
  id: "",
  type: "2",
  name: "",
  publicName: "",
  taxId: "",
  gender: "",
  birth: null,
  phone1: "",
  phone2: "",
  phone3: "",
  email: "",
  addressLine1: "",
  number: "",
  addressLine2: "",
  neighborhood: "",
  city: "",
  state: "",
  zip: "",
  info: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Informe o nome do fornecedor"),
});

const SupplierDetail = (props) => {
  const navigate = useNavigate();
  const { request, data, errors, loading, requestId } = useApiClient();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.person.supplierSave, values);
    },
  });

  const { values, setSubmitting, setStatus, setErrors } = formik;

  useEffect(() => {
    if (data || errors) setSubmitting(false);

    if (data && data.success) {
      toast.success("Salvo com sucesso");
      store.set("supplier-last-saved", { ...values, id: data.value.id });
      navigate(-1);
    }

    if (errors) {
      if (errors.status) setStatus(errors.status);
      if (errors.schema) setErrors({ ...errors.schema });
    }
  }, [
    data,
    errors,
    requestId,
    navigate,
    setSubmitting,
    setStatus,
    setErrors,
  ]);

  const getResponseHandler = useCallback(
    (data) => ({ ...data.value.person, ...data.value.supplierProfile }),
    []
  );

  return (
    <DetailView
      title="Forncedores / Cadastro"
      icon={<BusinessIcon />}
      defaultAction={null}
      formik={formik}
      getRoute={apiRoutes.main.person.supplierById}
      getResponseHandler={getResponseHandler}
      loading={loading}
      {...props}
    >
      <IdentificationCard formik={formik} />
      <AddressCard formik={formik} />
    </DetailView>
  );
};

export default SupplierDetail;
