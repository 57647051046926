import { Button, Card, CardContent, Grid, LinearProgress } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import classes from "../../../containers/__Containers.css";

const cols = [
  {
    name: "Título",
    selector: "data.title",
    format: (row) => <b>{row.data.title}</b>,
  },
  {
    name: "Mensagem",
    selector: "data.message",
  },
];

const NotificationsSearch = (props) => {
  const { unreadNotifications } = useSelector((state) => state.notifications);

  const searchLoading = false;

  return (
    <div className={classes.Canvas}>
      <CanvasTitle label={"Notificações"} icon={<NotificationsIcon />} />
      <Card>
        {searchLoading && <LinearProgress />}
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            {unreadNotifications && (
              <Grid item xs={12}>
                <DataTable
                  columns={cols}
                  data={unreadNotifications}
                  noDataComponent={"Nenhum item para exibir"}
                  noHeader
                  pointerOnHover
                  striped
                  responsive
                />
              </Grid>
            )}
            {true && ( //continuationToken
              <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={3}>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      fullWidth
                      disabled={searchLoading}
                      onClick={() => {
                        // searchHandler(continuationToken);
                      }}
                    >
                      Buscar Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default NotificationsSearch;
