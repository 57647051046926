import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newServiceOrderPayload: {
    customerId: null,
    vehicleId: null,
  },
};

const serviceOrderSlice = createSlice({
  name: "serviceOrder",
  initialState,
  reducers: {
    setNewServiceOrderPayload(state, action) {
      state.newServiceOrderPayload = action.payload;
    },
  },
});

export const serviceOrderActions = serviceOrderSlice.actions;

export default serviceOrderSlice;
