import {
  Button,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import BlockIcon from "@material-ui/icons/Block";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PeopleIcon from "@material-ui/icons/People";
import TwoWheelerIcon from "@material-ui/icons/TwoWheeler";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import store from "store";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import HotButton from "../../../components/UI/HotButton/HotButton";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { serviceOrderActions } from "../../../redux/serviceOrder/serviceOrderSlice";
import classes from "../../__Containers.css";

const cols = [
  {
    name: "Nome",
    selector: "name",
  },
  {
    name: "CPF/CNPJ",
    selector: "taxId",
  },
  {
    name: "E-mail",
    selector: "email",
  },
  {
    name: "Telefone 1",
    selector: "phone1",
  },
];

const VehicleChips = (props) => {
  const { data, handleVehicleClick, newServiceOrderContext } = props;

  if (!data || !data.customerProfile.vehicles || !data.customerProfile.vehicles.length) {
    return null;
  }

  const associatedVehicles = data.customerProfile.vehicles.filter((v) => !v.removed);

  if (!associatedVehicles.length)
    return (
      <Chip
        icon={<BlockIcon />}
        label="Nenhum veículo associado"
        clickable={false}
        color="default"
        style={{ margin: "7px" }}
      />
    );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
      }}
    >
      {associatedVehicles
        .filter((v) => !v.removed)
        .map((vehicle, index) => {
          let chipIcon = null;

          switch (vehicle.type) {
            case 1:
              chipIcon = <DirectionsCarIcon />;
              break;
            case 2:
              chipIcon = <TwoWheelerIcon />;
              break;
            case 3:
              chipIcon = <LocalShippingIcon />;
              break;
            default:
              chipIcon = <DirectionsCarIcon />;
          }

          return (
            <Tooltip key={index} title={`${vehicle.brand} ${vehicle.model} ${vehicle.modelYear}`}>
              <Chip
                icon={chipIcon}
                label={vehicle.plates}
                clickable={!!newServiceOrderContext}
                color="default"
                style={{ margin: "7px" }}
                onClick={(e) => {
                  if (newServiceOrderContext) {
                    handleVehicleClick(data, vehicle);
                  }
                }}
              />
            </Tooltip>
          );
        })}
    </div>
  );
};

const CustomerSearch = ({ handleClose, newServiceOrderContext }) => {
  const cachedState = store.get("customer-search");
  const [searchArgumentInput, setSearchArgumentInput] = useState(cachedState ? cachedState.searchArgumentInput : "");
  const [continuationToken, setContinuationToken] = useState(cachedState ? cachedState.continuationToken : null);
  const [rows, setRows] = useState(cachedState ? cachedState.rows : null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { request: searchRequest, data: searchData, loading: searchLoading } = useApiClient();

  const searchHandler = useCallback(
    (continuationToken) => {
      searchRequest(apiRoutes.main.person.customerSearch, {
        arg: searchArgumentInput,
        continuationToken,
      });
    },
    [searchRequest, searchArgumentInput]
  );

  useEffect(() => {
    if (searchData && searchData.success) {
      setRows((curr) => (!curr ? searchData.value.items : curr.concat(searchData.value.items)));
      setContinuationToken(searchData.value.continuationToken);
    }
  }, [searchData]);

  const vehicleClickHandler = useCallback(
    (customer, vehicle) => {
      if (customer?.id && vehicle?.id) {
        dispatch(
          serviceOrderActions.setNewServiceOrderPayload({
            customerId: customer.id,
            vehicleId: vehicle.id,
          })
        );
      }
      handleClose();
    },
    [dispatch, navigate]
  );

  return (
    <div className={classes.Canvas}>
      <CanvasTitle
        label="Clientes"
        icon={<PeopleIcon />}
        defaultAction={
          <HotButton
            hotkeys="ctrl+n"
            onClick={() => {
              navigate("/app/clientes/form", { state: { newServiceOrderContext } });
            }}
          >
            Novo Cliente
          </HotButton>
        }
      />
      <Card>
        {searchLoading && <LinearProgress />}
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="search-argument">Pesquisar...</InputLabel>
                <Input
                  id="search-argument"
                  value={searchArgumentInput}
                  onChange={(e) => setSearchArgumentInput(e.target.value)}
                  inputProps={{ placeholder: "CPF, CNPJ, E-mail, Nome ou Placa" }}
                  autoFocus
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="limpar tela de pesquisa"
                        onClick={() => {
                          setRows(null);
                          setSearchArgumentInput("");
                          setContinuationToken(null);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="default"
                disabled={searchLoading}
                onClick={() => {
                  setRows(null);
                  searchHandler(null);
                }}
              >
                Pesquisar
              </Button>
            </Grid>
            {rows && (
              <Grid item xs={12}>
                <DataTable
                  columns={cols}
                  data={rows}
                  noDataComponent="Nenhum cliente para exibir"
                  noHeader
                  pointerOnHover
                  striped
                  responsive
                  loading={searchLoading}
                  onRowClicked={(item) => {
                    store.set("customer-search", {
                      searchArgumentInput,
                      continuationToken,
                      rows,
                    });
                    navigate("/app/clientes/form", { state: { id: item.id, newServiceOrderContext } });
                  }}
                  expandableRows
                  // expandableRowExpanded={(row) => !!row.customerProfile.vehicles.length}
                  expandableRowsComponent={
                    <VehicleChips
                      newServiceOrderContext={newServiceOrderContext}
                      handleVehicleClick={vehicleClickHandler}
                    />
                  }
                />
              </Grid>
            )}
            {continuationToken && (
              <Grid item xs={12}>
                <Grid container spacing={3} alignItems="center" justifyContent="center">
                  <Grid item xs={12} md={3}>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      fullWidth
                      disabled={searchLoading}
                      onClick={() => {
                        searchHandler(continuationToken);
                      }}
                    >
                      Buscar Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default CustomerSearch;
