import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";

const MAutocomplete = (props) => {
  const { formik, field, options, optionLabelField, optionKeyField, label, placeholder, disabled, onChange } = props;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const selected = options.find((item) => item[optionKeyField] === formik.values[field]);
    setSelected(selected || null);
  }, [field, formik.values, options, optionKeyField]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option[optionLabelField] || "Carregando..."}
      value={selected}
      noOptionsText="Nenhum item encontrado"
      onChange={(e, item) => {
        setSelected(item);
        formik.setFieldValue(field, (item || {})[optionKeyField] || "");
        if (typeof onChange === "function") onChange(e, item);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
          disabled={formik.isSubmitting || disabled}
          error={formik.touched[field] && Boolean(formik.errors[field])}
          helperText={formik.touched[field] ? formik.errors[field] : ""}
        />
      )}
    />
  );
};

export default MAutocomplete;
