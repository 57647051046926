import React from "react";
import { TextField } from "@material-ui/core";

const MTextField = (props) => {

  const error = 
    props.formik.touched &&
    props.formik.errors &&
    props.formik.touched[props.field] && 
    props.formik.errors[props.field] ? props.formik.errors[props.field] : "";

  return (
    <TextField
      id={props.field}
      name={props.field}
      autoFocus={props.autoFocus}
      {...props}
      fullWidth
      value={props.formik.values[props.field]}
      onChange={props.formik.handleChange}
      disabled={props.formik.isSubmitting || props.disabled}
      error={!!error}
      helperText={error}
    />
  );
};

export default MTextField;
