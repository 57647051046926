import prodConfig from "./config.prod";

/**
 * Settings for development environment
 * yarn start
 */
const devConfig = {
  env: "dev",
  signalRHost: "https://localhost:7075",
  // signalRHost: "https://movi-serverless-main.azurewebsites.net",
  hosts: {
    // Local
    main: "https://localhost:7075",

    // Production
    // main: "https://movi-serverless-main.azurewebsites.net",
  },
  codes: {
    ...prodConfig.codes,
  },
};

export default devConfig;
