import { Dialog } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomerSearch from "../../Customer/CustomerSearch/CustomerSearch";
import { useDispatch } from "react-redux";
import { serviceOrderActions } from "../../../redux/serviceOrder/serviceOrderSlice";

const ServiceOrderSelectCustomerModal = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(serviceOrderActions.setNewServiceOrderPayload(null));
    };
  }, []);

  if (!props.show) return null;

  return (
    <Dialog open maxWidth="lg" fullWidth onClose={props.handleClose} aria-labelledby="form-dialog-title" scroll="body">
      <CustomerSearch newServiceOrderContext handleClose={() => props.handleClose()} />
    </Dialog>
  );
};

export default ServiceOrderSelectCustomerModal;
