import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadNotifications: [],
};

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNewNotification(state, action) {
      state.unreadNotifications = [...state.unreadNotifications, action.payload];
    },
  },
});

export const notificationsActions = notificationsSlice.actions;

export default notificationsSlice;
