import DataTable from "react-data-table-component";
import toast from "react-hot-toast";
import { fDate } from "../../../utils/format";
import InfoIcon from "@material-ui/icons/Info";

const cols = [
  {
    name: "Entrada",
    selector: "entry",
    format: (row) =>
      row.info ? (
        <>
          <InfoIcon color="primary" fontSize="small" /> {fDate(row.entry)}
        </>
      ) : (
        fDate(row.entry)
      ),
  },
  {
    name: "Lote",
    selector: "lot",
    format: (row) => `${row.lot ?? "-"}`,
    right: true,
  },
  {
    name: "Validade",
    selector: "expiresAt",
    format: (row) => (row.expiresAt ? fDate(row.expiresAt, "dd/MM/yyyy") : "-"),
    right: true,
  },
  {
    name: "Quantidade",
    selector: "quantity",
    right: true,
  },
  {
    name: "Custo (R$)",
    selector: "cost",
    right: true,
  },
];

const ProductTable = ({ items, loading }) => {
  return (
    <DataTable
      columns={cols}
      data={items}
      noDataComponent={<b>Nenhum item para exibir</b>}
      noHeader
      pointerOnHover
      striped
      responsive
      loading={loading}
      expandableRows={false}
      onRowClicked={(row) => {
        if (row.info) toast.success(row.info, { icon: <InfoIcon color="primary" /> });
      }}
    />
  );
};

export default ProductTable;
