import { Button, Card, CardContent, Grid, MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import LoadingLabel from "../../../../components/UI/LoadingLabel/LoadingLabel";
import MAutocomplete from "../../../../components/UI/MAutocomplete/MAutocomplete";
import MAutocompleteProductCategory from "../../../../components/UI/MAutocomplete/MAutocompleteProductCategory";
import MSelectField from "../../../../components/UI/MSelectField/MSelectField";
import MTextField from "../../../../components/UI/MTextField/MTextField";
import productUnits from "../../../../data/productUnits";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import classes from "../../../__Containers.css";

const initialValues = {
  id: "",
  category: "",
  subcategory: "",
  productUnit: "UN",
  application: "",
  shortDescription: "",
  info: "",
};

const validationSchema = Yup.object({
  category: Yup.string().required("Selecione a categoria do produto"),
  subcategory: Yup.string().required("Selecione a subcategoria do produto"),
  shortDescription: Yup.string().required("Informe uma descrição breve do produto"),
});

const NewProductForm = ({ productSelected, onProductSelected }) => {
  const { request, data, errors, loading } = useApiClient();
  const [subcategories, setSubcategories] = useState([]);
  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.product.save, values);
    },
  });

  const { setValues, setFieldValue, setStatus, setErrors, setSubmitting } = formik;

  useEffect(() => {
    if (data?.success) {
      onProductSelected(data.value);
      setFieldValue("id", data.value.id);
    }

    if (errors?.status) {
      setStatus(errors.status || "Ocorreu um erro durante essa operação. ");
    }

    if(errors?.schema) {
      setErrors({ ...errors.schema });
    }

    setSubmitting(false);
  }, [data, errors, setFieldValue, setStatus, setErrors, setSubmitting, onProductSelected]);

  useEffect(() => {
    const { id = "", productUnit = "UN", application = "", shortDescription = "", info = "" } = productSelected;

    setValues((curr) => {
      return {
        id,
        productUnit,
        application,
        shortDescription,
        info,
        category: productSelected.group.category,
        subcategory: productSelected.group.subcategory,
      };
    });
  }, []);

  return (
    <Grid container spacing={2}>
      {formik.status && !loading && (
        <Grid item xs={12}>
          <Alert severity="error">{formik.status}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.SectionTitle}>Produto</span>
              </Grid>
              <Grid item xs={6}>
                <MAutocompleteProductCategory
                  formik={formik}
                  field="category"
                  onChange={(e, value) => setSubcategories(value?.subcategories)}
                />
              </Grid>
              <Grid item xs={6}>
                <MAutocomplete
                  formik={formik}
                  field="subcategory"
                  options={subcategories}
                  optionKeyField="subcategory"
                  optionLabelField="subcategory"
                  label="Subcategoria"
                  placeholder="Selecione uma subcategoria"
                />
              </Grid>
              <Grid item xs={4}>
                <MSelectField formik={formik} field="productUnit" label="Unidade de Medida">
                  {productUnits.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </MSelectField>
              </Grid>
              <Grid item xs={8}>
                <MTextField formik={formik} field="shortDescription" label="Descrição Breve" autoFocus />
              </Grid>
              <Grid item xs={12}>
                <MTextField formik={formik} field="application" label="Aplicação" multiline rows={2} />
              </Grid>
              <Grid item xs={12}>
                <MTextField formik={formik} field="info" label="Informação Adicional" multiline rows={3} />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  size="small"
                  onClick={formik.submitForm}
                >
                  <LoadingLabel loading={formik.isSubmitting} text="Salvar" />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewProductForm;
