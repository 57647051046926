/**
 *
 * Validações comuns para os forms da aplicação
 *
 * https://github.com/jquense/yup
 *
 */

export const validCpf = ({ required = true }) => {
  return {
    exclusive: false,
    message: "O CPF informado é inválido",
    params: {},
    test: (value) => {
      if (!required && !value) return true;

      return /[0-9]{11}/.exec(value || '0');
    },
  };
};

export const validCnpj = ({ required = true }) => {
  return {
    exclusive: false,
    message: "O CNPJ informado é inválido",
    params: {},
    test: (value) => {
      if (!required && !value) return true;

      return /[0-9]{14}/.exec(value || '0');
    },
  };
};

export const validTelefone = {
  exclusive: false,
  message: "O telefone informado é inválido (inclua o DDD)",
  test: (value) => {
    if (value == null) return false;

    return /[0-9]{11}/.exec(value);
  },
};
