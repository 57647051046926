import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import LoadingLabel from "../../../../../components/UI/LoadingLabel/LoadingLabel";
import MSelectField from "../../../../../components/UI/MSelectField/MSelectField";
import MTextField from "../../../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../../../hooks/apiClient";
import apiRoutes from "../../../../../hooks/apiClient.routes";
import { serviceOrderPaymentAdded } from "../../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../../redux/dashboardGarage/dashboardGarageSlice";

const initialValues = {
  serviceOrderId: "",
  paymentType: "Money",
  cardFlag: "",
  cardTransactionCode: "",
  installments: "1",
  amount: "",
};

const validationSchema = Yup.object({
  serviceOrderId: Yup.string().required("OS não selecionada"),
  paymentType: Yup.string().required("Selecione o tipo de pagamento"),
  cardFlag: Yup.string(),
  cardTransactionCode: Yup.string(),
  installments: Yup.number().min(1, "Quantidade inválida").required("Informe a quantidade parcelas"),
  amount: Yup.number().min(0.01, "Informe um valor maior ou igual a R$0,01").required("Informe o valor do pagamento"),
});

const PaymentAddModal = (props) => {
  const { selectedServiceOrder } = props;
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let payload = {
        ...values,
      };

      if (values.paymentType === "Money") {
        delete payload.cardFlag;
        delete payload.cardTransactionCode;
      }

      request(apiRoutes.main.serviceOrder.paymentAdd, payload, {
        requestExtra: { ...payload },
      });
    },
  });

  const { setValues, setStatus, setFieldValue, setSubmitting } = formik;

  const totalDue = selectedServiceOrder.garageOrder?.totalDue ?? 0;
  
  useEffect(() => {
    setValues((curr) => {
      return {
        ...curr,
        serviceOrderId: selectedServiceOrder.id,
        amount: totalDue - selectedServiceOrder.paymentsTotal,
      };
    });
  }, [setValues, selectedServiceOrder.id, totalDue]);

  useEffect(() => {
    if (["Money", "DebitCard"].indexOf(formik.values.paymentType) > -1) {
      setFieldValue("installments", "1");
      setFieldValue("cardFlag", "");
      setFieldValue("cardTransactionCode", "");
    }
  }, [formik.values.paymentType, setFieldValue]);

  useEffect(() => {
    if (data) {
      toast.success("Pagamento adicionado");
      dispatch(serviceOrderPaymentAdded(data.value));
    }

    setStatus((errors || {}).status);
    setSubmitting(false);
  }, [data, errors, requestExtra, setSubmitting, setStatus]);

  const readOnly = formik.isSubmitting;

  const onClose = useCallback(() => {
    if (!formik.isSubmitting) {
      setStatus(null);
      dispatch(dashboardGarageActions.setPaymentAddModalShow(false));
    }
  }, [formik.isSubmitting, setStatus]);

  const readOnlyInstallments = ["Money", "DebitCard"].indexOf(formik.values.paymentType) > -1;
  const isCard = ["DebitCard", "CreditCard"].indexOf(formik.values.paymentType) > -1;

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose} aria-labelledby="adicionar pagamento">
      <DialogTitle id="max-width-dialog-title">Adicionar Pagamento</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {formik.status && !loading && (
            <Grid item xs={12}>
              <Alert severity="error">{formik.status}</Alert>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <MSelectField field="paymentType" label="Forma de Pagamento" formik={formik}>
              <MenuItem value="Money">Dinheiro</MenuItem>
              <MenuItem value="DebitCard">Cartão de Débito</MenuItem>
              <MenuItem value="CreditCard">Cartão de Crédito</MenuItem>
            </MSelectField>
          </Grid>
          {isCard && (
            <>
              <Grid item xs={6}>
                <MSelectField field="cardFlag" label="Bandeira" formik={formik}>
                  <MenuItem value="Mastercard">Mastercard</MenuItem>
                  <MenuItem value="Visa">Visa</MenuItem>
                  <MenuItem value="Hipercard">Hipercard</MenuItem>
                </MSelectField>
              </Grid>
              <Grid item xs={6}>
                <MTextField
                  field="cardTransactionCode"
                  label="Código da Operação"
                  formik={formik}
                  disabled={readOnly}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <MTextField
              field="installments"
              label="Qtd. de Parcelas"
              formik={formik}
              disabled={readOnly || readOnlyInstallments}
              type="number"
              min="1"
              step="1"
            />
          </Grid>
          <Grid item xs={6}>
            <MTextField
              field="amount"
              label="Valor do Pagamento(R$)"
              formik={formik}
              disabled={readOnly}
              type="number"
              money
              min="0.01"
              step="0.01"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={formik.isSubmitting}>
          Fechar
        </Button>
        <Button disabled={loading} onClick={formik.handleSubmit} color="primary">
          <LoadingLabel loading={formik.isSubmitting} text="Salvar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentAddModal;
