import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import LoadingLabel from "../../../../components/UI/LoadingLabel/LoadingLabel";
import MTextField from "../../../../components/UI/MTextField/MTextField";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import classes from "../../../__Containers.css";
import { useBarcode } from "react-barcodes";
import { companyOrderActions } from "../../../../redux/companyOrder/companyOrderSlice";
import { useDispatch } from "react-redux";

const initialValues = {
  productId: "",
  manufacturerCode: "",
  manufacturer: "",
  code: "",
  barcode: "",
  application: "",
};

const validationSchema = Yup.object({
  manufacturerCode: Yup.string().required("Informe o código do fabricante para o produto"),
  manufacturer: Yup.string().required("Informe o fabricante do produto"),
  barcode: Yup.string().required("Informe uma descrição breve do produto"),
});

const NewManufacturerItem = ({ barcode, productSelected, onItemCreated }) => {
  const { request, data, errors, loading } = useApiClient();
  const dispatch = useDispatch();
  const { inputRef } = useBarcode({
    value: barcode,
    options: {
      height: 50,
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.product.manufacturerItemSave, values);
    },
  });

  const { setFieldValue, setStatus, setSubmitting } = formik;

  useEffect(() => {
    if (data?.success) {
      onItemCreated(barcode);
      dispatch(companyOrderActions.setNewProductModalShow(null));
    }
    if (errors) {
      setStatus(errors.status || "Ocorreu um erro durante essa operação. ");
    }
    setSubmitting(false);
  }, [data, errors, barcode, setFieldValue, setStatus, setSubmitting]);

  useEffect(() => {
    setFieldValue("productId", productSelected.id);
    setFieldValue("barcode", barcode);
  }, []);

  return (
    <Grid container spacing={2}>
      {formik.status && !loading && (
        <Grid item xs={12}>
          <Alert severity="error">{formik.status}</Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <span className={classes.SectionTitle}>Item de Fabricante</span>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <svg ref={inputRef} />
              </Grid>
              <Grid item xs={12} md={6}>
                <MTextField formik={formik} field="manufacturerCode" label="Código do Fabricante" />
              </Grid>
              <Grid item xs={12} md={6}>
                <MTextField formik={formik} field="manufacturer" label="Fabricante" />
              </Grid>
              <Grid item xs={12}>
                <MTextField formik={formik} field="application" label="Aplicação" multiline rows={2} />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={formik.isSubmitting}
                  size="small"
                  onClick={formik.submitForm}
                >
                  <LoadingLabel loading={formik.isSubmitting} text="Finalizar" />
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default NewManufacturerItem;
