import { Button } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router';

const NotFound = (props) => {
    const navigate = useNavigate();
    return (
        <div style={{ textAlign: 'center', padding: '40px' }}>
            <h1>404</h1>
            A página que você está procurando não existe.
            <br/> 
            <br/> 
            <br/> 
            <Button variant="contained" color="primary" onClick={() => { navigate(-1) }}>Voltar</Button>
            <br/>
            <br/>
            <Button variant="text" color="primary" onClick={() => navigate('/app/home', { replace: true }) }>Voltar para o Início</Button>
        </div>
    );
};

export default NotFound;