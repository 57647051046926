import React, { useRef, useState } from "react";
import SearchView from "../../components/UI/SearchView/SearchView";
import apiRoutes from "../../hooks/apiClient.routes";
import HistoryIcon from "@material-ui/icons/History";
import { fDate } from "../../utils/format";
import { IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ServiceOrderPrintMenu from "./ServiceOrderPrintMenu";

const cols = [
  {
    name: "Cliente",
    selector: "customer.name",
    width: "25%",
  },
  {
    name: "Veículo",
    selector: (row) => `${row.vehicle?.brand} ${row.vehicle?.model} ${row.vehicle?.modelYear} - ${row.vehicle?.plates}`,
    width: "40%",
  },
  {
    name: "Abertura",
    selector: (row) => fDate(row.creation),
  },
  {
    name: " ",
    right: true,
    selector: (row) => "empty",
  },
];

const ServiceOrderHistorySearch = ({}) => {
  const [selectedServiceOrder, setSelectedServiceOrder] = useState(null);

  cols[3].selector = (row) => (
    <IconButton
      onClick={(e) => {
        console.log(e.currentTarget, row);
        setSelectedServiceOrder({ serviceOrder: row, el: e.currentTarget });
      }}
    >
      <PrintIcon />
    </IconButton>
  );

  return (
    <>
      <ServiceOrderPrintMenu
        serviceOrder={selectedServiceOrder?.serviceOrder}
        el={selectedServiceOrder?.el}
        handlePrintMenuClose={() => setSelectedServiceOrder(null)}
      />
      <SearchView
        title="Histórico / Ordens de Serviço"
        icon={<HistoryIcon />}
        placeholder="Placa do veículo"
        cacheKey="service-order-history-search"
        cols={cols}
        searchRoute={apiRoutes.main.serviceOrder.history}
      />
    </>
  );
};

export default ServiceOrderHistorySearch;
