import { Button, Tooltip } from "@material-ui/core";
import PropTypes from 'prop-types';
import React from 'react';
import { hotkeys } from 'react-keyboard-shortcuts';

class HotButton extends React.PureComponent {

    static propTypes = {
        hotkeys: PropTypes.oneOf(['ctrl+n', 'ctrl-o']).isRequired,
        onClick: PropTypes.func.isRequired,
    }

    hot_keys = {
        [this.props.hotkeys]: {
            priority: 1,
            handler: (event) => this.props.onClick(),
        },
    }

    render() {
        return (
            <Tooltip title={<span>⚡️ {this.props.hotkeys}</span>}>
                <Button
                    variant="contained"
                    color="primary"
                    {... this.props}
                >
                    {this.props.children}
                </Button>
            </Tooltip>
        )
    }
}

export default hotkeys(HotButton)