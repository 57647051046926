import { HubConnectionState, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { dashboardGarageActions } from "../redux/dashboardGarage/dashboardGarageSlice";
import { notificationsActions } from "../redux/notifications/notifications-slice";
import store from "store";
import appConfig from "../config/app.config";
import { serviceOrderChangedHandler } from "./serviceOrderHandlers";

const isDev = process.env.NODE_ENV === "development";

// https://stackoverflow.com/questions/46190574/how-to-import-signalr-in-react-component

const startSignalRConnection = async (connection) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    console.log("SignalR connection established");
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.error("SignalR Connection Error: ", err);
    setTimeout(() => startSignalRConnection(connection), 20000);
  }
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRConnection =
  (connectionHub, actionEventMap = {}) =>
  (dispatch) => {
    const options = {
      logMessageContent: isDev,
      logger: isDev ? LogLevel.Warning : LogLevel.Error,
      // accessTokenFactory: () => getAccessToken(getState()),
    };
    // create the connection instance
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed
    const connection = new HubConnectionBuilder()
      .withUrl(connectionHub, options)
      // .withAutomaticReconnect()
      // .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    connection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    connection.onclose((error) => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log("SignalR: Conexão encerrada. ", error);
    });

    connection.onreconnecting((error) => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log("SignalR: Conexão perdida. Reconectando...", error);
    });

    connection.onreconnected((connectionId) => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log("SignalR: Conexão reestabelecida. ID: ", connectionId);
    });

    startSignalRConnection(connection);

    const user = store.get("user");

    connection.on("updateResource", (response) => {
      console.log("SignalR: updateResource ", response);
      if (response.userId !== user.id && response.userUnity === user.unity) {
        switch (response.data.name) {
          case "serviceOrder":
            serviceOrderChangedHandler({ dispatch, params: response.data.params });
            break;
          case "dashboardGarage.serviceOrders.active.garageOrder.services:add":
            dispatch(dashboardGarageActions.signalRServiceAdded(response.data.params));
            break;
          case "dashboardGarage.serviceOrders.active.garageOrder.services:remove":
            dispatch(dashboardGarageActions.signalRServiceRemoved(response.data.params));
            break;
          default:
            break;
        }
      }
    });

    connection.on("newNotification", (message) => {
      console.log("SignalR: newNotification ", message);
      dispatch(notificationsActions.addNewNotification(message));
    });

    return connection;
  };

export const broadcastUpdateResource = async ({ name, action, params }) => {
  await fetch(`${appConfig.signalRHost}/api/SignalRUpdateResource`, {
    method: "POST",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
    credentials: "include",
    body: JSON.stringify({
      name,
      action,
      params,
    }),
  });
};
