import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { fDate } from "../../../utils/format";
import MTextField from "../../../components/UI/MTextField/MTextField";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import { KeyboardDatePicker } from "@material-ui/pickers";

const columns = [
  {
    id: "installment",
    label: "Descrição",
    minWidth: 170,
    // align: "right",
    format: (row) => `Parcela ${row.installment}`,
  },
  {
    id: "due",
    label: "Vencimento",
    format: (value) => {
      return fDate(value.due, "dd/MM/yyyy");
    },
  },
  {
    id: "amount",
    label: "Valor(R$)",
    align: "right",
    format: (value) => value.amount.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
});

const CompanyOrderPaymentInstallments = (props) => {
  const { formik, debt } = props;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedIndex } = formik.values;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const installmentForm = (
    <Grid container spacing={2}>
      <GridItemText md={12} label={`Parcela ${selectedIndex + 1}`} empty />
      {formik.values.paymentType === "fcb" && (
        <>
          <Grid item xs={12} md={6}>
            <MTextField
              formik={formik}
              field="docNumber"
              label="No. do Documento"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MTextField
              formik={formik}
              field="fcbBarcode"
              label="Código de Barras"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <MTextField
              formik={formik}
              field="fcbLinhaDigitavel"
              label="Linha Digitável"
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <KeyboardDatePicker
          id="due"
          name="due"
          label="Vencimento"
          format="dd/MM/yyyy"
          fullWidth
          value={formik.values.due}
          disabled={formik.isSubmitting}
          invalidDateMessage="Data inválida"
          onChange={(date) => {
            formik.setFieldValue("due", date);
          }}
          KeyboardButtonProps={{
            "aria-label": "alterar vencimento",
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MTextField formik={formik} field="amount" label="Valor(R$)" />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          variant="text"
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => {
            debt.installments[selectedIndex].due = formik.values.due;
            debt.installments[selectedIndex].amount = +formik.values.amount;
                        
            formik.setFieldValue("form", "installment");
            formik.setFieldValue(
              "debtInstallmentId",
              debt.installments[selectedIndex].id
            );
            formik.submitForm();
          }}
        >
          Salvar
        </Button>
        <Button
          variant="text"
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => {
            formik.setFieldValue("selectedIndex", -1);
            formik.resetForm();
          }}
        >
          Cancelar
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {debt.installments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {index === selectedIndex ? (
                      <TableCell colSpan={3}>{installmentForm}</TableCell>
                    ) : (
                      columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={() => {
                              formik.setValues({
                                ...formik.values,
                                fcbLinhaDigitavel: row.fcbLinhaDigitavel,
                                amount: row.amount.toFixed(2),
                                due: row.due,
                              });
                              formik.setFieldValue("selectedIndex", index);
                            }}
                          >
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })
                    )}
                    {}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        labelRowsPerPage="Linhas por página"
        component="div"
        count={debt.installments.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CompanyOrderPaymentInstallments;
