import { MenuItem } from "@material-ui/core";
import MSelectField from "./MSelectField";


const MSelectFieldPaymentType = (props) => {
  return (
    <MSelectField field={props.field} label={props.label || "Forma de Pagamento"} formik={props.formik}>
      <MenuItem key="fcb" value="fcb">
        Boleto Bancário
      </MenuItem>
      <MenuItem key="money" value="money">
        Espécie
      </MenuItem>
      <MenuItem key="pix" value="pix">
        Pix
      </MenuItem>
      <MenuItem key="cheque" value="cheque">
        Cheque
      </MenuItem>
      <MenuItem key="credit-card" value="credit-card">
        Cartão de Crédito
      </MenuItem>
      <MenuItem key="debit-card" value="debit-card">
        Cartão de Débito
      </MenuItem>
    </MSelectField>
  );
};

export default MSelectFieldPaymentType;
