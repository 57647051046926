import React from "react";
import classes from "../../Layout/Layout.css";
import BugReportIcon from "@material-ui/icons/BugReport";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import HistoryIcon from "@material-ui/icons/History";
import BuildIcon from "@material-ui/icons/Build";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import StorefrontIcon from "@material-ui/icons/Storefront";
import BusinessIcon from "@material-ui/icons/Business";
import BallotIcon from "@material-ui/icons/Ballot";
import AssignmentIcon from "@material-ui/icons/Assignment";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const MenuItems = () => {
  // Healing, import AssignmentIcon from '@material-ui/icons/Assignment';
  const { user } = useSelector((state) => state.auth);
  const isActive = (navData) => (navData.isActive ? `${classes.SidebarItem} ${classes.active}` : classes.SidebarItem);

  return (
    <>
      <NavLink to="/app/home" className={isActive}>
        <AssignmentIcon />
        <p>Oficina</p>
      </NavLink>
      {/* <NavLink to="/app/loja" className={isActive}>
        <StorefrontIcon />
        <p>Loja</p>
      </NavLink> */}
      <NavLink to="/app/clientes" className={isActive}>
        <PeopleIcon />
        <p>Clientes</p>
      </NavLink>
      <NavLink to="/app/produtos" className={isActive}>
        <BallotIcon />
        <p>Produtos</p>
      </NavLink>
      <NavLink to="/app/servicos" className={isActive}>
        <BuildIcon />
        <p>Serviços</p>
      </NavLink>
      <NavLink to="/app/fornecedores" className={isActive}>
        <BusinessIcon />
        <p>Fornecedores</p>
      </NavLink>
      <NavLink to="/app/historico" className={isActive}>
        <HistoryIcon />
        <p>Histórico</p>
      </NavLink>
      <NavLink to="/app/promocoes" className={isActive}>
        <NewReleasesIcon />
        <p>Promoções</p>
      </NavLink>
      <NavLink to="/app/financeiro" className={isActive}>
        <MonetizationOnIcon />
        <p>Financeiro</p>
      </NavLink>
      <NavLink to="/app/equipe" className={isActive}>
        <AssignmentIndIcon />
        <p>Equipe</p>
      </NavLink>
      <NavLink to="/app/seguranca" className={isActive}>
        <VerifiedUserIcon />
        <p>Segurança</p>
      </NavLink>
      {(user || {}).debugSession && (
        <NavLink to="/app/debug" className={isActive}>
          <BugReportIcon style={{ color: "red" }} />
          <p>Debug Session</p>
        </NavLink>
      )}
    </>
  );
};

export default MenuItems;
