import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import classes from "../../../../containers/__Containers.css";
import MSelectField from "../../MSelectField/MSelectField";
import MTextField from "../../MTextField/MTextField";

const IdentificationCard = (props) => {
  const { formik, allowPj = true } = props;

  const pf = +formik.values.type === 1;

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span className={classes.SectionTitle}>Identificação</span>
            </Grid>
            {!formik.values.id && allowPj && (
              <Grid item xs={12}>
                <FormControl
                  disabled={formik.isSubmitting}
                  component="fieldset"
                >
                  <RadioGroup
                    aria-label="tipo"
                    id="type"
                    name="type"
                    row
                    value={formik.values.type}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      color="primary"
                      control={<Radio />}
                      label="Pessoa Física"
                    />
                    <FormControlLabel
                      value="2"
                      color="primary"
                      control={<Radio />}
                      label="Pessoa Jurídica"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <MTextField
                field="name"
                autoFocus={true}
                label={pf ? "Nome" : "Razão Social"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MTextField
                field={"publicName"}
                label={pf ? "Apelido" : "Nome Fantasia"}
                formik={formik}
              />
            </Grid>
            {pf && (
              <Grid item xs={12} md={6}>
                <MTextField
                  field={"taxId"}
                  label={"CPF ⭐"}
                  formik={formik}
                  inputProps={{ maxLength: 11 }}
                />
              </Grid>
            )}
            {!pf && (
              <Grid item xs={12} md={6}>
                <MTextField
                  field={"taxId"}
                  label={"CNPJ ⭐"}
                  formik={formik}
                  inputProps={{ maxLength: 14 }}
                />
              </Grid>
            )}
            {pf && (
              <Grid item xs={12} md={6}>
                <MSelectField field="gender" label="Sexo" formik={formik}>
                  <MenuItem key={"m"} value={"m"}>
                    Masculino
                  </MenuItem>
                  <MenuItem key={"f"} value={"f"}>
                    Feminino
                  </MenuItem>
                  <MenuItem key={"-"} value={"-"}>
                    Não especificado
                  </MenuItem>
                </MSelectField>
              </Grid>
            )}
            {pf && (
              <Grid item xs={12} md={6}>
                <KeyboardDatePicker
                  id="birth"
                  name="birth"
                  label="Nascimento"
                  format="dd/MM/yyyy"
                  fullWidth
                  value={formik.values.birth}
                  disableFuture
                  disabled={formik.isSubmitting}
                  invalidDateMessage="Data inválida"
                  onChange={(date) => {
                    formik.setFieldValue("birth", date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "alterar nascimento",
                  }}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <MTextField
                field={"phone1"}
                label={"Telefone 1 ⭐"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <MTextField
                field={"phone2"}
                label={"Telefone 2"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={6}>
              <MTextField
                field={"phone3"}
                label={"Telefone 3"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <MTextField field={"email"} label={"Email ⭐"} formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <MTextField
                field={"info"}
                label={"Informação Adicional"}
                formik={formik}
                multiline
                rows={4}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
            <Grid item xs={12}>
              <span style={{ color: "gray", fontSize: "small" }}>
                ⭐Esses campos ajudam a impedir duplicatas, ao menos um deles é
                obrigatório
              </span>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default IdentificationCard;
