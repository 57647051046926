import React from "react";
import { FormControl, FormHelperText, InputLabel, Select } from "@material-ui/core";

const MSelectField = (props) => {
  return (
    <FormControl
      fullWidth
      error={
        (props.formik.touched[props.field] || props.touched) &&
        Boolean(props.formik.errors[props.field])
      }
    >
      <InputLabel id={`${props.field}-label`}>{props.label}</InputLabel>
      <Select
        labelId={`${props.field}-label`}
        id={props.field}
        name={props.field}
        autoWidth
        value={props.formik.values[props.field]}
        onChange={props.formik.handleChange}
        disabled={props.formik.isSubmitting || props.disabled}
        {...props}
      >
        {props.children}
      </Select>
      <FormHelperText>{props.formik.errors[props.field]}</FormHelperText>
    </FormControl>
  );
};

export default MSelectField;
