import BusinessIcon from "@material-ui/icons/Business";
import React from "react";
import { useNavigate } from "react-router";
import HotButton from "../../../components/UI/HotButton/HotButton";
import SearchView from "../../../components/UI/SearchView/SearchView";
import apiRoutes from "../../../hooks/apiClient.routes";

const cols = [
  {
    name: "Nome",
    selector: "name",
  },
  {
    name: "CNPJ/CPF",
    selector: "taxId",
  },
  {
    name: "E-mail",
    selector: "email",
  },
  {
    name: "Telefone 1",
    selector: "phone1",
  },
];

const SupplierSearch = (props) => {
  const { title, handleSupplierSelected } = props;
  const navigate = useNavigate();

  return (
    <SearchView
      title={title || "Fornecedores"}
      icon={<BusinessIcon />}
      placeholder="Razão Social, CNPJ ou email"
      cacheKey="supplier-search"
      cols={cols}
      searchRoute={apiRoutes.main.person.supplierSearch}
      defaultActionButton={
        <HotButton
          hotkeys="ctrl+n"
          onClick={() => {
            navigate("/app/fornecedores/form");
          }}
        >
          Novo Fornecedor
        </HotButton>
      }
      onRowClicked={(row) => {
        if (typeof handleSupplierSelected === "function") handleSupplierSelected(row);
        else {
          navigate("/app/fornecedores/form", { state: { id: row.id } });
        }
      }}
    />
  );
};

export default SupplierSearch;
