import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import useApiClient from "../../../hooks/apiClient";
import classes from "../../../containers/__Containers.css";
import store from "store";

const SearchView = (props) => {
  const {
    title,
    icon,
    panel,
    cacheKey,
    searchRoute,
    cols,
    defaultActionButton,
    placeholder,
    noDataComponent,
    onRowClicked,
    expandableRows,
    expandableRowsComponent,
  } = props;

  const cachedState = cacheKey ? store.get(cacheKey) : null;
  const [searchArgumentInput, setSearchArgumentInput] = useState(
    cachedState ? cachedState.searchArgumentInput : ""
  );
  const [continuationToken, setContinuationToken] = useState(
    cachedState ? cachedState.continuationToken : null
  );
  const [rows, setRows] = useState(cachedState ? cachedState.rows : null);

  const {
    request: searchRequest,
    data: searchData,
    loading: searchLoading,
  } = useApiClient();

  const searchHandler = useCallback((continuationToken) => {
    searchRequest(searchRoute, {
      arg: searchArgumentInput,
      continuationToken
    });
  }, [searchRequest, searchRoute, searchArgumentInput]);

  useEffect(() => {
    if (searchData && searchData.success) {
      setRows((curr) =>
        !curr ? searchData.value.items : curr.concat(searchData.value.items)
      );
      setContinuationToken(searchData.value.continuationToken);
    }
  }, [searchData]);

  useEffect(() => {
    return () => {
      store.set(cacheKey, {
        searchArgumentInput,
        rows,
      });
    };
  }, [searchArgumentInput, cacheKey, rows]);

  return (
    <div className={classes.Canvas}>
      <CanvasTitle
        label={title}
        icon={icon}
        defaultAction={defaultActionButton}
      />
      {panel}
      <Card>
        {searchLoading && <LinearProgress />}
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel htmlFor="search-argument">Pesquisar...</InputLabel>
                <Input
                  id="search-argument"
                  value={searchArgumentInput}
                  onChange={(e) => setSearchArgumentInput(e.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      setRows(null);
                      searchHandler(null);
                    }
                  }}
                  autoFocus
                  inputProps={{
                    placeholder: placeholder,
                  }}
                  disabled={searchLoading}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="limpar tela de pesquisa"
                        onClick={() => {
                          setRows(null);
                          setSearchArgumentInput("");
                          setContinuationToken(null);
                          store.remove(cacheKey);
                        }}
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="default"
                disabled={searchLoading}
                onClick={() => {
                  setRows(null);
                  searchHandler(null);
                }}
              >
                Pesquisar
              </Button>
            </Grid>
            {rows && (
              <Grid item xs={12}>
                <DataTable
                  columns={cols}
                  data={rows}
                  noDataComponent={noDataComponent || <b>Nenhum item para exibir</b>}
                  noHeader
                  pointerOnHover
                  striped
                  responsive
                  loading={searchLoading}
                  onRowClicked={(row) => {
                    store.set(cacheKey, {
                      searchArgumentInput,
                      continuationToken,
                      rows,
                    });

                    if (typeof onRowClicked === "function") onRowClicked(row);
                  }}
                  expandableRows={expandableRows || false}
                  expandableRowsComponent={expandableRowsComponent}
                />
              </Grid>
            )}
            {continuationToken && (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} md={3}>
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      fullWidth
                      disabled={searchLoading}
                      onClick={() => {
                        searchHandler(continuationToken);
                      }}
                    >
                      Buscar Mais
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SearchView;
