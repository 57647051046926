import { Card } from "@material-ui/core";
import classes from "../../../containers/__Containers.css";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

const SecurityHome = (props) => {
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Segurança" icon={<VerifiedUserIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <span style={{ fontSize: '24px' }}>Em desenvolvimento 👷🏼🚧</span>
      </Card>
    </div>
  );
};

export default SecurityHome;