const colors = [
  { value: "Azul", text: "Azul" },
  { value: "Amarelo", text: "Amarelo" },
  { value: "Cinza", text: "Cinza" },
  { value: "Preto", text: "Preto" },
  { value: "Preto Metálico", text: "Metálico" },
  { value: "Prata", text: "Prata" },
  { value: "Vermelho", text: "Vermelho" },
  { value: "Vinho", text: "Vinho" },
];

export default colors;
