import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import appConfig from "../../../config/app.config";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import { setupSignalRConnection } from "../../../signalR/SignalR";
import Logo from "../../Logo/Logo";
import NavigationItems from "../NavigationItems/NavigationItems";
import DrawerToggle from "../SideDrawer/DrawerToggle/DrawerToggle";
import classes from "./Toolbar.css";

const Toolbar = (props) => {
  const dispatch = useDispatch();
  const { request, data } = useApiClient();

  useEffect(() => {
    request(apiRoutes.main.app.init);
  }, []);

  useEffect(() => {
    if (data) console.log((data || {}).value);
  }, [data]);

  useEffect(() => {
    console.log("SignalR setup");
    const hub = setupSignalRConnection(`${appConfig.signalRHost}/api`, {})(dispatch);
    return () => {
      hub.connection.stop();
    };
  }, [setupSignalRConnection, dispatch]);

  return (
    <>
      <header className={classes.Toolbar}>
        <DrawerToggle clicked={props.menuClick} />
        <div className={classes.Logo}>
          {/* <Menu style={{ marginRight: '18px' }} onClick={props.menuClick}/> */}
          <Logo />
        </div>
        <nav className={classes.DesktopOnly}>
          <NavigationItems avatar />
        </nav>
      </header>
    </>
  );
};

export default Toolbar;
