import {
  Button, CircularProgress,
  Collapse, Grid,
  Paper, TextField
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as yup from "yup";
import LogoImg from "../../assets/images/logo.png";
import classes from "../Login/Login.css";

const validationSchema = yup.object({
  senha: yup
    .string("Informe a senha de seu usuário")
    .min(6, "A senha precisa ter no mínimo 6 caracteres")
    .required("A senha é obrigatória"),
  confirmacao: yup
    .string("Confirme a senha de sua conta")
    .oneOf([yup.ref("senha"), null], "A confirmação está diferente da senha")
    .required("A confirmação de sua senha é obrigatória"),
  safePayload: yup.string().required("O link está em formato inválido. "),
});

const Login = (props) => {
  const navigate = useNavigate();
  let payload = "";
  let query = new URLSearchParams(props.location.search);
  for (const item of query.entries()) {
    payload = item[1];
    break;
  }

  const formik = useFormik({
    initialValues: {
      senha: "",
      confirmacao: "",
      safePayload: payload,
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      axios
        .post(
          "https://movi-serverless-main.azurewebsites.net/api/SignInResetPassword?code=A4KhmXvEzvg7rJfXKzZWigO5qnTWZj02coaKHiGzB5k/nVGlma9pPQ==",
          {
              password: values.senha,
              safePayload: values.safePayload
          }
        )
        .then((response) => {
          actions.setSubmitting(false);

          if (response.data.success) {
            alert("Senha salva com sucesso. ");
            navigate("/app");
          } else {
            formik.setStatus(
              "Ocorreu um erro durante essa operação. Por favor, tente novamente mais tarde."
            );
          }
        })
        .catch((e) => {
          actions.setSubmitting(false);
          console.error(e);
          formik.setStatus(
            "Oops... Ocorreu um erro durante sua requisição. Tente novamente mais tarde. "
          );
        });
    },
  });

  return (
    <div className={classes.Login}>
      <Paper elevation={5} style={{ padding: "30px" }}>
        <div>
          <img
            src={LogoImg}
            alt="logo movi"
            style={{ cursor: "pointer", width: "20%", marginBottom: "20px" }}
            onClick={() => navigate("/")}
          />
          <br />
          <div style={{ fontWeight: "lighter", marginBottom: "40px" }}>
            <span>Defina a senha de seu usuário</span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Collapse in={formik.status !== undefined}>
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            </Collapse>
            <Grid item xs={12}>
              <TextField
                id="senha"
                name="senha"
                label="Senha"
                type="password"
                fullWidth
                value={formik.values.senha}
                onChange={formik.handleChange}
                error={formik.touched.senha && Boolean(formik.errors.senha)}
                helperText={formik.touched.senha && formik.errors.senha}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="confirmacao"
                name="confirmacao"
                label="Confirme a senha"
                fullWidth
                type="password"
                value={formik.values.confirmacao}
                onChange={formik.handleChange}
                error={
                  formik.touched.confirmacao &&
                  Boolean(formik.errors.confirmacao)
                }
                helperText={
                  formik.touched.confirmacao && formik.errors.confirmacao
                }
              />
            </Grid>
            <Grid item xs={12}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  fullWidth
                >
                  Definir sua senha
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Link to="/app">Cancelar</Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
