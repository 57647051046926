import { createSlice } from "@reduxjs/toolkit";
import store from "store";

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  user: store.get('user')
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    signIn(state, action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = action.payload;
    },
    signOut(state) {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
    unauthorized(state) {
      state.isAuthenticated = false;
      state.isLoading = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
