const ServiceOrderViewer = (props) => {
  return (
    <div>
      <h1>Welcome to your OS!!!</h1>
      <div>Timeline</div>
      <embed src="https://localhost:7075/api/ServiceOrderPdf?code=FWrbHCdfVjKjQca9WT3gQnjpgsvS6NK4mCSlUu2eyzOImiwOOq2Irg%3D%3D&id=a9cf1aac-7518-4cfa-908e-666b1965e9a1"/>
    </div>
  );
};

export default ServiceOrderViewer;
