import { Avatar, Badge, Menu, MenuItem } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SettingsIcon from "@material-ui/icons/Settings";
import { useState } from "react";
import store from "store";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import NavigationItem from "./NavigationItem/NavigationItem";
import classes from "./NavigationItems.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const NavigationItems = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { request } = useApiClient(false);
  const { unreadNotifications } = useSelector((state) => state.notifications);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    setAnchorEl(null);
    store.remove("user");
    navigate("/login", { replace: true });
    request(apiRoutes.main.auth.signOutBasic);
  };

  return (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/app/notificacoes">
        <Badge badgeContent={unreadNotifications.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </NavigationItem>
      <NavigationItem link="/app/configuracoes">
        <SettingsIcon />
      </NavigationItem>
      {props.avatar ? (
        <Avatar
          style={{
            margin: "10px",
            width: "36px",
            height: "36px",
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          {user.name[0].toUpperCase()}
        </Avatar>
      ) : null}
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem disabled>{user.name}</MenuItem>
        <MenuItem onClick={handleClose}>Minha conta</MenuItem>
        <MenuItem onClick={signOut}>Sair</MenuItem>
      </Menu>
    </ul>
  );
};

export default NavigationItems;
