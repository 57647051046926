import { Card } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import CanvasTitle from "../../components/UI/CanvasTitle/CanvasTitle";
import classes from "../__Containers.css";

const Debug = () => {
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Debug Session" icon={<BugReportIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <p>
          Essa é uma sessão especial para seu usuário porque alguma anomalia foi detectada. Não se preocupe, na maioria
          dos casos você trabalhará sem nenhum inconveniente enquanto ajustamos algumas coisas.
        </p>
        <p>
          Você está vendo essa tela porque é parte da nossa política a total transparência. Na prática, a única coisa
          que muda é que aumentamos o volume de informação de diagnóstico registrada a cada ação realizada.
        </p>
        <p style={{ color: "gray" }}>Suporte Movi Motors</p>
      </Card>
    </div>
  );
};

export default Debug;
