import { Button, Card, CardContent, CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import BallotIcon from "@material-ui/icons/Ballot";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import * as Yup from "yup";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import SupplierSelectModal from "../../Supplier/SupplierSelectModal/SupplierSelectModal";
import classes from "../../__Containers.css";
import CompanyOrderItemsCard from "./CompanyOrderItemsCard";
import CompanyOrderPaymentsCard from "./CompanyOrderPaymentsCard";

const initialValues = {
  id: "",
  supplierId: "",
  orderNumber: "",
  nfe: "",
  shipping: "",
  taxes: "",
  supplier: {
    id: "",
    name: "",
    taxId: "",
    phone1: "",
    phone2: "",
    phone3: "",
    email: "",
  },
  products: [],
};

const validationSchema = Yup.object({
  orderNumber: Yup.string().required("Informe o número identificador desse pedido"),
  shipping: Yup.number().required("Informe o valor do frete"),
  taxes: Yup.number().required("Informe o valor dos impostos no pedido"),
});

const CompanyOrderRegistration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderNumberInput = useRef();
  const { request, data, errors, loading, requestId } = useApiClient();
  const { request: cancelRequest, data: cancelData, errors: cancelErrors, loading: cancelLoading } = useApiClient();
  const { request: finishRequest, data: finishData, errors: finishErrors, loading: finishLoading } = useApiClient();

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: (values, actions) => {
      if (!values.supplierId) {
        actions.setStatus("Selecione o fornecedor do pedido. ");
        actions.setSubmitting(false);
        return;
      }
      request(apiRoutes.main.companyOrder.save, values, { requestId: "save" });
    },
  });

  const { values, setSubmitting, setFieldValue, setValues, setStatus, setErrors } = formik;

  const [showSupplierModal, setShowSupplierModal] = useState(!values.supplier.id && !location.state);

  useEffect(() => {
    if (location.state && location.state.pendingCompanyOrder)
      request(
        apiRoutes.main.companyOrder.byId,
        { id: location.state.pendingCompanyOrder.companyOrderId },
        { requestId: "pending" }
      );
  }, [request, location]);

  useEffect(() => {
    if (requestId !== "save") return;

    if (data || errors) setSubmitting(false);

    if (data && data.success) {
      setFieldValue("id", data.value.id);
    }

    if (errors) {
      if (errors.status) setStatus(errors.status);
      if (errors.schema) setErrors(errors.schema);
    } else {
      setStatus("");
      setErrors({});
    }
  }, [data, errors, requestId, setSubmitting, setFieldValue, setErrors, setStatus]);

  useEffect(() => {
    if (requestId !== "pending") return;

    if (data && data.success) {
      setValues({ ...data.value });
    }

    setStatus({ ...errors }.status || "");
  }, [data, errors, requestId, setStatus, setValues]);

  useEffect(() => {
    if (cancelData && cancelData.success) {
      toast.success("Registro de pedido cancelado ");
      navigate(-1);
    }

    setStatus((cancelErrors || {}).status || "");
  }, [cancelData, cancelErrors, navigate, setStatus]);

  useEffect(() => {
    if (finishData && finishData.success) {
      toast.success("Registro de pedido finalizado ");
      navigate(-1);
    }

    if (finishErrors?.status) toast.error(finishErrors.status);

    setStatus((finishErrors || {}).status || "");
  }, [finishData, finishErrors, navigate, setStatus]);

  const { products } = formik.values;
  const newItemHandler = useCallback(
    (item) => {
      setFieldValue("products", [...formik.values.products, item]);
    },
    [products, setFieldValue]
  );

  const removedItemHandler = useCallback(
    (itemId) => {
      if (products.find((value) => value.id === itemId)) {
        formik.setFieldValue(
          "products",
          products.filter((item) => item.id !== itemId)
        );
      }
    },
    [products]
  );

  return (
    <>
      <SupplierSelectModal
        show={showSupplierModal}
        handleClose={() => setShowSupplierModal(false)}
        handleSelected={(supplier) => {
          const newValues = {
            ...formik.values,
            supplierId: supplier.id,
            supplier: {
              id: supplier.id,
              name: supplier.name,
              taxId: supplier.taxId,
              email: supplier.email,
              phone1: supplier.phone1,
              phone2: supplier.phone2,
              phone3: supplier.phone3,
            },
          };
          formik.setValues(newValues);
        }}
      />
      <div className={classes.Canvas}>
        <CanvasTitle label="Produtos / Entrada de Pedido" icon={<BallotIcon />} defaultAction={null} />
        <Card className={classes.FormCard}>
          {(formik.isSubmitting || loading || cancelLoading || finishLoading) && <LinearProgress />}
          <Grid container spacing={3}>
            {formik.status && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <span className={classes.SectionTitle}>Fornecedor</span>
                    </Grid>
                    {formik.values.supplier.id && (
                      <>
                        <GridItemText label="Nome" text={formik.values.supplier.name} />
                        <GridItemText label="CNPJ / CPF" text={formik.values.supplier.taxId} />
                        <GridItemText label="Telefone" text={formik.values.supplier.phone1} />
                        <GridItemText label="E-mail" text={formik.values.supplier.email} />
                      </>
                    )}
                    {!formik.values.supplier.id && (
                      <Grid item xs={12}>
                        <GridItemText label=" " text="Nenhum fornecedor selecionado" />
                      </Grid>
                    )}
                    {!formik.values.id && (
                      <Grid item xs={12}>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => {
                            formik.setStatus("");
                            setShowSupplierModal(true);
                          }}
                        >
                          Selecionar
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className={classes.SectionTitle}>Dados do Pedido</span>
                      </Grid>
                      {!formik.values.id && (
                        <>
                          {" "}
                          <Grid item xs={12} md={6}>
                            <MTextField
                              field="orderNumber"
                              label="No. Pedido"
                              inputRef={orderNumberInput}
                              autoFocus
                              formik={formik}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MTextField field="nfe" label="Chave NF-e" inputProps={{ maxLength: 44 }} formik={formik} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MTextField field="shipping" label="Frete (R$)" type="number" step="0.01" formik={formik} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <MTextField field="taxes" label="Impostos (R$)" type="number" step="0.01" formik={formik} />
                          </Grid>
                          <Grid item xs={12}>
                            <Button variant="text" color="primary" type="submit">
                              Salvar
                            </Button>
                          </Grid>
                        </>
                      )}
                      {formik.values.id && (
                        <>
                          <Grid item xs={12} md={6}>
                            <GridItemText label="No. Pedido" text={formik.values.orderNumber} empty />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <GridItemText label="Chave NF-e" text={formik.values.nfe} empty />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <GridItemText label="Frete(R$)" text={formik.values.shipping.toFixed(2)} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <GridItemText label="Impostor(R$)" text={formik.values.taxes} empty />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
            {formik.values.id ? (
              <CompanyOrderItemsCard
                companyOrderId={formik.values.id}
                newItemHandler={newItemHandler}
                removedItemHandler={removedItemHandler}
                items={formik.values.products}
              />
            ) : (
              <Grid item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <span className={classes.SectionTitle}>Produtos</span>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Button variant="contained" color="primary" fullWidth onClick={formik.submitForm}>
                          Iniciar Registro
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <CompanyOrderPaymentsCard
              companyOrderId={formik.values.id}
              supplier={formik.values.supplier}
              total={
                formik.values.taxes +
                formik.values.shipping +
                (_.sum(formik.values.products.map((item) => item.stock.items[0].quantity * item.stock.items[0].cost)))
              }
            />
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={6} md={2}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    finishRequest(apiRoutes.main.companyOrder.finishRegistration, {
                      companyOrderId: formik.values.id,
                      productsChecked: true,
                    });
                  }}
                  fullWidth
                  disabled={finishLoading || cancelLoading || !formik.values.id || !formik.values.products.length}
                >
                  Finalizar
                </Button>
              )}
            </Grid>
            {formik.values.id && (
              <Grid item xs={6} md={2}>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    cancelRequest(apiRoutes.main.companyOrder.cancel, {
                      companyOrderId: formik.values.id,
                    });
                  }}
                  fullWidth
                  disabled={formik.isSubmitting || cancelLoading || finishLoading}
                >
                  Cancelar
                </Button>
              </Grid>
            )}
            {!formik.values.id && (
              <Grid item xs={6} md={2}>
                <Button
                  variant="contained"
                  color="default"
                  fullWidth
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Voltar
                </Button>
              </Grid>
            )}
          </Grid>
        </Card>
      </div>
    </>
  );
};

export default CompanyOrderRegistration;
