import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import Alert from "@material-ui/lab/Alert";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import MTextField from "../../../components/UI/MTextField/MTextField";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import { serviceOrderOpened } from "../../../redux/dashboardGarage/dashboardGarageActions";
import classes from "../../__Containers.css";
import ServiceOrderSelectCustomerModal from "../ServiceOrderSelectCustomerModal/ServiceOrderSelectCustomerModal";

const initialValues = {
  customerId: "",
  vehicleId: "",
  status: 1,
  receptionInfo: "",
  km: "",
  items: "",
};

const validationSchema = Yup.object({
  km: Yup.number("Informe um valor numérico"),
});

const ServiceOrderDetail = ({}) => {
  const navigate = useNavigate();
  const { newServiceOrderPayload } = useSelector((state) => state.serviceOrder);
  const [customer, setCustomer] = useState({});
  const [vehicle, setVehicle] = useState({});
  const [showModal, setShowModal] = useState(true);
  const [items] = useState([]);
  const osItemInputRef = useRef();
  const kmAtualInputRef = useRef();
  const { request, data, errors, loading } = useApiClient();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.serviceOrder.create, {
        ...values,
        customer,
        vehicle,
        items,
      });
    },
  });

  const { setSubmitting, setErrors, setStatus, setValues } = formik;

  useEffect(() => {
    if (!loading) setSubmitting(false);

    if (data && data.success) {
      toast.success("OS Aberta com sucesso");
      dispatch(serviceOrderOpened(data.value));
      navigate("/app/home");
    }

    if (errors) {
      setStatus(errors.status || "");
      setErrors(errors.schema ?? {});
    }
  }, [data, errors, loading, navigate, setSubmitting, setErrors, setStatus]);

  // Customer/Vehicle loading
  const {
    request: customerRequest,
    data: customerData,
    errors: customerErrors,
    loading: customerLoading,
    requestExtra: customerRequestExtra,
  } = useApiClient();

  useEffect(() => {
    if (newServiceOrderPayload?.customerId) {
      customerRequest(
        apiRoutes.main.person.customerById,
        {
          id: newServiceOrderPayload.customerId,
        },
        { requestExtra: newServiceOrderPayload }
      );
      setShowModal(false);
    }
  }, [newServiceOrderPayload]);

  useEffect(() => {
    if (customerData?.success) {
      setCustomer(customerData.value.person);

      let vehicle = 
        _.find(customerData.value.customerProfile.vehicles, (v) => v.id === customerRequestExtra.vehicleId) ??
        _.last(customerData.value.customerProfile.vehicles);

      if(vehicle) setVehicle(vehicle);
      setValues((curr) => {
        return { ...curr, customerId: customerData.value.person.id, vehicleId: vehicle?.id };
      });
    }
  }, [customerData, customerRequestExtra, setValues]);

  useEffect(() => {
    kmAtualInputRef.current.focus();
  }, [kmAtualInputRef, customer]);

  const adicionarItemOS = () => {
    if (formik.values.items) {
      items.push({ description: formik.values.items });
      formik.setFieldValue("items", "");
      osItemInputRef.current.focus();
    }
  };

  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Abertura de Ordem de Serviço" icon={<AssignmentLateIcon />} />
      <ServiceOrderSelectCustomerModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
      <form onSubmit={formik.handleSubmit}>
        <Card className={classes.FormCard}>
          {(formik.isSubmitting || loading) && <LinearProgress />}
          <Grid container spacing={3}>
            {formik.status && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                {customerErrors?.status && (
                  <Grid item xs={12}>
                    <Alert severity="error">{customerErrors.status}</Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Card>
                    {customerLoading && <LinearProgress />}
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <span className={classes.SectionTitle}>Cliente</span>
                        </Grid>
                        {customer.id && (
                          <>
                            <GridItemText label="Nome" text={customer.name} />
                            <GridItemText label="CPF/CNPJ" text={customer.taxId} />
                            <GridItemText label="Tel. 1" text={customer.phone1} />
                            <GridItemText label="Tel. 2" text={customer.phone2} />
                            <GridItemText label="Tel. 3" text={customer.phone3} />
                            <GridItemText label="E-mail" text={customer.email} />
                          </>
                        )}
                        <Grid item xs={12}>
                          <Button
                            variant="text"
                            color="primary"
                            onClick={() => {
                              setShowModal(true);
                              formik.setStatus("");
                            }}
                          >
                            Selecionar
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                {vehicle?.id && (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <span className={classes.SectionTitle}>Veículo</span>
                          </Grid>
                          <GridItemText label="Placa" text={vehicle.plates} />
                          <GridItemText label="Fabricante" text={vehicle.brand} />
                          <GridItemText label="Modelo" text={vehicle.model} md={12} />
                          <GridItemText label="Modelo Ano" text={vehicle.modelYear} />
                          <GridItemText label="Cor" text={vehicle.cor} />
                          {!vehicle.id && (
                            <Grid item xs={12}>
                              <Button variant="text" color="primary" size="small">
                                Associar veículo
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <span className={classes.SectionTitle}>Itens da OS</span>
                    </Grid>
                    {formik.errors.items && (
                      <Grid item={12}>
                        <Alert severity="error">{formik.errors.items}</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <MTextField
                        field="km"
                        label={"KM Atual do veículo"}
                        formik={formik}
                        inputProps={{
                          maxLength: 8,
                          ref: kmAtualInputRef,
                          type: "number",
                          step: 1,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <MTextField
                        field="receptionInfo"
                        label="Informação Adicional"
                        formik={formik}
                        multiline
                        rows={3}
                        inputProps={{
                          maxLength: 1024,
                          placeholder: "Observações do cliente, avarias pré-existentes, segredos, etc."
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MTextField
                        field={"items"}
                        label={"Descrição de item"}
                        formik={formik}
                        multiline
                        rows={3}
                        inputProps={{ maxLength: 250, ref: osItemInputRef }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            adicionarItemOS();
                          }
                        }}
                      />
                      <Button disabled={formik.isSubmitting || !formik.values.items} onClick={() => adicionarItemOS()}>
                        Adicionar
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <List component="nav" aria-label="main mailbox folders">
                        {items.map((item, index) => {
                          return (
                            <ListItem
                              key={index}
                              button
                              disabled={formik.isSubmitting}
                              onClick={(e) => {
                                formik.setFieldValue("items", e.target.textContent);
                                items.splice(index, 1);
                              }}
                            >
                              <ListItemIcon>
                                <AssignmentIcon />
                              </ListItemIcon>
                              <ListItemText primary={<div>{item.description}</div>} />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={6} md={2}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    console.log(newServiceOrderPayload);
                    if (!formik.values.customerId || !formik.values.vehicleId) {
                      formik.setStatus("Antes de prosseguir, selecione o cliente e veículo para a OS.");
                    } else {
                      formik.handleSubmit();
                    }
                  }}
                >
                  Salvar
                </Button>
              )}
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="default"
                fullWidth
                onClick={() => {
                  navigate("/app/home", { replace: true });
                }}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default ServiceOrderDetail;
