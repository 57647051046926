import { Card, CardContent, Grid } from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import React from "react";
import { useNavigate } from "react-router";
import CanvasTitle from "../../components/UI/CanvasTitle/CanvasTitle";
import HotButton from "../../components/UI/HotButton/HotButton";
import classes from "../__Containers.css";
import DashboardGarage from "./DashboardGarage";

const Dashboard = (props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.Canvas} style={{ minHeight: "100%" }}>
      <CanvasTitle
        label="Oficina"
        icon={<AssignmentIcon />}
        defaultAction={
          <HotButton hotkeys="ctrl+n" onClick={() => navigate("/app/os/abertura")}>
            Nova OS
          </HotButton>
        }
      />
      <Grid container spacing={0} alignItems="center" style={{ minHeight: "100%" }}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DashboardGarage />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
