import { Card, CardContent, Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import BallotIcon from "@material-ui/icons/Ballot";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useBarcode } from "react-barcodes";
import toast from "react-hot-toast";
import * as Yup from "yup";
import DetailView from "../../../components/UI/DetailView/DetailView";
import GridItemText from "../../../components/UI/GridItemText/GridItemText";
import MSelectField from "../../../components/UI/MSelectField/MSelectField";
import MTextField from "../../../components/UI/MTextField/MTextField";
import productUnits from "../../../data/productUnits";
import useApiClient from "../../../hooks/apiClient";
import apiRoutes from "../../../hooks/apiClient.routes";
import classes from "../../__Containers.css";
import ProductTable from "./ProductTable";

const initialValues = {
  id: "",
  shortDescription: "",
  application: "",
  lowestPrice: "",
  price: "",
  unavailable: false,
  unavailabilityReason: "",
  strategicImportance: "",
  quantityAlert: "",
  minQuantity: "",
  productUnit: "UN",
};

const validationSchema = Yup.object({
  shortDescription: Yup.string().required("Informe a descrição resumida"),
  application: Yup.string().required("Informe a aplicação desse item ou um termo como 'Universal'"),
  productUnit: Yup.string().required("Selecione uma unidade de medida"),
  quantityAlert: Yup.number().min(0, "Informe uma quantidade maior ou igual a zero"),
  price: Yup.number().min(0.01, "Informe o preço desse item").required("Selecione uma unidade de medida"),
});

const ProductDetail = (props) => {
  const [item, setItem] = useState({});
  const { request, data, errors, loading } = useApiClient();
  const { inputRef } = useBarcode({
    value: item.barcode,
    options: {
      height: 50,
    },
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.stock.save, values);
    },
  });

  const { setSubmitting, setErrors, setStatus } = formik;

  useEffect(() => {
    if (data) {
      toast.success("Salvo com sucesso. ");
    }

    if (!loading) setSubmitting(false);
    if (errors?.status) toast.error(errors?.status);
    setErrors({ ...errors?.schema });
  }, [data, errors, setErrors, setSubmitting]);

  const getResponseHandler = useCallback((data) => {
    setItem(data.value);
    return { ...data.value };
  }, []);

  return (
    <DetailView
      title="Produtos / Cadastro"
      icon={<BallotIcon />}
      defaultAction={null}
      formik={formik}
      getRoute={apiRoutes.main.stock.byId}
      loading={loading}
      getResponseHandler={getResponseHandler}
      {...props}
    >
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <span className={classes.SectionTitle}>Item do Catálogo</span>
              </Grid>
              <GridItemText label="Descrição" text={`${item.shortDescription ?? ""}`} />
              <GridItemText label="Seção" text={`${item.category ?? ""} / ${item.subcategory ?? ""}`} />
              <GridItemText label="Fabricante" text={item.manufacturer} xs={6} />
              <GridItemText label="Cód. Fabricante" text={item.manufacturerCode} xs={6} />
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <b>Código de Barras</b>
                <br />
                <svg ref={inputRef} />
              </Grid>
              <Grid item xs={12} md={12}>
                <ProductTable items={item.items} loading={loading} /> 
              </Grid>
              <Grid item xs={12} md={12}>
                <h3>Quantidade Total em Estoque {item.quantity}</h3>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <span className={classes.SectionTitle}>Item do Estoque</span>
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  field="shortDescription"
                  label={"Descrição breve"}
                  placeholder={"Essa é a descrição que aparecerá nos pedidos"}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12}>
                <MTextField
                  field={"application"}
                  label={"Aplicação"}
                  formik={formik}
                  multiline
                  rows={4}
                  inputProps={{ maxLength: 500 }}
                />
              </Grid>
              <Grid item xs={6}>
                <MTextField
                  field={"lowestPrice"}
                  label={"Valor Mínimo (R$)"}
                  formik={formik}
                  type="number"
                  money
                  min="0.01"
                  step="0.01"
                />
              </Grid>
              <Grid item xs={6}>
                <MTextField
                  field={"price"}
                  label={"Valor (R$)"}
                  formik={formik}
                  type="number"
                  money
                  min="0.01"
                  step="0.01"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox name="unavailable" checked={formik.values.unavailable} onChange={formik.handleChange} />
                  }
                  label="Venda suspensa"
                />
              </Grid>
              <Grid item xs={12}>
                <MTextField field="unavailabilityReason" label="Motivo de suspensão" formik={formik} />
              </Grid>
              <Grid item xs={6}>
                <MTextField
                  field={"quantityAlert"}
                  label={"Quantidade Alerta"}
                  formik={formik}
                  type="number"
                  min="0"
                  step="1"
                />
              </Grid>
              <Grid item xs={6}>
                <MSelectField formik={formik} field="strategicImportance" label="Importância Estratégica">
                  <MenuItem value={0}>Muito Baixa</MenuItem>
                  <MenuItem value={1}>Baixa</MenuItem>
                  <MenuItem value={2}>Média</MenuItem>
                  <MenuItem value={3}>Alta</MenuItem>
                  <MenuItem value={4}>Muito Alta</MenuItem>
                </MSelectField>
              </Grid>
              <Grid item xs={6}>
                <MSelectField formik={formik} field="productUnit" label="Unidade de Medida">
                  {productUnits.map((unit) => (
                    <MenuItem key={unit} value={unit}>
                      {unit}
                    </MenuItem>
                  ))}
                </MSelectField>
              </Grid>
              <Grid item xs={6}>
                <MTextField
                  field="minQuantity"
                  label="Quantidade Mínima"
                  placeholder="Para vendas fracionadas"
                  formik={formik}
                  type="number"
                  min="0"
                  step="1"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </DetailView>
  );
};

export default ProductDetail;
