import _ from "lodash";
import DataTable from "react-data-table-component";

const cols = [
  {
    name: "Descrição",
    selector: "orderDescription",
    width: "50%",
    format: (row) =>
      `${row.stock.shortDescription} ${row.stock.manufacturer} ${row.stock.manufacturerCode}`,
  },
  {
    name: "Quant.",
    selector: "quantity",
    right: true,
    format: (row) => row.stock.items[0].quantity
  },
  {
    name: "Custo(R$)",
    selector: "cost",
    right: true,
    format: (row) => row.stock.items[0].cost.toFixed(2)
  },
  {
    name: "Total(R$)",
    selector: "price",
    right: true,
    format: (row) => (row.stock.items[0].quantity * row.stock.items[0].cost).toFixed(2)
  },
];

const CompanyOrderItemsTable = (props) => {
  const { items, onRowClicked } = props;

  let total = items.map(item => item.stock.items[0].quantity * item.stock.items[0].cost);

  return (
    <div style={{ maxHeight: 400, overflowY: "auto" }}>
      <DataTable
        columns={cols}
        data={items}
        noDataComponent="Nenhum produto para exibir"
        noHeader
        pointerOnHover
        highlightOnHover
        striped
        responsive
        onRowClicked={(row) => {
          if (typeof onRowClicked === "function") onRowClicked(row);
        }}
      />
      <div style={{ textAlign: 'right' }}>
        <h4>TOTAL R${_.sum(total).toFixed(2)}</h4>
      </div>
    </div>
  );
};

export default CompanyOrderItemsTable;
