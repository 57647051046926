import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/auth-slice";
import companyOrderSlice from "./companyOrder/companyOrderSlice";
import dashboardGarage from "./dashboardGarage/dashboardGarageSlice";
import notificationsSlice from "./notifications/notifications-slice";
import serviceOrderSlice from "./serviceOrder/serviceOrderSlice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    notifications: notificationsSlice.reducer,
    dashboardGarage: dashboardGarage.reducer,
    serviceOrder: serviceOrderSlice.reducer,
    companyOrder: companyOrderSlice.reducer
  },
});

export default store;
