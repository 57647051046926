import axios from "axios";
import { useCallback, useReducer } from "react";
import { useNavigate } from "react-router";
import store from "store";
import common from "../config/config.common";
import routes from "./apiClient.routes";
import { toast } from "react-hot-toast";
import appConfig from "../config/app.config";

const initialState = {
  loading: false,
  errors: null,
  data: null,
  requestId: null,
  requestExtra: null,
};

const defaultHeaders = {
  "Content-Type": "application/json",
  "Cache-Control": "no-cache",
  client_id: common.clientId,
};

const apiClientReducer = (currentState, action) => {
  switch (action.type) {
    case "SEND":
      return {
        loading: true,
        errors: null,
        data: null,
        requestId: action.requestId,
        requestExtra: null,
      };
    case "RESPONSE":
      return {
        ...currentState,
        loading: false,
        data: action.responseData,
        requestId: action.requestId,
        requestExtra: action.requestExtra,
      };
    case "ERROR":
      return {
        loading: false,
        errors: action.errors,
        requestId: action.requestId,
        requestExtra: action.requestExtra,
      };
    case "CLEAR":
      return initialState;
    default:
      throw new Error("Oops... alguma coisa deu errado!");
  }
};

const useApiClient = (handleSecurity = true) => {
  const [httpState, dispatch] = useReducer(apiClientReducer, initialState);
  const navigate = useNavigate();

  const clear = useCallback(() => dispatch({ type: "CLEAR" }), []);

  const defaultTimeout = appConfig.env === "dev" ? 0 : 30000;

  const request = useCallback(
    (apiRoute, params, { headers, requestId, requestExtra, timeout = defaultTimeout } = {}) => {
      dispatch({ type: "SEND", requestId });

      const successFn = (response) => {
        dispatch({
          type: "RESPONSE",
          responseData: response.data || {},
          requestId,
          requestExtra,
        });
      };

      const failureFn = (error) => {
        console.error(error);

        let withMessages = { status: "" };

        if (error.response) {
          /*
           * Errors returned from Server: 400, 401, 500;
           */

          if (error.response.status === 401 && handleSecurity) {
            navigate("/login", { replace: true });
            store.remove("user");
            toast.error("Seu tempo de sessão esgotou. Por favor, refaça o login. ");
            return;
          }

          if (typeof error.response.data.errors === "string") {
            withMessages = {
              status: error.response.data.errors,
            };
          } else if (error.response.data && error.response.data.errors) {
            withMessages = error.response.data.errors;
          } else {
            withMessages = error.response.data || { status: "Desculpe, ocorreu um erro durante sua requisição. " };
          }
        } else if (error.request) {
          /**
           * Erro de comunicação com o servidor.
           * A requisição foi realizada, mas sem resposta.
           */
          withMessages = {
            status: "Ocorreu um erro de comunicação com o servidor. ",
          };
        } else {
          // Something happened in setting up the request and triggered an Error
          withMessages = {
            status: "Ocorreu um erro ao preparar sua solicitação. ",
          };
        }

        dispatch({
          type: "ERROR",
          errors: withMessages,
          requestId,
        });
      };

      axios
        .request({
          url: `${apiRoute.url}${apiRoute.method === "POST" ? `?code=${apiRoute.code}` : ""}`,
          method: apiRoute.method,
          params: apiRoute.method === "GET" ? { ...params, code: apiRoute.code } : { code: apiRoute.code },
          data: apiRoute.method === "POST" ? params : null,
          headers: {
            ...defaultHeaders,
            ...headers,
          },
          responseType: apiRoute.responseType,
          withCredentials: true,
          timeout: timeout,
        })
        .then(successFn)
        .catch(failureFn);
    },
    [navigate]
  );

  return {
    request: request,
    clear: clear,
    loading: httpState.loading,
    data: httpState.data,
    errors: httpState.errors,
    requestId: httpState.requestId,
    requestExtra: httpState.requestExtra,
  };
};

export const apiRoutes = routes;

export const getUrl = (route, params) => {
  const searchParams = new URLSearchParams();
  const search = {
    code: route.code,
    ...params,
  };
  Object.keys(search).forEach((key) => searchParams.append(key, search[key]));

  return `${route.url}?${searchParams.toString()}`;
};

export default useApiClient;
