import { Button, Card, CardActions, CardContent, Grid } from "@material-ui/core";
import classes from "../../containers/__Containers.css";
import HistoryIcon from "@material-ui/icons/History";
import CanvasTitle from "../../components/UI/CanvasTitle/CanvasTitle";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router-dom";

const HistoryHome = (props) => {
  const navigate = useNavigate();
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Histórico" icon={<HistoryIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <Grid container spacing={3}>
          <Grid item xs={2} md={2}>
            <Card>
              <CardContent>
                <h4>Ordens de Serviços</h4>
              </CardContent>
              <CardActions>
                <Button onClick={() => navigate("/app/historico/os")}>
                  <SearchIcon />
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default HistoryHome;
