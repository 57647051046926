import React from 'react';
import { Grid } from "@material-ui/core";

const CanvasTitle = (props) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={(props.defaultAction ? 6 : 12)}>
                <h3 style={{ margin: "12px" }}>
                    {props.icon}
                    <p style={{
                        display: "inline",
                        marginLeft: "10px",
                        verticalAlign: "top",
                    }}
                    >
                        {props.label}
                    </p>
                </h3>
            </Grid>
            <Grid item xs={6}>
                <Grid container justifyContent="flex-end">
                    {props.defaultAction}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CanvasTitle;