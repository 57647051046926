import { Button, Card, CardContent, Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import classes from "../../../containers/__Containers.css";
import MSelectField from "../../../components/UI/MSelectField/MSelectField";
import MTextField from "../../../components/UI/MTextField/MTextField";
import employeePositions from "../../../data/employeePositions";
import { KeyboardDatePicker } from "@material-ui/pickers";

const EmployeeCard = (props) => {
  const { formik } = props;

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span className={classes.SectionTitle}>Dados Profissionais</span>
            </Grid>
            <Grid item xs={6}>
              <MSelectField field="position" label="Função" formik={formik}>
                {employeePositions.map((uf) => (
                  <MenuItem key={uf} value={uf}>
                    {uf}
                  </MenuItem>
                ))}
              </MSelectField>
            </Grid>
            <Grid item xs={6}>
              <MSelectField field="bloodType" label="Tipo Sanguíneo" formik={formik}>
                <MenuItem value="Desconhecido">Desconhecido</MenuItem>
                <MenuItem value="A+">A+</MenuItem>
                <MenuItem value="A-">A-</MenuItem>
                <MenuItem value="B+">B+</MenuItem>
                <MenuItem value="B-">B-</MenuItem>
                <MenuItem value="O+">O+</MenuItem>
                <MenuItem value="O-">O-</MenuItem>
                <MenuItem value="AB+">AB+</MenuItem>
                <MenuItem value="AB-">AB-</MenuItem>
              </MSelectField>
            </Grid>
            <Grid item xs={12}>
              <MTextField field={"allergies"} label={"Alergias"} formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <MTextField field={"medicalKnownConditions"} label={"Condições Médicas Relevantes"} formik={formik} />
            </Grid>
            <Grid item xs={6}>
              <MTextField field={"pis"} label={"PIS"} formik={formik} />
            </Grid>
            <Grid item xs={6}>
              <MTextField field={"ctps"} label={"CTPS"} formik={formik} />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="admission"
                name="admission"
                label="Admissão"
                format="dd/MM/yyyy"
                fullWidth
                value={formik.values.admission}
                disableFuture
                disabled={formik.isSubmitting}
                invalidDateMessage="Data inválida"
                onChange={(date) => {
                  formik.setFieldValue("admission", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "alterar nascimento",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="termination"
                name="termination"
                label="Demissão"
                format="dd/MM/yyyy"
                fullWidth
                value={formik.values.termination}
                disableFuture
                disabled={formik.isSubmitting}
                invalidDateMessage="Data inválida"
                onChange={(date) => {
                  formik.setFieldValue("termination", date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "alterar nascimento",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox name="garageStaff" checked={formik.values.garageStaff} onChange={formik.handleChange} />
                }
                label="Esse membro pode ser associado à Ordens de Serviço como Responsável"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EmployeeCard;
