import React, { useRef, useState } from "react";
import appConfig from "../../config/app.config";
import MenuItems from "../Navigation/MenuItems/MenuItems";
import SideDrawer from "../Navigation/SideDrawer/SideDrawer";
import Toolbar from "../Navigation/Toolbar/Toolbar";
import classes from "./Layout.css";

const Layout = (props) => {
  const [showMobileDrawer, setShowMobileDrawer] = useState(false);
  const divMenuRef = useRef();

  return (
    <>
      <Toolbar menuClick={() => setShowMobileDrawer((curr) => !curr)} />
      <SideDrawer open={showMobileDrawer} closed={() => setShowMobileDrawer(false)} />
      <div
        className={classes.Sidebar}
        ref={divMenuRef}
        onMouseOver={() => {
          divMenuRef.current.classList.add(classes.SidebarHover);
        }}
        onMouseLeave={() => {
          divMenuRef.current.classList.remove(classes.SidebarHover);
        }}
        onClick={() => {
          divMenuRef.current.classList.remove(classes.SidebarHover);
        }}
      >
        <MenuItems />
      </div>
      <main className={classes.Content}>
        <div>{props.children}</div>
        <span
          style={{
            color: "gray",
            fontSize: "10px",
            textAlign: "center",
            paddingBottom: 10,
            marginLeft: 12,
            marginRight: 12,
          }}
        >
          Movi Motors Todos os Direitos Reservados © {new Date().getFullYear()}
          &nbsp;•&nbsp;Versão&nbsp;{appConfig.clientVersion}
          &nbsp;•&nbsp;
          <a href="#/termos-de-uso" target="_blank">
            Termos de Uso
          </a>
          &nbsp;•&nbsp;
          <a href="#/privacidade" target="_blank">
            Política de Privacidade
          </a>
        </span>
      </main>
    </>
  );
};

export default Layout;
