import { Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import MAutocomplete from "../../../../components/UI/MAutocomplete/MAutocomplete";
import MAutocompleteProductCategory from "../../../../components/UI/MAutocomplete/MAutocompleteProductCategory";
import MTextField from "../../../../components/UI/MTextField/MTextField";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import * as Yup from "yup";
import DataTable from "react-data-table-component";

const initialValues = {
  category: "",
  subcategory: "",
  shortDescription: "",
  continuationToken: null,
};

const validationSchema = Yup.object({
  category: Yup.string().required("Selecione a categoria do produto"),
  subcategory: Yup.string().required("Selecione a subcategoria do produto"),
});

const cols = [
  {
    name: "Descrição",
    selector: "shortDescription",
    width: "50%",
  },
  {
    name: "Outros fabricantes",
    selector: "quantity",
    format: (row) => row.manufacturerItems?.map((item) => `${item.manufacturer} ${item.manufacturerCode}`).join(", "),
  },
];

const NewProductSearch = ({ onProductSelected }) => {
  const [products, setProducts] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const { request, data, errors, loading } = useApiClient();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      request(apiRoutes.main.product.byGroup, values);
    },
  });

  const { setSubmitting, setFieldValue } = formik;

  useEffect(() => {
    setSubmitting(false);

    if (data) {
      setFieldValue("continuationToken", data.value?.continuationToken);
      setProducts(data.value.owned);
      console.log(data.value.owned);
    }
  }, [data, errors, setFieldValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        Que produto é esse novo item?
      </Grid>
      <Grid item xs={6}>
        <MAutocompleteProductCategory
          formik={formik}
          field="category"
          onChange={(item, value) => setSubcategories(value?.subcategories ?? [])}
        />
      </Grid>
      <Grid item xs={6}>
        <MAutocomplete
          formik={formik}
          field="subcategory"
          options={subcategories}
          optionKeyField="subcategory"
          optionLabelField="subcategory"
          label="Subcategoria"
          placeholder="Selecione uma subcategoria"
        />
      </Grid>
      <Grid item xs={6}>
        <MTextField formik={formik} field="shortDescription" label="Descrição breve" />
      </Grid>
      <Grid item xs={6}>
        <Button variant="contained" disabled={formik.isSubmitting} onClick={formik.handleSubmit}>
          Pesquisar
        </Button>
      </Grid>
      {products && products.length === 0 && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <b>Nenhum item encontrado</b>
        </Grid>
      )}
      {products && products.length > 0 && (
        <>
          <Grid item xs={12}>
            <DataTable
              columns={cols}
              data={products}
              noHeader
              pointerOnHover
              highlightOnHover
              striped
              responsive
              onRowClicked={(row) => {
                onProductSelected(row);
              }}
            />
          </Grid>
        </>
      )}
      {products && (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              onProductSelected({ group: { category: formik.values.category, subcategory: formik.values.subcategory } })
            }
          >
            Incluir Novo Produto
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default NewProductSearch;
