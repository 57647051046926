import StorefrontIcon from '@material-ui/icons/Storefront';
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import { Card } from "@material-ui/core";
import classes from "../../__Containers.css";

const StoreHome = (props) => {
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Loja" icon={<StorefrontIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <span style={{ fontSize: '24px' }}>Em desenvolvimento 👷🏼🚧</span>
      </Card>
    </div>
  );
};

export default StoreHome;