import { Typography } from "@material-ui/core";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const cols = [
  {
    name: "Quant.",
    selector: "quantity",
    width: "50px",
  },
  {
    name: "Descrição",
    selector: "shortDescription",
  },
  {
    name: "Valor Unit.(R$)",
    selector: (row) => row.price.toFixed(2),
    right: true,
  },
  {
    name: "Valor Total(R$)",
    selector: (row) => (row.price * row.quantity).toFixed(2),
    right: true,
  },
];

const TabServices = () => {
  const { serviceOrders, selectedIndex } = useSelector((state) => state.dashboardGarage);

  const dispatch = useDispatch();

  const selectedServiceOrder = ((serviceOrders || {}).active || [])[selectedIndex];
  if (!selectedServiceOrder) return null;

  return (
    <>
      <DataTable
        columns={cols}
        data={selectedServiceOrder.garageOrder?.services ?? []}
        noDataComponent={
          <b>
            <br />
            Nenhum serviço foi adicionado
          </b>
        }
        noHeader
        pointerOnHover
        striped
        dense
        responsive
        onRowClicked={(row) => {
          dispatch(dashboardGarageActions.setServiceRemoveShow(row));
        }}
      />
      {!!selectedServiceOrder.garageOrder?.servicesTotal && (
        <Typography variant="h6" style={{ textAlign: "right" }}>
          Total R$ {selectedServiceOrder.garageOrder?.servicesTotal?.toFixed(2)}
        </Typography>
      )}
    </>
  );
};

export default TabServices;
