import React from "react";
import { Grid } from "@material-ui/core";
import classes from "./GridItemText.css";

const GridItemText = (props) => {
  const { label, text, money, number, empty, xs, md } = props;

  if (!text && !money && !empty && !number) return null;

  let value = "";
  if (money || money === 0) value = money.toFixed(2);
  else if (number || number === 0) value = number.toFixed(0);
  else value = text;
  return (
    <Grid item xs={xs || 12} md={md || 6} className={classes.Dado}>
      <b>{label}</b>
      <p>{value || empty}</p>
    </Grid>
  );
};

export default GridItemText;
