const TabThirdParties = (props) => {
  return (
    <>
      <b>
        <br />
        Em desenvolvimento 👷🏼🚧
      </b>
      <br />
      <small>{props.id}</small>
    </>
  );
};

export default TabThirdParties;
