import React, { Component } from "react";
import { Switch, Route, Navigate } from "react-router-dom";
import Oops from "../containers/Oops/Oops";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  setErrorHandled = () => {
    this.setState({ hasError: false });
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    //TODO send email to suport API
    console.log(error, errorInfo);
    // this.setErrorHandled();
  }

  render() {
    // console.log("error boundary rendered", this);
    if (this.state.hasError) {
      this.setErrorHandled();
      return (
        <Navigate
          to={{
            pathname: "/app/oops",
            state: { internal: true },
          }}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
