import { CircularProgress, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from 'react';
import useApiClient, { apiRoutes } from '../../../hooks/apiClient';

const VehicleModelsSelect = (props) => {

    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);
    const { request, data, errors, loading } = useApiClient();

    const { modelId, brandId, type } = props.formik.values;

    useEffect(() => {
        if (brandId > 0) {
            request(apiRoutes.main.fipe.modelos, {
                idTipoVeiculo: type,
                idMarca: brandId,
            });
        }
        setOptions([]);
    }, [request, type, brandId]);

    useEffect(() => {

        setSelected(null);
        if (!loading && data && data.success) {
            setOptions(data.value);
        }

    }, [data, loading]);

    useEffect(() => {
        const selected = options.find((item) => item.idModelo === modelId);
        setSelected(selected || null);
    }, [options, modelId, brandId, type]);

    if (loading)
        return <CircularProgress />

    if (errors)
        return <Alert severity="error" title="Oops... não foi possível carregar os modelos" />

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.descricao || 'Carregando...'}
            value={selected}
            noOptionsText="Nenhum modelo encontrado"
            onChange={(e, model) => {
                setSelected(model);
                props.onChange(e, model);
            }}
            renderInput={(params) => (

                <TextField
                    {...params}
                    variant="standard"
                    label="Modelo"
                    placeholder="Modelo do veículo"
                    disabled={props.formik.isSubmitting || props.disabled}
                    error={props.formik.touched[props.field] && Boolean(props.formik.errors[props.field])}
                    helperText={props.formik.touched[props.field] ? props.formik.errors[props.field] : ''}
                />
            )}
        />
    );
};

export default VehicleModelsSelect;