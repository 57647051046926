import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  selectedIndex: -1,
  serviceOrders: null,
  servicePickerShow: false,
  serviceRemoveShow: false,
  servicePicked: null,
  productPickerShow: false,
  productRemoveModalShow: false,
  productPicked: null,
  paymentAddModalShow: false,
  paymentRemoveModalShow: false,
  paymentPicked: null,
  closeServiceOrderModalShow: false,
  serviceOrderEditModalShow: false,
};

const refreshGarageOrderTotal = (serviceOrder) => {
  serviceOrder.paymentsTotal = _.sum(serviceOrder.payments.map((item) => item.amount));

  const garageOrder = serviceOrder.garageOrder ?? {};
  garageOrder.productsTotal = _.sum(garageOrder.products?.map((item) => item.quantity * item.price));
  garageOrder.servicesTotal = _.sum(garageOrder.services?.map((item) => item.quantity * item.price));
  garageOrder.totalDue = (garageOrder.servicesTotal ?? 0) + (garageOrder.productsTotal ?? 0);
};

const dashboardGarage = createSlice({
  name: "dashboardGarage",
  initialState,
  reducers: {
    setProductPickerShow(state, action) {
      state.productPickerShow = action.payload;
      if (action.payload) {
        state.productPicked = null;
      }
    },
    setProductRemoveModalShow(state, action) {
      state.productRemoveModalShow = !!action.payload;
      state.productPicked = action.payload;
    },
    setProductPicked(state, action) {
      state.productPicked = action.payload;
    },
    productAdded(state, action) {
      state.productPickerShow = false;
      state.productPicked = null;
      const serviceOrder = _.find(state.serviceOrders.active, (item) => item.id === action.payload.serviceOrderId);
      serviceOrder.garageOrder = serviceOrder.garageOrder ?? { products: [], services: [] };

      const garageOrder = serviceOrder.garageOrder;
      garageOrder.products = [...garageOrder.products, action.payload];

      refreshGarageOrderTotal(state.serviceOrders.active[state.selectedIndex]);
    },
    productRemoved(state, action) {
      state.productRemoveModalShow = false;
      state.productPicked = null;
      const garageOrder = _.find(
        state.serviceOrders.active,
        (item) => item.id === action.payload.serviceOrderId
      ).garageOrder;
      const itemIndex = garageOrder.products.findIndex((item) => item.id === action.payload.itemId);
      garageOrder.products.splice(itemIndex, 1);
      refreshGarageOrderTotal(state.serviceOrders.active[state.selectedIndex]);
    },
    setServicePickerShow(state, action) {
      state.servicePickerShow = action.payload;
      if (action.payload) {
        state.servicePicked = null;
      }
    },
    setServiceRemoveShow(state, action) {
      state.serviceRemoveShow = !!action.payload;
      state.servicePicked = action.payload;
    },
    setServicePicked(state, action) {
      state.servicePicked = action.payload;
    },
    setServiceOrders(state, action) {
      state.serviceOrders = action.payload;
      if (state.selectedIndex === -1 && state.serviceOrders.active.length) state.selectedIndex = 0;
    },
    setSelectedIndex(state, action) {
      state.selectedIndex = action.payload;
    },
    serviceAdded(state, action) {
      state.servicePickerShow = false;
      state.servicePicked = null;
      state.serviceOrders.active[state.selectedIndex].garageOrder = state.serviceOrders.active[state.selectedIndex]
        .garageOrder ?? { products: [], services: [] };

      const garageOrder = state.serviceOrders.active[state.selectedIndex].garageOrder;
      garageOrder.services = [...garageOrder.services, action.payload];

      refreshGarageOrderTotal(state.serviceOrders.active[state.selectedIndex]);
    },
    serviceRemoved(state, action) {
      state.serviceRemoveShow = false;
      state.servicePicked = null;
      const garageOrder = state.serviceOrders.active[state.selectedIndex].garageOrder;
      garageOrder.services = garageOrder.services.filter((item) => item.id !== action.payload);
      refreshGarageOrderTotal(state.serviceOrders.active[state.selectedIndex]);
    },
    serviceOrderUpdate(state, action) {
      const serviceOrder = state.serviceOrders.active.find((item) => item.id === action.payload.id);
      serviceOrder.km = action.payload.km;
      serviceOrder.receptionInfo = action.payload.receptionInfo;
      serviceOrder.employeeResponsible = action.payload.employeeResponsible;

      state.serviceOrderEditModalShow = false;
    },
    serviceOrderUpdateItem(state, action) {
      // TODO When the time comes where you want to show the time of 'done' make sure to adjust the timezone
      const serviceOrder = state.serviceOrders.active.find((item) => item.id === action.payload.id);
      const updatedItem =
        serviceOrder.items[_.findIndex(serviceOrder.items, (item) => item.id === action.payload.itemId)];

      updatedItem.done = action.payload.isDone ? new Date().toJSON() : null;
      updatedItem.description = action.payload.description;

      // Update service order status
      if (_.every(serviceOrder.items, (item) => !!item.done)) {
        serviceOrder.status = 3;
      } else if (_.every(serviceOrder.items, (item) => !!!item.done)) {
        serviceOrder.status = 4;
      } else {
        serviceOrder.status = 2;
      }
    },
    setPaymentAddModalShow(state, action) {
      state.paymentAddModalShow = action.payload;
    },
    setPaymentRemoveModalShow(state, action) {
      state.paymentRemoveModalShow = !!action.payload;
      state.paymentPicked = action.payload;
    },
    paymentAdded(state, action) {
      state.paymentAddModalShow = false;
      const serviceOrder = state.serviceOrders.active[state.selectedIndex];
      serviceOrder.payments = [...serviceOrder.payments, action.payload];
      refreshGarageOrderTotal(serviceOrder);
    },
    paymentRemoved(state, action) {
      state.setPaymentRemoveModalShow = false;
      state.paymentPicked = null;
      var payments = state.serviceOrders.active[state.selectedIndex].payments;
      state.serviceOrders.active[state.selectedIndex].payments = payments.filter(
        (p) => p.paymentId !== action.payload.paymentId
      );
      refreshGarageOrderTotal(state.serviceOrders.active[state.selectedIndex]);
    },
    setCloseServiceOrderModalShow(state, action) {
      state.closeServiceOrderModalShow = action.payload;
    },
    serviceOrderOpened(state, action) {
      state.serviceOrders.active = [...state.serviceOrders.active, action.payload];
      state.selectedIndex = state.serviceOrders.active.length - 1;
    },
    serviceOrderClosed(state, action) {
      const index = _.findIndex(state.serviceOrders.active, (item) => item.id === action.payload.id);
      if (index === -1) return;
      state.serviceOrders.active.splice(index, 1);
      state.closeServiceOrderModalShow = false;
    },
    setServiceOrderEditModalShow(state, action) {
      state.serviceOrderEditModalShow = action.payload;
    },
    signalRServiceOrderChanged(state, action) {
      const index = _.findIndex(state.serviceOrders.active, (item) => item.id === action.payload.id);

      if (index === -1) return;

      if (action.payload.closed) {
        // Check if it is finished then remove: action.payload.closed
        state.serviceOrders.active.splice(index, 1);
      } else {
        // Or update with the payload
        state.serviceOrders.active[index] = action.payload;
      }
    },
    signalRServiceAdded(state, action) {
      const serviceOrder = state.serviceOrders.active.find(
        (item) => item.garageOrderId === action.payload.garageOrderId
      );
      if (serviceOrder) {
        serviceOrder.garageOrder.services = [...serviceOrder.garageOrder.services, action.payload.service];
      }
    },
    signalRServiceRemoved(state, action) {
      const serviceOrder = state.serviceOrders.active.find(
        (item) => item.garageOrderId === action.payload.garageOrderId
      );
      if (serviceOrder) {
        serviceOrder.garageOrder.services = serviceOrder.garageOrder.services.filter(
          (item) => item.id !== action.payload.itemId
        );
      }
    },
  },
});

export const dashboardGarageActions = dashboardGarage.actions;

export default dashboardGarage;
