import { Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import GridItemText from "../../../../components/UI/GridItemText/GridItemText";
import useApiClient, { apiRoutes } from "../../../../hooks/apiClient";
import { serviceOrderItemChanged } from "../../../../redux/dashboardGarage/dashboardGarageActions";
import { dashboardGarageActions } from "../../../../redux/dashboardGarage/dashboardGarageSlice";

const TabServiceOrder = ({ serviceOrder }) => {
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(serviceOrderItemChanged(requestExtra));
    }

    if (errors) {
      toast.error("Desculpe, ocorreu um erro durante essa operação. Tente novamente mais tarde. ");
    }
  }, [data, requestExtra, errors]);

  const doneToggleHandler = useCallback(
    (serviceOrderId, item) => {
      const payload = {
        id: serviceOrderId,
        itemId: item.id,
        description: item.description,
        isDone: !item.done,
      };
      request(apiRoutes.main.serviceOrder.updateItem, payload, { requestExtra: payload });
    },
    [request]
  );

  return (
    <>
      <br />
      <Grid container spacing={3}>
        <GridItemText label="KM Atual" number={serviceOrder.km} xs={6} md={4} />
        <GridItemText
          label="Abertura"
          text={`${format(new Date(serviceOrder.creation), "dd/MM/yyyy HH:mm")}`}
          xs={6}
          md={4}
        />
        <GridItemText label="Responsável" text={serviceOrder.employeeResponsible?.name ?? "(Nenhum)"} xs={12} md={4} />
        <GridItemText label="Observações da Recepção" text={serviceOrder.receptionInfo} xs={12} md={12} />
        <Grid item xs={12} md={12}>
          <b>Items da OS</b>
          <List component="nav" aria-label="itens da os">
            {serviceOrder.items?.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  onClick={() => doneToggleHandler(serviceOrder.id, item)}
                  disabled={loading}
                >
                  <ListItemIcon>
                    {loading ? (
                      <CircularProgress size={20} />
                    ) : item.done ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={<div>{item.description}</div>} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
      <Button
        onClick={() => {
          dispatch(dashboardGarageActions.setServiceOrderEditModalShow(true));
        }}
        color="primary"
      >
        Editar
      </Button>
    </>
  );
};

export default TabServiceOrder;
