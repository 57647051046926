import Dialog from "@material-ui/core/Dialog";
import React from "react";
import VehicleDetail from "./VehicleDetail";

const VehicleDetailModal = (props) => {
  
  if (!props.show) return null;

  return (
    <Dialog
      open
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      scroll="body"
    >
      <VehicleDetail
        title={props.id ? "Editar Veículo Associado" : "Associar Veículo"}
        id={props.id}
        handleClose={() => props.handleClose()}
        handleDone={props.handleDone}
      />
    </Dialog>
  );
};

export default VehicleDetailModal;
