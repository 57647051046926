import { Button, Card, CircularProgress, Grid, LinearProgress } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import classes from "../../../containers/__Containers.css";
import useApiClient from "../../../hooks/apiClient";

const DetailView = (props) => {
  const { title, icon, formik, getRoute, getResponseHandler } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const { request, data, errors, loading } = useApiClient();
  const { setSubmitting, setValues, setStatus } = formik;

  useEffect(() => {
    if (location && location.state && location.state.id) {
      setSubmitting(true);
      request(getRoute, {
        id: location.state.id,
      });
      delete location.state.id;
    }
  }, [getRoute, location, request, setSubmitting]);

  useEffect(() => {
    if (data || errors) setSubmitting(false);
    if (data) {
      if (typeof getResponseHandler === "function") {
        var values = getResponseHandler(data);
        setValues((curr) => { return { ...curr, ...values }; });
      } else setValues({ ...data.value });
    }

    if (errors) setStatus("Erro carregando o item. Por favor, tente novamente mais tarde. ");
  }, [data, errors, setSubmitting, setValues, setStatus, getResponseHandler]);

  return (
    <div className={classes.Canvas}>
      <CanvasTitle label={title} icon={icon} defaultAction={null} />
      <form onSubmit={formik.handleSubmit}>
        <Card className={classes.FormCard}>
          {(formik.isSubmitting || loading) && <LinearProgress />}
          <Grid container spacing={3}>
            {formik.status && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            {props.children}
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={6} md={2}>
              {formik.isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Salvar
                </Button>
              )}
            </Grid>
            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="default"
                fullWidth
                onClick={() => navigate(-1)} //TODO resolve with https://stackoverflow.com/a/50404777/5734876 ?
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </div>
  );
};

export default DetailView;
