import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
// import { AnimatedSwitch } from 'react-router-transition';
import Layout from "../../components/Layout/Layout";
import ErrorBoundary from "../../hoc/ErrorBoundary";
import CompanyOrderRegistration from "../CompanyOrder/CompanyOrderRegistration/CompanyOrderRegistration";
import CustomerDetail from "../Customer/CustomerDetail/CustomerDetail";
import CustomerSearch from "../Customer/CustomerSearch/CustomerSearch";
import Dashboard from "../Dashboard/Dashboard";
import Debug from "../Debug/Debug";
import FinancesHome from "../Finances/FinancesHome/FinancesHome";
import HistoryHome from "../History/HistoryHome";
import ServiceOrderHistorySearch from "../History/ServiceOrderHistorySearch";
import NotFound from "../NotFound/NotFound";
import NotificationsSearch from "../Notifications/NotificationSearch/NotificationSearch";
import Oops from "../Oops/Oops";
import ProductDetail from "../Products/ProductDetail/ProductDetail";
import ProductSearch from "../Products/ProductSearch/ProductSearch";
import PromotionsHome from "../Promotions/PromotionsHome/PromotionsHome";
import SecurityHome from "../Security/SecurityHome/SecurityHome";
import ServiceOrderDetail from "../ServiceOrder/ServiceOrderDetail/ServiceOrderDetail";
import ServiceDetail from "../Services/ServiceDetail.js/ServiceDetail";
import ServiceSearch from "../Services/ServiceSearch/ServiceSearch";
import SettingsSearch from "../Settings/SettingsSearch/SettingsSearch";
import StoreHome from "../Store/StoreHome/StoreHome";
import SupplierDetail from "../Supplier/SupplierDetail/SupplierDetail";
import SupplierSearch from "../Supplier/SupplierSearch/SupplierSearch";
import TeamDetail from "../Team/TeamDetail/TeamDetail";
import TeamSearch from "../Team/TeamSearch/TeamSearch";
// import classes from "./Home.module.css"; TODO broke the layout. Visit it later.

const Home = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const noUser = !user;

  useEffect(() => {
    if (noUser) {
      navigate("/login", { replace: true });
    }
  }, [noUser]);

  if (noUser) return null;

  return (
    <Layout>
      <ErrorBoundary>
        <Routes>
          <Route path={"/clientes"} element={<CustomerSearch />} />
          <Route path={"/servicos"} element={<ServiceSearch />} />
          <Route path={"/servicos/form"} element={<ServiceDetail />} />
          <Route path={"/produtos"} element={<ProductSearch />} />
          <Route path={"/produtos/form"} element={<ProductDetail />} />
          <Route path={"/produtos/pedidos/entrada"} element={<CompanyOrderRegistration />} />
          <Route path={"/equipe"} element={<TeamSearch />} />
          <Route path={"/equipe/form"} element={<TeamDetail />} />
          <Route path={"/clientes/form"} element={<CustomerDetail />} />
          <Route path={"/os/abertura"} element={<ServiceOrderDetail />} />
          <Route path={"/fornecedores"} element={<SupplierSearch />} />
          <Route path={"/fornecedores/form"} element={<SupplierDetail />} />
          <Route path={"/historico"} element={<HistoryHome />} />
          <Route path={"/historico/os"} element={<ServiceOrderHistorySearch />} />
          <Route path={"/promocoes"} element={<PromotionsHome />} />
          <Route path={"/financeiro"} element={<FinancesHome />} />
          <Route path={"/seguranca"} element={<SecurityHome />} />
          <Route path={"/notificacoes"} element={<NotificationsSearch />} />
          <Route path={"/configuracoes"} element={<SettingsSearch />} />
          <Route path={"/loja"} element={<StoreHome />} />
          <Route path={"/home"} element={<Dashboard />} />
          <Route path={"/oops"} element={<Oops />} />
          <Route path={"/debug"} element={<Debug />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ErrorBoundary>
    </Layout>
  );
};

export default Home;
