import React from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const StatusIcon = (props) => {

    const { serviceOrder } = props;

    if (!serviceOrder)
        return null;

    if (serviceOrder.status === 1 || serviceOrder.status === 4) // Open or Pending
        return <AssignmentLateIcon style={{ color: '#b22a00' }} />;

    if (serviceOrder.status === 2) // In execution
        return <AssignmentIndIcon style={{ color: '#b28900' }} />

    if (serviceOrder.status === 3) // Finished
        return <AssignmentTurnedInIcon style={{ color: '#357a38' }} />

    return null;
};

export default StatusIcon;