import toast from "react-hot-toast";
import { broadcastUpdateResource } from "../../signalR/SignalR";
import { dashboardGarageActions } from "./dashboardGarageSlice";

export const garageOrderProductAdded = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.productAdded(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.serviceOrderId },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const garageOrderProductRemoved = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.productRemoved(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.serviceOrderId },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderPaymentAdded = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.paymentAdded(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.serviceOrderId },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderPaymentRemoved = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.paymentRemoved(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.serviceOrderId },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderOpened = (serviceOrder) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceOrderOpened(serviceOrder));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: serviceOrder.id },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderClosed = (serviceOrder) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceOrderClosed(serviceOrder));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: serviceOrder.id },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderItemChanged = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceOrderUpdateItem(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.id },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceOrderChanged = (item) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceOrderUpdate(item));
      await broadcastUpdateResource({
        name: "serviceOrder",
        params: { serviceOrderId: item.id },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceAdded = (garageOrderId, service) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceAdded(service));
      await broadcastUpdateResource({
        name: "dashboardGarage.serviceOrders.active.garageOrder.services:add",
        params: { garageOrderId, service },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};

export const serviceRemoved = (garageOrderId, itemId) => {
  return async (dispatch) => {
    const broadcast = async () => {
      dispatch(dashboardGarageActions.serviceRemoved(itemId));
      await broadcastUpdateResource({
        name: "dashboardGarage.serviceOrders.active.garageOrder.services:remove",
        params: { garageOrderId, itemId },
      });
    };

    try {
      await broadcast();
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  };
};
