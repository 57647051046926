import { Button, Card, CardContent, Grid, MenuItem, LinearProgress } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import MSelectField from "../../../components/UI/MSelectField/MSelectField";
import MTextField from "../../../components/UI/MTextField/MTextField";
import classes from "../../__Containers.css";
import useApiClient, { apiRoutes } from "./../../../hooks/apiClient";
import VehicleBrandsSelect from "./VehicleBrandsSelect";
import VehicleModelsSelect from "./VehicleModelsSelect";
import VehicleColorsSelect from "./VehicleColorsSelect";

const validationSchema = Yup.object({
  tipo: Yup.string().default(""),
  brandId: Yup.string().required("Selecione o fabricante"),
  modelId: Yup.string().nullable().required("Selecione o modelo"),
  modelYear: Yup.number()
    .required("Informe o ano do modelo com 4 dígitos")
    .min(1908, "Informe uma ano válido a partir de 1908")
    .max(new Date().getFullYear() + 2, "O ano do modelo não pode ser tão posterior"),
  color: Yup.string().required("Selecione a cor do veículo"),
  plates: Yup.string()
    .required("Informe a placa do veículo")
    .matches(/^[A-Za-z]{3}[0-9]{1}[A-Za-z0-9]{1}[0-9]{2}/g, "Formato da placa é inválido"),
});

const initialValues = {
  id: "",
  type: 1,
  brandId: "",
  brand: "",
  modelId: "",
  model: "",
  modelYear: "",
  color: "",
  plates: "",
  info: "",
};

const VehicleDetail = (props) => {
  const { request: submitRequest, data: submitData, errors: submitErrors } = useApiClient();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      submitRequest(apiRoutes.main.vehicle.save, values);
    },
  });

  const { setSubmitting, setErrors, setStatus, setValues, values } = formik;

  const { handleDone, handleClose } = props;

  const doneHandler = useCallback(
    (id) => {
      handleDone({ ...values, id });
      handleClose();
      toast.success("Veículo salvo");
    },
    [handleDone, handleClose, values]
  );

  useEffect(() => {
    setSubmitting(false);

    if (submitErrors) {
      setStatus(submitErrors.status);
      setErrors(submitErrors.schema || {});
    }
  }, [setSubmitting, submitData, submitErrors, setStatus, setErrors]);

  useEffect(() => {
    if (submitData && submitData.success) {
      doneHandler(submitData.value.id);
    }
  }, [submitData, doneHandler]);

  // Load vehicle by ID
  const { request: vehicleRequest, data: vehicleData, errors: vehicleErrors, loading: vehicleLoading } = useApiClient();

  useEffect(() => {
    if (props.id) {
      vehicleRequest(apiRoutes.main.vehicle.byId, { id: props.id });
    }
  }, [vehicleRequest, props.id]);

  useEffect(() => {
    if (vehicleData) {
      setValues(vehicleData.value);
    }
  }, [setValues, vehicleData, vehicleErrors]);

  // Search and load vehicle by PLATE
  const { request: plateRequest, data: plateData, loading: plateLoading } = useApiClient();
  const [validPlate, setValidPlates] = useState("");
  useEffect(() => {
    if (validPlate) plateRequest(apiRoutes.main.vehicle.byPlate, { plates: validPlate });
  }, [plateRequest, validPlate]);

  useEffect(() => {
    if (!plateLoading && plateData && plateData.success) {
      setValues(plateData.value);
    }
  }, [plateData, plateLoading, setValues]);

  const isLoading = formik.isSubmitting || vehicleLoading || plateLoading;

  return (
    <Card style={{ padding: "12px" }}>
      {isLoading && <LinearProgress />}
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.SectionTitle}>
              {props.title}
            </Grid>
            <Grid item xs={12} md={4}>
              <MTextField
                field="plates"
                label="Placa"
                formik={formik}
                disabled={isLoading || !!props.id}
                onBlur={() => {
                  if (formik.values.plates && !formik.errors.plates) {
                    setValidPlates(formik.values.plates);
                  }
                }}
                inputProps={{
                  maxLength: 7,
                  style: { textTransform: "uppercase" },
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <MSelectField field="type" label="Tipo" formik={formik} disabled={isLoading}>
                <MenuItem key={1} value={1}>
                  Passeio
                </MenuItem>
                <MenuItem key={2} value={2}>
                  Moto
                </MenuItem>
                <MenuItem key={3} value={3}>
                  Linha Pesada
                </MenuItem>
              </MSelectField>
            </Grid>
            <Grid item xs={12} md={4}>
              <VehicleColorsSelect
                field="color"
                formik={formik}
                disabled={isLoading}
                onChange={(e, color) => {
                  formik.setFieldValue("color", color ? color.text : "");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <VehicleBrandsSelect
                field="brandId"
                formik={formik}
                disabled={isLoading}
                tipo={formik.values.type}
                onChange={(e, brand) => {
                  formik.setFieldValue("brandId", brand ? brand.idMarca : "");
                  formik.setFieldValue("brand", brand ? brand.descricao : "");
                  formik.setFieldValue("modelId", "");
                  formik.setFieldValue("model", "");
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MTextField
                field="modelYear"
                label="Ano"
                inputProps={{ maxLength: 4 }}
                formik={formik}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <VehicleModelsSelect
                field="modelId"
                formik={formik}
                disabled={isLoading}
                brandId={formik.values.brandId}
                onChange={(e, model) => {
                  console.log(model);
                  formik.setFieldValue("modelId", model ? model.idModelo : "");
                  formik.setFieldValue("model", model ? model.descricao : "");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MTextField
                field="info"
                label="Informação Adicional"
                formik={formik}
                disabled={isLoading}
                multiline
                rows={2}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={formik.handleSubmit}>
                Salvar
              </Button>
              &nbsp;
              <Button variant="contained" color="default" onClick={() => props.handleClose()}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default VehicleDetail;
