import { CircularProgress } from "@material-ui/core";

const LoadingLabel = (props) => {
  const { loading, loadingText, text } = props;
  return (
    <>
      {loading && (
        <span>
          <CircularProgress size={20} color="secondary" />
          &nbsp;
          &nbsp;
        </span>
      )}
      <span>{loadingText || text}</span>
    </>
  );
};

export default LoadingLabel;
