import {
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Paper, TextField
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import LogoImg from "../../assets/images/logo.png";
import classes from "./LandingPage.css";

const validationSchema = yup.object({
  email: yup
    .string("Informe o e-mail de seu usuário")
    .email("Informe um e-mail válido")
    .required("O e-mail é o único campo obrigatório"),
});

const LandingPage = () => {
  const [result, setResult] = useState('');
  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      axios
        .post(
          "https://movi-serverless-main.azurewebsites.net/api/SignNewsletter?code=rQ840yKp35HBrMaw6vkP19IAn5VGaVv2DtHvU5D9haec44ZzNi6cFQ==",
          values
        )
        .then((response) => {
          actions.setSubmitting(false);

          if (response.data.success) {
            localStorage.setItem("newsletter", "signed");
            alert(
              "Ficamos felizes por seu interesse e manteremos você informado. "
            );
            window.location.reload();
          } else {
            formik.setStatus(
              "Desculpe, ocorreu um erro. Por favor, tente novamente mais tarde."
            );
          }
        })
        .catch((e) => {
          actions.setSubmitting(false);
          console.error(e);
          formik.setStatus(
            "Oops... Ocorreu um erro durante sua requisição. Tente novamente mais tarde. "
          );
        });
    },
  });

  let content = (
    <Grid container justifyContent="center" className={classes.LandingPage}>
      <Grid item xs={12} sm={4}>
        <Paper elevation={5} style={{ textAlign: "center", padding: "20px" }}>
          <a href="#/login">
            <img alt="Movi Motors Logo" src={LogoImg} width={150} />
          </a>
          <h2>Sua oficina vai ser 2.0</h2>
          <p>
            O Movi é uma solução completa de gestão para oficinas de veículos
            leves, linha pesada ou motos que está em desenvolvimento.
          </p>
          <p>
            Se você deseja ser informado quando estivermos com tudo pronto,
            deixe seu contato abaixo.
          </p>
          <p>
            <small>Time Movi Motors</small>
            <br />
            <small>suporte@movimotors.com.br</small>
          </p>
          {!localStorage.getItem("newsletter") ? (
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4} justifyContent="center" style={{ padding: '12px' }}>
                <Collapse in={formik.status !== undefined}>
                  <Grid item xs={12}>
                    <Alert severity="error">{formik.status}</Alert>
                  </Grid>
                </Collapse>
                <Grid item xs={12}>
                  <TextField
                    id="nome"
                    name="nome"
                    label="Seu nome (opcional)"
                    fullWidth
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="email"
                    name="email"
                    label="E-mail de contato*"
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="telefone"
                    name="telefone"
                    label="DDD + Telefone de contato (opcional)"
                    fullWidth
                    value={formik.values.telefone}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.telefone && Boolean(formik.errors.telefone)
                    }
                    helperText={
                      formik.touched.telefone && formik.errors.telefone
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="mensagem"
                    name="mensagem"
                    label="Mensagem (opcional)"
                    multiline
                    rows={4}
                    fullWidth
                    value={formik.values.mensagem}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mensagem && Boolean(formik.errors.mensagem)
                    }
                    helperText={
                      formik.touched.mensagem && formik.errors.mensagem
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  {formik.isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                    >
                      Enviar
                    </Button>
                    <Button onClick={() => {
                      console.log(window);
                      formik.setSubmitting(true);
                      window.api.sayHello("C:\\git\\movi\\web\\public\\movi-bridge\\Movi.Desktop.Bridge", ['arg1', 'arg2'], (result) => {
                        console.log("IPC result from react!!!! ", result);
                        setResult(JSON.parse(result.data).result.acbrResult);
                        formik.setSubmitting(false);
                      });
                    }}>
                      Teste {result}
                    </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </form>
          ) : (
            <p>
              <b>Manteremos você informado!</b>
            </p>
          )}
        </Paper>
      </Grid>
      <br />
      <br />
      <br />
    </Grid>
  );
  return content;
};

export default LandingPage;
