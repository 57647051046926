import React from "react";

import classes from "./DrawerToggle.css";

import Menu from '@material-ui/icons/Menu';

const drawerToggle = (props) => {
  return (
    <div className={classes.DrawerToggle} onClick={props.clicked}>
      <Menu />
    </div>
  );
};
export default drawerToggle;
