import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import GridItemText from "../../../../../components/UI/GridItemText/GridItemText";
import { dashboardGarageActions } from "../../../../../redux/dashboardGarage/dashboardGarageSlice";
import ServiceSearch from "../../../../Services/ServiceSearch/ServiceSearch";
import * as Yup from "yup";
import MTextField from "../../../../../components/UI/MTextField/MTextField";
import useApiClient from "../../../../../hooks/apiClient";
import apiRoutes from "../../../../../hooks/apiClient.routes";
import toast from "react-hot-toast";
import LoadingLabel from "../../../../../components/UI/LoadingLabel/LoadingLabel";
import { Alert } from "@material-ui/lab";
import { serviceAdded } from "../../../../../redux/dashboardGarage/dashboardGarageActions";

const initialValues = {
  serviceOrderId: "",
  serviceId: "",
  quantity: "1",
  price: "",
};

const validationSchema = Yup.object({
  serviceOrderId: Yup.string().required(""),
  serviceId: Yup.string().required(""),
  quantity: Yup.number().required("Informe a quantidade desse serviço"),
  price: Yup.number().min(0.01, "Informe um valor maior ou igual a R$0,01").required("Informe o preço"),
});

const ServicePickerModal = (props) => {
  const { selectedServiceOrder } = props;
  const dispatch = useDispatch();
  const { request, data, errors, loading, requestExtra } = useApiClient();
  const { servicePicked } = useSelector((state) => state.dashboardGarage);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      request(apiRoutes.main.garageOrder.addService, values, {
        requestExtra: { quantity: values.quantity, price: values.price },
      });
    },
  });

  const { setValues, setStatus, setFieldValue, setSubmitting } = formik;

  useEffect(() => {
    setValues({
      serviceOrderId: selectedServiceOrder.id,
      quantity: "1",
    });
  }, [setValues, selectedServiceOrder.id]);

  useEffect(() => {
    if (data) {
      toast.success("Serviço adicionado");
      dispatch(
        serviceAdded(selectedServiceOrder.garageOrderId, { ...servicePicked, id: data.value.itemId, ...requestExtra })
      );
    }

    setStatus((errors || {}).status);
    setSubmitting(false);
  }, [data, errors, requestExtra, setSubmitting, setStatus]);

  const readOnly = formik.isSubmitting;

  const onClose = useCallback(() => {
    if (!formik.isSubmitting) {
      setStatus(null);
      dispatch(dashboardGarageActions.setServicePickerShow(false));
    }
  }, [formik.isSubmitting, setStatus]);

  const total = formik.values.price * formik.values.quantity;

  return (
    <Dialog fullWidth={false} maxWidth="md" open onClose={onClose} aria-labelledby="adicionar serviço">
      <DialogTitle id="max-width-dialog-title">Adicionar Serviço</DialogTitle>
      <DialogContent>
        {!servicePicked ? (
          <ServiceSearch
            asComponent
            onRowClicked={(service) => {
              setFieldValue("serviceId", service.id);
              setFieldValue("price", service.price);
              dispatch(dashboardGarageActions.setServicePicked(service));
            }}
          />
        ) : (
          <Grid container spacing={2}>
            {formik.status && !loading && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <GridItemText md={6} label="Categoria" text={servicePicked.category} />
            <GridItemText md={6} label="Subcategoria" text={servicePicked.subcategory} />
            <GridItemText md={12} label="Descrição" text={servicePicked.shortDescription} />
            <Grid item xs={6}>
              <MTextField
                field={"quantity"}
                label={"Quantidade"}
                formik={formik}
                disabled={readOnly}
                type="number"
                money
                min="1"
                step="1"
              />
            </Grid>
            <Grid item xs={6}>
              <MTextField
                field={"price"}
                label={"Valor Unit.(R$)"}
                formik={formik}
                disabled={readOnly}
                type="number"
                money
                min="0.01"
                step="0.01"
              />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <b>TOTAL R${(total || 0).toFixed(2)}</b>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={formik.isSubmitting}>
          Fechar
        </Button>
        <Button disabled={loading} onClick={formik.handleSubmit} color="primary">
          <LoadingLabel loading={formik.isSubmitting} text="Salvar" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServicePickerModal;
