import { Button, CircularProgress, Grid, Paper, TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import store from "store";
import * as yup from "yup";
import appConfig from "../../config/app.config";
import useApiClient, { apiRoutes } from "../../hooks/apiClient";
import { authActions } from "../../redux/auth/auth-slice";
import LogoImg from "./../../assets/images/logo.png";
import classes from "./Login.css";

const validationSchema = yup.object({
  email: yup.string().email("Informe um e-mail válido").required("Informe o e-mail de seu usuário"),
  password: yup.string().min(6, "A senha precisa ter no mínimo 6 caracteres").required("Informe sua senha"),
});

const Login = (props) => {
  const { request, data, errors, loading } = useApiClient(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var buffer = Buffer.from(`${values.email}:${values.password}`);
      request(
        apiRoutes.main.auth.signInBasic,
        {},
        {
          headers: {
            Authorization: `Basic ${buffer.toString("base64")}`,
          },
        }
      );
    },
  });

  const { setErrors, setStatus, setSubmitting } = formik;
  const navigate = useNavigate();

  useEffect(() => {
    
    if (data || errors) setSubmitting(false);

    if (data && data.success) {
      store.set("user", data.value);
      dispatch(authActions.signIn(data.value));
      navigate("/app/home");
    }

    if (errors) {
      setStatus(errors.status);
      setErrors(errors.schema || {});
    }
  }, [data, errors, navigate, setSubmitting, setErrors, setStatus]);

  return (
    <div className={classes.Login}>
      <Paper elevation={5} style={{ padding: "30px" }}>
        <div>
          <img
            src={LogoImg}
            alt="logo movi"
            style={{ cursor: "pointer", width: "20%", marginBottom: "20px" }}
            onClick={() => navigate("/")}
          />
          <br />
          <div style={{ fontWeight: "lighter", marginBottom: "40px" }}>
            <span>Acesse sua conta</span>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            {formik.status && !loading && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.status}</Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                label="E-mail do usuário"
                fullWidth
                disabled={formik.isSubmitting}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="password"
                name="password"
                type="password"
                label="Senha"
                fullWidth
                disabled={formik.isSubmitting}
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button variant="contained" color="primary" type="submit" fullWidth>
                  Acessar
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <Link to="/esqueci-senha">Esqueci minha senha</Link>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.Info}>
                Novo por aqui?
                <br />
                <Link to="/criar-conta">Crie uma conta grátis.</Link>
              </div>
              <span style={{ color: "gray", fontSize: "10px" }}>
                Todos os Direitos Reservados © {new Date().getFullYear()} | Versão {appConfig.clientVersion}
              </span>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default Login;
