import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import appConfig from "./config/app.config";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import Home from "./containers/Home/Home";
import LandingPage from "./containers/LandingPage/LandingPage";
import Login from "./containers/Login/Login";
import NotFound from "./containers/NotFound/NotFound";
import RedefinirSenha from "./containers/PasswordRedefinition/PasswordRedefinition";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import ServiceOrderViewer from "./containers/ServiceOrder/ServiceOrderViewer/ServiceOrderViewer";
import TermsOfUse from "./containers/TermsOfUse/TermsOfUse";
import GuardedRoute from "./hoc/GuardedRoute";
import apiRoutes, { prepareRoutes } from "./hooks/apiClient.routes";

const app = () => {
  console.log(`Movimotors App v.${appConfig.clientVersion}`);
  console.log(`Released ${appConfig.clientVersionReleaseDate}`);

  console.log(`Loading metadata for ${appConfig.env}...`);
  let startingTime = new Date().getTime();

  prepareRoutes(apiRoutes);

  console.log(`Configured in ${new Date().getTime() - startingTime}ms`);

  return (
    <>
      <Routes>
        {appConfig.env === "prod" ? (
          <Route path="/" element={<Login />} />
        ) : (
          <Route path="/" element={<LandingPage />} />
        )}
        <Route path="/login" element={<Login />} />;
        <Route path="/esqueci-senha" element={<ForgotPassword />} />;
        <Route path="/definir-senha" element={<RedefinirSenha />} />;
        <Route path="/minha-os/:id" element={<ServiceOrderViewer />} />;
        <Route path="/privacidade" element={<PrivacyPolicy />} />;
        <Route path="/termos-de-uso" element={<TermsOfUse />} />;
        <Route path="/app/*" element={<Home />} />;
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster />
    </>
  );
};

export const openArrayBuffer = (arraybuffer, type = "application/pdf") => {
  var blob = new Blob([arraybuffer], { type });

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob);
  } else {
    var objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  }
};

export const openCenteredWindow = (url, title, width, height) => {
  var left = (window.screen.width - width) / 2;
  var top = (window.screen.height - height) / 4;

  window.open(url, title, `resizable=yes,width=${width},height=${height},top=${top},left=${left}`);
};

export default app;
