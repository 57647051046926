import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import { Card } from "@material-ui/core";
import classes from "../../__Containers.css";

const FinancesHome = (props) => {
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Financeiro" icon={<MonetizationOnIcon />} defaultAction={null} />
      <Card className={classes.FormCard}>
        <span style={{ fontSize: "24px" }}>Em desenvolvimento 👷🏼🚧</span>
      </Card>
    </div>
  );
};

export default FinancesHome;
