import { Button, Card, CardContent, Grid, MenuItem } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import classes from "../../../../containers/__Containers.css";
import ufs from "../../../../data/ufs";
import LoadingLabel from "../../LoadingLabel/LoadingLabel";
import MSelectField from "../../MSelectField/MSelectField";
import MTextField from "../../MTextField/MTextField";

const AddressCard = (props) => {
  const { formik } = props;
  const [searching, setSearching] = useState(false);

  const searchCepHandler = () => {
    setSearching(true);
    axios
      .get(`https://viacep.com.br/ws/${formik.values.zip}/json/unicode/`)
      .then((response) => {
        const { logradouro, localidade, uf, bairro } = response.data;
        formik.setValues({
          ...formik.values,
          addressLine1: logradouro,
          neighborhood: bairro,
          city: localidade,
          state: uf,
        });
        setSearching(false);
      })
      .catch((e) => {
        console.error(e);
        setSearching(false);
      });
  };

  return (
    <Grid item xs={12} md={6}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span className={classes.SectionTitle}>Endereço</span>
            </Grid>
            <Grid item xs={6}>
              <MTextField field={"zip"} label={"CEP"} formik={formik} inputProps={{ maxLength: 8 }} />
            </Grid>
            <Grid item xs={6} style={{ alignSelf: "flex-end" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={searching || !formik.values.zip || formik.values.zip.length !== 8}
                onClick={searchCepHandler}
              >
                <LoadingLabel text="Buscar" loading={searching} />
              </Button>
            </Grid>
            <Grid item xs={8}>
              <MTextField field={"addressLine1"} label={"Logradouro"} formik={formik} />
            </Grid>
            <Grid item xs={4}>
              <MTextField field="number" label="Número" formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <MTextField field={"addressLine2"} label={"Complemento"} formik={formik} />
            </Grid>
            <Grid item xs={12}>
              <MTextField field={"neighborhood"} label={"Bairro"} formik={formik} />
            </Grid>
            <Grid item xs={8}>
              <MTextField field={"city"} label={"Cidade"} formik={formik} />
            </Grid>
            <Grid item xs={4}>
              <MSelectField field="state" label="UF" formik={formik}>
                {ufs.map((uf) => (
                  <MenuItem key={uf} value={uf}>
                    {uf}
                  </MenuItem>
                ))}
              </MSelectField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AddressCard;
