import { Grid } from "@material-ui/core";
import GridItemText from "../../../../components/UI/GridItemText/GridItemText";

const TabGeneral = (props) => {
  const { selectedServiceOrder } = props;
  // `General overview, statistics, timeline, warnings, customerFeedback (e-mail, sms, app), etc for ${props.id}`;
  console.log(selectedServiceOrder);
  return (
    <Grid container>
      {/* <Grid item xs={6}>
        + dados do cliente e veículo
      </Grid> */}
      <Grid item xs={12}>&nbsp;</Grid>
      <Grid item xs={12}>
        <Grid container>
          <GridItemText
            xs={12}
            md={4}
            label="(+) Total de Produtos(R$)"
            money={selectedServiceOrder.garageOrder?.productsTotal ?? 0}
            empty
          />
          <GridItemText
            xs={12}
            md={4}
            label="(+) Total de Serviços(R$)"
            money={selectedServiceOrder.garageOrder?.servicesTotal ?? 0}
            empty
          />
          <GridItemText
            xs={12}
            md={4}
            label="(=) TOTAL(R$)"
            money={selectedServiceOrder.garageOrder?.totalDue ?? 0}
            empty
          />
          <Grid item xs={8} md={8}></Grid>
          <GridItemText
            xs={12}
            md={4}
            label="(-) Total Pago(R$)"
            money={selectedServiceOrder?.paymentsTotal ?? 0}
            empty
          />
          <Grid item xs={8} md={8}></Grid>
          <GridItemText
            xs={12}
            md={4}
            label="(=) SALDO(R$)"
            money={(selectedServiceOrder.garageOrder?.totalDue ?? 0) - (selectedServiceOrder?.paymentsTotal ?? 0)}
            empty
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TabGeneral;
