import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newProductModalShow: false,
  barcode: null,
};

const companyOrderSlice = createSlice({
  name: "companyOrder",
  initialState,
  reducers: {
    setNewProductModalShow(state, action) {
      state.newProductModalShow = !!action.payload;
      state.barcode = action.payload;
    },
  },
});

export const companyOrderActions = companyOrderSlice.actions;

export default companyOrderSlice;
