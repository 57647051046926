import BuildIcon from "@material-ui/icons/Build";
import React from "react";
import SearchView from "../../../components/UI/SearchView/SearchView";
import apiRoutes from "../../../hooks/apiClient.routes";
import HotButton from "../../../components/UI/HotButton/HotButton";
import { useNavigate } from "react-router";

const cols = [
  {
    name: "Descrição",
    selector: "shortDescription",
    format: (row) => (
      <span style={{ pointerEvents: "none" }}>
        <b>
          {"["}
          {row.category}
          {"] "}
        </b>
        {row.shortDescription}
      </span>
    ),
  },
  {
    name: "Valor(R$)",
    selector: "price",
    right: true,
    format: (row) => row.price.toFixed(2),
  },
];

const ServiceSearch = (props) => {
  const navigate = useNavigate();
  const { asComponent } = props;

  const onRowClicked = (row) => {
    if (props.onRowClicked) props.onRowClicked(row);
    else {
      navigate("/app/servicos/form", { state: { id: row.id } });
    }
  };

  return (
    <SearchView
      title={asComponent ? null : "Serviços"}
      icon={asComponent ? null : <BuildIcon />}
      placeholder="Descrição do serviço"
      cacheKey="service-search"
      cols={cols}
      searchRoute={apiRoutes.main.service.search}
      defaultActionButton={
        asComponent ? null : (
          <HotButton
            hotkeys="ctrl+n"
            onClick={() => {
              navigate("/app/servicos/form");
            }}
          >
            Novo Serviço
          </HotButton>
        )
      }
      onRowClicked={onRowClicked}
    />
  );
};

export default ServiceSearch;
