import { Card, CardContent, Grid } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import React from "react";
import CanvasTitle from "../../../components/UI/CanvasTitle/CanvasTitle";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import classes from "../../__Containers.css";

const SettingsSearch = (props) => {
  const theme = useTheme();
  const desktopMedia = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div className={classes.Canvas}>
      <CanvasTitle label="Configurações" icon={<SettingsIcon />} />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            {desktopMedia ? (
              <Grid item xs={2} md={3}>
                <Card>
                  <CardContent>Lista de opções</CardContent>
                </Card>
              </Grid>
            ) : null}
            <Grid item xs={12} md={9}>
              <Card>
                <CardContent>Detalhes da opção selecionada</CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SettingsSearch;
