import { MenuItem } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import useApiClient, { apiRoutes } from "../../../hooks/apiClient";
import MSelectField from "./MSelectField";

const MSelectFieldGarageStaff = (props) => {
  const { request, data, errors, loading } = useApiClient();

  useEffect(() => {
    request(apiRoutes.main.person.employeeGarageStaff);
  }, []);

  if (errors) return <Alert severity="error">Desculpe, ocorreu um errando carregando os membros de apoio da oficina</Alert>;

  const noStaffMember = data?.value?.items?.length === 0;

  return (
    <MSelectField
      field={props.field}
      label={loading ? "Carregando..." : (noStaffMember ? "Responsável (Nenhum membro cadastrado)" : "Responsável")}
      formik={props.formik}
      disabled={loading || noStaffMember}
    >
      {data?.value?.items?.map((item) => (
        <MenuItem key={item.id} value={item.id}>{item.publicName || item.name}</MenuItem>
      ))}
    </MSelectField>
  );
};

export default MSelectFieldGarageStaff;
