import { Button, Card, CardContent, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import classes from "../../__Containers.css";
import VehicleDetailModal from "../VehicleDetail/VehicleDetailModal";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

var removeVehicleHandler = null;

const cols = [
  {
    name: "Fabricante",
    selector: "brand",
    width: "15%",
  },
  {
    name: "Modelo",
    selector: "model",
    width: "40%",
  },
  {
    name: "Ano",
    selector: "modelYear",
    width: "15%",
  },
  {
    name: "Placa",
    selector: "plates",
    width: "15%",
  },
  {
    name: "",
    selector: "plates",
    width: "15%",
    format: (row) => <RemoveCircleIcon onClick={() => removeVehicleHandler(row)} />,
  },
];

const VehicleAssociated = (props) => {
  const { associatedVehicles, loading } = props;
  const [vehicleModal, setVehicleModal] = useState({ show: false, id: null });

  removeVehicleHandler = (row) => {
    props.handleChange([...associatedVehicles.filter((v) => v.id !== row.id)]);
  };

  return (
    <>
      <VehicleDetailModal
        title="Veículos"
        show={vehicleModal.show}
        id={vehicleModal.id}
        handleClose={() => setVehicleModal({ show: false })}
        handleDone={(vehicle) => {
          if (associatedVehicles.find((v) => v.id === vehicle.id)) {
            props.handleChange([...associatedVehicles.filter((v) => v.id !== vehicle.id)].concat([vehicle]));
          } else {
            props.handleChange([...associatedVehicles].concat([vehicle]));
          }
        }}
      />
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <span className={classes.SectionTitle}>Veículos</span>
            </Grid>
            <Grid item xs={12}>
              <DataTable
                columns={cols}
                data={associatedVehicles || []}
                noDataComponent="Nenhum veículo associado"
                noHeader
                pointerOnHover
                striped
                responsive
                progressPending={loading}
                progressComponent="Carregando..."
                onRowClicked={(item) => setVehicleModal({ show: true, id: item.id })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Button color="primary" size="small" onClick={() => setVehicleModal({ show: true, id: null })}>
                Associar Veículo
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default VehicleAssociated;
